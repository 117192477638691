import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { AssetHierarchyResponse } from "source/app/configuration/services/api.service";

@Component({
	selector: "app-asset-picker-modal",
	templateUrl: "./asset-picker.modal.component.html",
	styleUrls: ["./asset-picker.modal.component.scss"],
})
export class AssetPickerModalComponent implements OnInit {
	static modalId = "asset-picker-component";
	static save = "save";
	static cancel = "cancel";

	constructor(private modalController: ModalController) {}
	@Input() assetHierarchy: AssetHierarchyResponse;

	currentNode: AssetHierarchyResponse;
	nodeTree: AssetHierarchyResponse[] = [];

	close = () =>
		this.modalController.dismiss(undefined, AssetPickerModalComponent.cancel, AssetPickerModalComponent.modalId);

	expand = (node: AssetHierarchyResponse) => {
		if (node.nodes.length === 0) {
			return;
		}
		this.nodeTree.push(node);
		this.currentNode = node;
	};

	choose = (node: AssetHierarchyResponse) =>
		this.modalController.dismiss(
			{ id: node.id, name: node.name },
			AssetPickerModalComponent.save,
			AssetPickerModalComponent.modalId,
		);

	noAsset = () =>
		this.modalController.dismiss(null, AssetPickerModalComponent.save, AssetPickerModalComponent.modalId);

	goBack = () => {
		if (this.nodeTree.length > 1) {
			this.nodeTree.pop();
			this.currentNode = this.nodeTree[this.nodeTree.length - 1];
		}
	};

	ngOnInit(): void {
		this.currentNode = this.assetHierarchy;
		this.nodeTree.push(this.assetHierarchy);
	}
}

<div class="container">
	<div class="button-container">
		<ion-fab *ngIf="(locationState$ | async) === 'Open'">
			<ion-fab-button color="primary">
				<ion-icon name="lock-open-outline"></ion-icon>
			</ion-fab-button>

			<ion-fab-list side="start">
				<ion-button
					color="light"
					shape="round"
					(click)="closeLocation()"
					*ngIf="(locationState$ | async) !== 'Closed'"
				>
					<ion-icon slot="start" name="lock-closed-outline"></ion-icon>
					{{ "LOCATION_STATUS_CLOSE_RIDE" | transloco }}
				</ion-button>
				<ion-button
					color="warning"
					shape="round"
					(click)="downLocation()"
					*ngIf="(locationState$ | async) !== 'Down'"
				>
					<ion-icon slot="start" name="alert-circle-outline"></ion-icon>
					{{ "LOCATION_STATUS_START_DOWNTIME" | transloco }}
				</ion-button>
			</ion-fab-list>
		</ion-fab>
	</div>
</div>

import { Injectable } from "@angular/core";
import { snapshotManager } from "@datorama/akita";
import { AlertController, LoadingController, NavController } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { Subject } from "rxjs";
import { LocationStateService } from "source/app/configuration/state/location-state.service";
import { ValuePickerModalService } from "source/app/modals/value-picker/value-picker-modal.service";
import { LocationStateQuery } from "../state/location-state.query";
import { LastQueueTimeUpdate, LastRidersUpdate, Location, LocationState } from "../state/location-state.store";
import { UiService } from "../state/ui.service";
import { UiState } from "../state/ui.store";
import { ApiService, LocationSummaryResult } from "./api.service";
import { ChecklistStateService } from "source/app/features/checklists/state/checklist-state.service";
import { OperatorService } from "./operator.service";
import { RaygunService } from "./raygun.service";

@Injectable({
	providedIn: "root",
})
export class LocationChangeService {
	constructor(
		private valuePickerModalService: ValuePickerModalService,
		private locationStateQuery: LocationStateQuery,
		private locationStateService: LocationStateService,
		private alertController: AlertController,
		private apiService: ApiService,
		private uiService: UiService,
		private translocoService: TranslocoService,
		private loadingController: LoadingController,
		private checklistStateService: ChecklistStateService,
		private operatorService: OperatorService,
		private navController: NavController,
		private raygunService: RaygunService,
	) {}
	private prefix = "saved-sate";

	locationChanged$ = new Subject<void>();

	startLocationChange = async () => {
		const locationSummary = await this.apiService.locationSummary();
		if (locationSummary.allowLocationSwitch == false) {
			await this.showError("LOCATION_CHANGE_ERROR_ALERT__DISABLED_SWITCH_LOCATIONS");
			return;
		}

		const currentOperator = await this.locationStateQuery.operator$.firstAsync();
		const currentLocation = await this.locationStateQuery.location$.firstAsync();

		const availableLocations = await this.getAvailableLocations(currentOperator, currentLocation, locationSummary);
		if (availableLocations.length == 0) {
			await this.showError("LOCATION_CHANGE_ERROR_ALERT__NO_AVAILABLE_LOCATIONS");
			return;
		}

		const targetLocation = await this.selectLocation(availableLocations);
		if (targetLocation == null || currentLocation?.id === targetLocation.id) {
			// Select location modal was cancelled, just return
			return;
		}

		const loadingModal = await this.showLoadingModal();
		const currentState = snapshotManager.getStoresSnapshot(["ui", "locationState"]) as {
			ui: UiState;
			locationState: LocationState;
		};

		try {
			const locationStatus = await this.locationStateService.getLocationStatus(targetLocation.id);

			if (currentOperator != null) {
				const operators = locationStatus.operators;
				const operator = operators.find((x) => x.id === currentOperator.id);

				if (operator == null) {
					throw this.createError("NotOperatorError");
				}

				if (operator.missingCompetencies?.length > 0) {
					throw this.createError("MissingCompetenciesError");
				}
			}

			if (currentLocation != null) {
				this.saveState(currentLocation.id, {
					ui: currentState.ui,
					lastQueueTimeUpdate: await this.locationStateQuery.lastQueueTimeUpdate$.firstAsync(),
					lastRidersUpdate: await this.locationStateQuery.lastRidersUpdate$.firstAsync(),
					riders: await this.locationStateQuery.riders$.firstAsync(),
					dispatches: await this.locationStateQuery.dispatches$.firstAsync(),
				});

				await this.operatorService.logoutOperator();
			}

			await this.setState(targetLocation);
			await this.operatorService.login(currentOperator.id, currentOperator.pin);
			this.locationStateService.setCurrentOperator(currentOperator);

			this.locationChanged$.next();
			this.navController.navigateRoot("dashboard");
		} catch (error) {
			if (error instanceof Error) {
				const locationChangeErrorName = error.name as LocationChangeErrorName;
				switch (locationChangeErrorName) {
					case "NotOperatorError": {
						this.revertLocationChange("LOCATION_CHANGE_ERROR_ALERT__NOT_OPERATOR", currentState);
						break;
					}
					case "MissingCompetenciesError": {
						this.revertLocationChange("LOCATION_CHANGE_ERROR_ALERT__MISSING_COMPETENCIES", currentState);
						break;
					}
					case "TimeoutError": {
						this.revertLocationChange("LOCATION_CHANGE_ERROR_ALERT__TIMEOUT", currentState);
						break;
					}
					default: {
						this.raygunService.send(error, {});
						this.revertLocationChange("LOCATION_CHANGE_ERROR_ALERT__UNKNOWN", currentState);
						break;
					}
				}
			} else {
				this.raygunService.send(new Error("Something other than an error thrown in location change attempt"), {
					error: error,
				});

				this.revertLocationChange("LOCATION_CHANGE_ERROR_ALERT__UNKNOWN", currentState);
			}
		} finally {
			loadingModal.dismiss();
		}
	};

	private getState = (location: string): SaveState => {
		try {
			return JSON.parse(localStorage.getItem(this.prefix + location));
		} catch (error) {
			return undefined;
		}
	};

	private setState = async (location: Location) => {
		this.locationStateService.resetRideOpsState();
		this.uiService.reset();

		await this.locationStateService.updateLocationStatus(location.id);

		const savedState = this.getState(location.id);
		if (savedState) {
			snapshotManager.setStoresSnapshot({ ui: savedState.ui });
			this.locationStateService.setRideOpsState(
				savedState.lastQueueTimeUpdate,
				savedState.lastRidersUpdate,
				savedState.riders,
				savedState.dispatches,
			);

			//we reset the preopeningChecklist, and rely on the locationstate instead
			this.checklistStateService.clearPreopeningChecklistSubmitted();
		}
	};

	private saveState = (location: string, state: SaveState) => {
		return localStorage.setItem(this.prefix + location, JSON.stringify(state));
	};

	private getAvailableLocations = async (
		currentOperator: Operator,
		currentLocation: Location,
		locationSummary: LocationSummaryResult,
	): Promise<Location[]> => {
		const locations = locationSummary?.locations || [];

		if (currentOperator == null) {
			return locations;
		}

		return locations
			.filter((location) => location.id !== currentLocation.id)
			.filter((location) => location.operators?.includes(currentOperator.id));
	};

	private selectLocation = async (locations: Location[]): Promise<Location> => {
		const modal = await this.valuePickerModalService.create({
			title: "LOCATION_PICKER__SELECT_LOCATION_TITLE",
			description: "LOCATION_PICKER__DESCRIPTION",
			elements: locations,
		});

		await modal.present();

		const location = await modal.onDidDismiss<Location>();
		return location?.data;
	};

	private showLoadingModal = async () => {
		const modal = await this.loadingController.create({
			backdropDismiss: false,
			cssClass: "changing-location",
			message: this.translocoService.translate("LOCATION_CHANGE__PLEASE_WAIT"),
		});

		await modal.present();
		return modal;
	};

	private revertLocationChange = async (
		message: string,
		snapshots: { ui: UiState; locationState: LocationState },
	) => {
		snapshotManager.setStoresSnapshot(snapshots);
		await this.showError(message);
	};

	private showError = async (message: string) => {
		const alert = await this.alertController.create({
			header: this.translocoService.translate("LOCATION_CHANGE_ERROR_ALERT__TITLE"),
			message: this.translocoService.translate(message),
			buttons: [this.translocoService.translate("LOCATION_CHANGE_ERROR_ALERT__OK")],
		});

		alert.present();
	};

	private createError = (name: LocationChangeErrorName, message: string = undefined) => {
		const error = new Error(message);
		error.name = name;
		return error;
	};
}

type LocationChangeErrorName = "NotOperatorError" | "MissingCompetenciesError" | "TimeoutError";

interface SaveState {
	ui: UiState;
	lastQueueTimeUpdate: LastQueueTimeUpdate;
	lastRidersUpdate: LastRidersUpdate;
	riders: number;
	dispatches: number;
}

import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";
import { EnvironmentState } from "./environment.state";

export function createInitialState(): { environment: EnvironmentState } {
	switch (location.hostname) {
		case "mobaro-rideops-app.azurewebsites.net":
		case "rideops.mobaro.com": {
			return { environment: EnvironmentState.Production };
		}
		case "mobaro-rideops-app-staging.azurewebsites.net":
		case "rideops.staging.mobaro.com": {
			return { environment: EnvironmentState.Staging };
		}
		default: {
			return { environment: EnvironmentState.Development };
		}
	}
}

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "environment", resettable: true })
export class EnvironmentStore extends Store<{ environment: EnvironmentState }> {
	constructor() {
		super(createInitialState());
	}
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UtilityService } from "../../../../../configuration/services/utility.service";

@Component({
	selector: "app-reference-images",
	templateUrl: "./reference-images.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferenceImagesComponent implements OnInit {
	constructor(
		private utilityService: UtilityService,
		private changeDetector: ChangeDetectorRef,
	) {}

	@Input() images: string[];
	urls: string[];

	open(url: string) {
		window.open(url, "_blank");
	}

	async ngOnInit() {
		this.urls = await Promise.all(this.images.map((image) => this.utilityService.getFileUrl(image)));

		this.changeDetector.markForCheck();
	}
}

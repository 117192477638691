<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<ng-container *ngIf="model != null">
	<span *ngIf="model.showHours">{{ model.hours }}</span>
	<span *ngIf="model.showHours" class="postfix">h</span>
	<span *ngIf="model.showMinutes">{{ model.minutes }}</span>
	<span *ngIf="model.showMinutes" class="postfix">m</span>
	<span>{{ model.seconds }}</span>
	<span class="postfix">s</span>
</ng-container>
<span *ngIf="model == null">{{ "STATISTICS_PAGE__NA" | transloco }}</span>

export class TimeSpanModel {
	private constructor(totalMinutes: number) {
		this.totalMinutes = totalMinutes;

		this.hours = Math.floor(totalMinutes / 60);
		this.minutes = Math.floor(totalMinutes % 60);
	}

	readonly totalMinutes: number;
	readonly minutes: number;
	readonly hours: number;
	static fromMinutes(totalMinutes: number) {
		return new TimeSpanModel(totalMinutes);
	}
}

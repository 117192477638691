import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { enableAkitaProdMode, persistState } from "@datorama/akita";
import { debounceTime } from "rxjs";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { defineCustomElements } from "@ionic/pwa-elements/loader";

defineCustomElements(window);

if (environment.isProduction) {
	enableProdMode();
	enableAkitaProdMode();
}

export const storage = persistState();

platformBrowserDynamic([
	{
		provide: "persistStorage",
		useValue: persistState({
			preStorageUpdateOperator: () => debounceTime(1000),
		}),
	},
])
	.bootstrapModule(AppModule)
	.catch((error) => console.error(error));

import { CommonModule } from "@angular/common";
import { HttpEventType } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { TranslocoModule, TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, Observable, map, tap } from "rxjs";

import { ApiService } from "source/app/configuration/services/api.service";
import { NotificationService } from "source/app/configuration/services/notification.service";
import { fadeInOnEnter, fadeOutOnLeave, scaleOut } from "../../animations";

@UntilDestroy()
@Component({
	standalone: true,
	selector: "app-file-upload",
	templateUrl: "./file-upload.component.html",
	styleUrls: ["./file-upload.component.scss"],
	animations: [fadeInOnEnter, fadeOutOnLeave, scaleOut],
	imports: [CommonModule, IonicModule, TranslocoModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent implements OnInit {
	constructor(
		private apiService: ApiService,
		private translocoService: TranslocoService,
		private notificationService: NotificationService,
	) {}

	@Input() file$: Observable<File>;
	@Output() fileId = new EventEmitter<string>();
	@Input() tags: string[] = [];

	protected uploadProgress$ = new BehaviorSubject(0);
	protected isUploading$ = this.uploadProgress$.pipe(map((progress) => progress > 0 && progress < 1));

	ngOnInit() {
		this.file$
			.pipe(
				tap((file) => this.upload(file)),
				untilDestroyed(this),
			)
			.subscribe();
	}

	private async upload(file: File): Promise<void> {
		if (!file) {
			return;
		}

		this.uploadProgress$.next(0.01);

		try {
			const upload$ = this.apiService.uploadFiles$([file], this.tags);

			upload$.pipe(untilDestroyed(this)).subscribe((event) => {
				switch (event.type) {
					case HttpEventType.Response:
						this.fileId.emit(event.body.data[0].path);
						this.uploadProgress$.next(1);
						break;
					case HttpEventType.UploadProgress:
						this.uploadProgress$.next(event.loaded / event.total);
						break;
				}
			});
		} catch {
			const message = this.translocoService.translate("FILE_UPLOAD__ERROR_MESSAGE");
			this.notificationService.showErrorToast(message);
			this.uploadProgress$.next(0);
		}
	}
}

import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Observable, combineLatest, map, tap } from "rxjs";
import { FileUploadsQuery } from "source/app/configuration/state/file-uploads.query";
import { FileUploadsService } from "source/app/configuration/state/file-uploads.service";

@Component({
	selector: "app-file-uploads",
	styleUrls: ["./file-uploads.component.scss"],
	templateUrl: "file-uploads.component.html",
})
export class FileUploadsComponent implements OnInit {
	constructor(
		private fileUploadsQuery: FileUploadsQuery,
		private fileUploadsService: FileUploadsService,
		private modalController: ModalController,
	) {}
	@Input() files: string[];

	total = 0;
	current = 0;

	progress: Observable<number>;

	close = () => {
		this.modalController.dismiss();
	};

	ngOnInit(): void {
		this.total = this.files.length;

		this.progress = combineLatest([
			this.fileUploadsQuery.selectMany(this.files),
			this.fileUploadsService.currentUploadProgress$,
		]).pipe(
			tap(([fileuploads, _currentProgress]) => {
				this.current = this.total - fileuploads.length;
				if (fileuploads.length === 0) {
					this.close();
				}
			}),
			map(([fileuploads, currentProgress]) => (this.total - fileuploads.length + currentProgress) / this.total),
		);
	}
}

@if (visibleAssignment.length > 0) {
	<ion-list lines="full">
		<ion-list-header lines="full">{{ "ASSIGNMENT__ASSIGNMENTS" | transloco }}</ion-list-header>
		<ion-item (click)="option(assignment)" lines="full" *ngFor="let assignment of visibleAssignment">
			<ion-label>
				{{ assignment.assignment.name }}
				<p>{{ assignment.assignment.target.name }}</p>
			</ion-label>
		</ion-item>
	</ion-list>
}

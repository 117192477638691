<ion-header>
	<ion-toolbar>
		<ion-title>{{ "ASSET_PICKER__TITLE" | transloco }}</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="close()">
				<ion-icon slot="icon-only" name="close-outline"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content>
	<ion-text>
		<p class="ion-padding-start ion-padding-end">
			{{ "ASSET_PICKER__DESCRIPTION" | transloco }}
		</p>
	</ion-text>
	<ion-list lines="full">
		<ion-list-header lines="full">
			{{ "ASSET_PICKER__BREADCRUMB_START" | transloco }}
			<span *ngFor="let breadcrumb of nodeTree; last as isLast">
				&nbsp; >
				<span [ngClass]="{ highlight: isLast }">{{ breadcrumb.name }}</span>
			</span>
		</ion-list-header>

		<ion-item button *ngIf="nodeTree.length > 1" (click)="goBack()">
			<ion-icon name="chevron-back-outline" slot="start" color="primary"></ion-icon>
			<ion-label>{{ "BASE__BACK" | transloco }}</ion-label>
		</ion-item>

		<ion-item button *ngFor="let node of currentNode.nodes" lines="full">
			<ion-icon name="cube-outline" slot="start" color="primary"></ion-icon>
			<ion-label (click)="expand(node)">
				{{ node.name }}
				<p
					*ngIf="node.nodes.length > 0"
					innerHTML="{{ 'ASSET_PICKER__CONTAINS' | transloco: { number: node.nodes.length } }}"
				></p>
			</ion-label>
			<ion-button (click)="choose(node)" slot="end">{{ "ASSET_PICKER__CHOOSE_ASSET" | transloco }}</ion-button>
		</ion-item>

		<ion-item button (click)="noAsset()" lines="full">
			<ion-icon name="close-circle-outline" slot="start" color="primary"></ion-icon>
			<ion-label>{{ "ASSET_PICKER__NO_ASSET" | transloco }}</ion-label>
			<ion-button slot="end">{{ "ASSET_PICKER__CHOOSE_ASSET" | transloco }}</ion-button>
		</ion-item>
	</ion-list>
</ion-content>

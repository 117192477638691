import { ChangeDetectionStrategy, Component, forwardRef } from "@angular/core";
import { RangeValue } from "@ionic/core";
import { FormQuestion, NumberQuestion } from "../../../checklist.interface";
import { QuestionComponent } from "../question.component";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "app-number-question",
	templateUrl: "./number-question.component.html",
	styleUrls: ["./number-question.component.scss"],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => NumberQuestionComponent),
			multi: true,
		},
	],
})
export class NumberQuestionComponent extends QuestionComponent<NumberQuestion> implements ControlValueAccessor {
	model: NumberQuestionModel = {
		value: null,
		isInvalidRange: false,
		step: null,
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private getNumericValue = (value: any): number | null => (isNaN(value) ? null : parseFloat(value));
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private isInvalidRange(input: any) {
		const value = this.getNumericValue(input);

		if (value == null) {
			return false;
		}

		const isLessThanMinimum = this.question.minimum != null && value < this.question.minimum;
		const isMoreThanMaximum = this.question.maximum != null && value > this.question.maximum;

		return isLessThanMinimum || isMoreThanMaximum;
	}

	protected pinFormatter(value: number) {
		return `${value}`;
	}

	private getStepForSlider() {
		const isDecimal = (value: number) => {
			return value && Math.floor(value) !== value;
		};

		const numDecimals = (value: number) => {
			return value?.toString().split(".")[1]?.length || 0;
		};

		if (!(isDecimal(this.question.minimum) || isDecimal(this.question.maximum))) {
			return 1;
		}

		const limitDecimalsLength = [numDecimals(this.question.minimum), numDecimals(this.question.maximum)];
		const maxNumDecimals = Math.max(...limitDecimalsLength);
		return Math.pow(10, -maxNumDecimals);
	}

	override writeValue(answer: FormQuestion | undefined): void {
		super.writeValue(answer);
		this.model.value = answer?.answers.length == 0 ? null : answer?.answers[0];

		if (this.question.variation === "Slider") {
			this.model.step = this.getStepForSlider();
		}
	}

	async onChange(input: string | number | RangeValue) {
		this.model.isInvalidRange = this.isInvalidRange(input);
		this.model.value = input?.toString();

		const answer = this.model.isInvalidRange ? null : this.model.value;

		this.setAnswers(answer + "");
		this.onTouched();
	}
}

interface NumberQuestionModel {
	value: string;
	isInvalidRange: boolean;
	step: number;
}

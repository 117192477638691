import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { ChecklistProgressState, ChecklistProgressStateStore } from "./checklist-progress-state.store";

@Injectable({ providedIn: "root" })
export class ChecklistProgressStateQuery extends Query<ChecklistProgressState> {
	constructor(protected override store: ChecklistProgressStateStore) {
		super(store);
	}

	checklist$ = this.select((state) => state.checklist);
	responses$ = this.select((state) => state.responses);
}

import { Component, Input, OnInit } from "@angular/core";
import { AlertController, ModalController, SelectCustomEvent } from "@ionic/angular";
import { AssignmentDefinition, AssignmentDefinitionState, AssignmentSolutionData } from "../../assignment.interface";
import { TranslocoService } from "@ngneat/transloco";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ImageAttachment } from "source/app/features/checklists/checklist.interface";

@Component({
	selector: "app-solution-details-modal",
	templateUrl: "./solution-details.modal.component.html",
	styleUrls: ["./solution-details.modal.component.scss"],
})
export class SolutionDetailsModalComponent implements OnInit {
	static modalId = "solution-details-modal-component";
	static save = "save";
	static cancel = "cancel";

	constructor(
		private modalController: ModalController,
		private alertController: AlertController,
		private translocoService: TranslocoService,
	) {}

	@Input() solution: AssignmentSolutionData;
	@Input() definition: AssignmentDefinition;

	loaded = false;
	states: AssignmentDefinitionState[] = [];
	formGroup: FormGroup<SolutionForm>;

	close = () =>
		this.modalController.dismiss(
			undefined,
			SolutionDetailsModalComponent.cancel,
			SolutionDetailsModalComponent.modalId,
		);
	save = async () => {
		if (this.formGroup.valid === false) {
			return;
		}

		const description = this.formGroup.controls.description.value;
		const attachments = this.formGroup.controls.attachments.value;
		const definitionState = this.formGroup.controls.definitionState.value;

		const descriptionIsEmpty = description.trim() === "";

		if (descriptionIsEmpty) {
			const alert = await this.alertController.create({
				header: this.translocoService.translate("ASSIGNMENT_RESOLVE__CONFIRM_NO_DESCRIPTION_TITLE"),
				message: this.translocoService.translate("ASSIGNMENT_RESOLVE__CONFIRM_NO_DESCRIPTION_BODY"),
				buttons: [
					{
						text: this.translocoService.translate("BASE__CANCEL"),
						role: "cancel",
					},
					{
						text: this.translocoService.translate("ASSIGNMENT_RESOLVE__CONFIRM_NO_DESCRIPTION_OK"),
						role: "ok",
					},
				],
				backdropDismiss: false,
			});

			await alert.present();
			const result = await alert.onDidDismiss();
			if (result.role === "cancel") {
				return;
			}
		}

		const solution: AssignmentSolutionData = {
			id: "",
			description: description,
			definitionState: definitionState,
			attachments: attachments,
		};

		this.modalController.dismiss(
			solution,
			SolutionDetailsModalComponent.save,
			SolutionDetailsModalComponent.modalId,
		);
	};

	changeState = (event: SelectCustomEvent<AssignmentDefinitionState>) => {
		const definitionState = event.detail.value;

		if (definitionState.requireDocumentation) {
			this.formGroup.controls.description.setValidators([Validators.required]);
		} else {
			this.formGroup.controls.description.setValidators([]);
		}
		this.formGroup.controls.description.updateValueAndValidity();
	};

	compareWith = (o1: { id: string }, o2: { id: string }) => {
		return o1 && o2 ? o1.id === o2.id : o1 === o2;
	};

	ngOnInit(): void {
		if (this.definition != null) {
			this.states = this.definition.states.filter((x) => x.type === "Final");
		}

		const description = this.solution?.description == null ? "" : this.solution.description;
		const attachments = this.solution?.attachments == null ? [] : this.solution.attachments;

		let definitionState: AssignmentDefinitionState | null = null;

		if (this.solution?.definitionState != null) {
			definitionState = this.solution.definitionState;
		} else if (this.states.length === 1) {
			definitionState = this.states[0];
		}

		const definitionStateValidtor = this.states.length > 0 ? [Validators.required] : [];

		this.formGroup = new FormGroup<SolutionForm>({
			description: new FormControl<string>({ value: description, disabled: false }),
			attachments: new FormControl<ImageAttachment[]>({ value: attachments, disabled: false }),
			definitionState: new FormControl<AssignmentDefinitionState>(
				{ value: definitionState, disabled: false },
				definitionStateValidtor,
			),
		});

		this.loaded = true;
	}
}

export interface SolutionForm {
	description: FormControl<string>;
	attachments: FormControl<ImageAttachment[]>;
	definitionState: FormControl<AssignmentDefinitionState>;
}

<div class="image-viewer">
	<ion-header>
		<ion-toolbar>
			<ion-title>{{ "IMAGE_VIEW_MODAL__IMAGE" | transloco }}</ion-title>
			<ion-buttons slot="end">
				<ion-button (click)="close()">
					<ion-icon slot="icon-only" name="close-outline"></ion-icon>
				</ion-button>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>

	<div class="image-container">
		<ion-img [src]="imageUrl"></ion-img>
	</div>
</div>

<div class="menu-container" *ngLet="allowNavigation$ | async as allowNavigation">
	<div class="icon-container">
		<ion-icon src="assets/svg/rideops-logo.svg" class="logo" (click)="settings()"></ion-icon>
	</div>

	<ng-container *ngLet="currentUrl$ | async as currentUrl">
		<div
			class="icon-container"
			[ngClass]="{
				active: currentUrl === '/dashboard',
				disabled: allowNavigation !== true
			}"
		>
			<ion-icon name="albums-outline" [routerLink]="['/dashboard']" routerDirection="root"></ion-icon>
		</div>

		<div
			class="icon-container"
			[ngClass]="{
				active: currentUrl === '/operators',
				disabled: allowNavigation !== true
			}"
		>
			<ion-icon name="person-outline" [routerLink]="['/operators']" routerDirection="root"></ion-icon>
		</div>

		<div
			class="icon-container"
			[ngClass]="{
				active: currentUrl === '/manuals',
				disabled: allowNavigation !== true
			}"
		>
			<ion-icon name="book-outline" [routerLink]="['/manuals']" routerDirection="root"></ion-icon>
		</div>

		<div
			*ngIf="showNotesIcon"
			class="icon-container"
			[ngClass]="{
				active: currentUrl === '/notes',
				disabled: allowNavigation !== true
			}"
		>
			<ion-icon name="document-text-outline" [routerLink]="['/notes']" routerDirection="root"></ion-icon>
		</div>

		<div
			class="icon-container"
			*ngIf="allowStatisticsForOperator$ | async"
			[ngClass]="{ disabled: allowNavigation !== true }"
		>
			<ion-icon name="analytics-outline" (click)="statistics()"></ion-icon>
		</div>

		<div class="icon-container mobaro">
			<ion-icon src="assets/svg/mobaro-logo.svg" class="logo"></ion-icon>
		</div>
	</ng-container>
</div>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslocoModule } from "@ngneat/transloco";
import { SwiperModule } from "swiper/angular";
import SwiperCore, { Navigation, Pagination } from "swiper";

import { ComponentsModule } from "../../components/components.module";
import { VideoPlayerModule } from "../../modals/video-player/video-player.module";
import { ChecklistComponent } from "./components/checklist.component";
import { ElementTemplateComponent } from "./components/elements/element-template.component";
import { ImageElementComponent } from "./components/elements/image-element/image-element.component";
import { TextElementComponent } from "./components/elements/text-element/text-element.component";
import { DateTimeQuestionComponent } from "./components/questions/date-question/date-time-question.component";
import { NumberQuestionComponent } from "./components/questions/number-question/number-question.component";
import { QuestionTemplateComponent } from "./components/questions/question-template.component";
import { QuestionComponent } from "./components/questions/question.component";
import { SelectQuestionComponent } from "./components/questions/select-question/select-question.component";
import { TextQuestionComponent } from "./components/questions/text-question/text-question.component";
import { EmailQuestionComponent } from "./components/questions/email-question/email-question.component";
import { UnknownQuestionComponent } from "./components/questions/unknown-question/unknown-question.component";
import { ImageQuestionComponent } from "./components/questions/image-question/image-question.component";
import { ScannerQuestionComponent } from "./components/questions/scanner-question/scanner-question.component";
import { DurationQuestionComponent } from "./components/questions/duation-question/duration-question.component";
import { SignatureQuestionComponent } from "./components/questions/signature-question/signature-question.component";
import { DocumentationComponent } from "./components/shared/documentation/documentation.component";
import { PaginationComponent } from "./components/shared/pagination/pagination.component";
import { ReferenceImagesComponent } from "./components/shared/reference-images/reference-images.component";
import { ChecklistModalComponent } from "./modals/checklist-modal.component";
import { ChecklistModalService } from "./modals/checklist-modal.service";
import { UnsupportedQuestionsService } from "./services/unsupported-questions.service";
import { FileUploadComponent } from "../../components/file-upload/file-upload.component";
import { CommentComponent } from "./components/shared/comment/comment.component";
import { AssignmentsComponent } from "./components/shared/assignments/assignments.component";
import { AttachmentsComponent } from "./components/shared/attachments/attachments.component";
// import { SharedModule } from "./components/shared/shared.module";

SwiperCore.use([Pagination, Navigation]);

@NgModule({
	declarations: [
		ChecklistComponent,
		PaginationComponent,
		QuestionComponent,
		QuestionTemplateComponent,
		SelectQuestionComponent,
		TextQuestionComponent,
		EmailQuestionComponent,
		UnknownQuestionComponent,
		ImageQuestionComponent,
		ScannerQuestionComponent,
		DurationQuestionComponent,
		SignatureQuestionComponent,
		DateTimeQuestionComponent,
		NumberQuestionComponent,
		ElementTemplateComponent,
		TextElementComponent,
		ImageElementComponent,
		ReferenceImagesComponent,
		ChecklistModalComponent,
		DocumentationComponent,
		CommentComponent,
		AssignmentsComponent,
	],
	providers: [ChecklistModalService, UnsupportedQuestionsService],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		IonicModule,
		TranslocoModule,
		ComponentsModule,
		VideoPlayerModule,
		SwiperModule,
		FileUploadComponent,
		AttachmentsComponent,
	],
})
export class ChecklistModule {}

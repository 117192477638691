import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslocoModule } from "@ngneat/transloco";
import { ComponentsModule } from "../../../components/components.module";
import { AssignmentModalComponent } from "./assignment-modal.component";
import { AssignmentDescriptionComponent } from "../components/description/assignment-description.component";
import { AssigneePickerModalComponent } from "../modals/assignee-picker/assignee-picker.modal.component";
import { QuillViewComponent } from "ngx-quill";
import { AssigneePickerModalService } from "../modals/assignee-picker/assignee-picker.modal.service";
import { AssetPickerModalComponent } from "./asset-picker/asset-picker.modal.component";
import { AssetPickerModalService } from "./asset-picker/asset-picker.modal.service";
import { AssigneesComponent } from "../components/assignees/assignees.component";
import { AssetComponent } from "../components/asset/asset.component";
import { SolutionComponent } from "../components/solution/solution.component";
import { SolutionDetailsModalComponent } from "./solution-details/solution-details.modal.component";
import { AssignmentSolutionDetailsModalService } from "./solution-details/solution-details.modal.service";
import { AttachmentsComponent } from "../../checklists/components/shared/attachments/attachments.component";
import { AssignmentService } from "../services/assignment.service";
import { AssignmentModalService } from "./assignment-modal.service";

@NgModule({
	declarations: [
		AssignmentModalComponent,
		AssignmentDescriptionComponent,
		AssigneePickerModalComponent,
		AssetPickerModalComponent,
		AssigneesComponent,
		AssetComponent,
		SolutionComponent,
		SolutionDetailsModalComponent,
	],
	providers: [
		AssigneePickerModalService,
		AssetPickerModalService,
		AssignmentSolutionDetailsModalService,
		AssignmentService,
		AssignmentModalService,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		IonicModule,
		TranslocoModule,
		ComponentsModule,
		QuillViewComponent,
		AttachmentsComponent,
	],
})
export class AssignmentModule {}

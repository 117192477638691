import { Component, forwardRef } from "@angular/core";
import { QuestionComponent } from "../question.component";
import { DurationQuestion, FormQuestion } from "../../../checklist.interface";
import { Observable, Subject, interval, map, startWith, switchMap, takeUntil, tap } from "rxjs";
import { PickerColumn, PickerController } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "app-duration-question",
	templateUrl: "./duration-question.component.html",
	styleUrls: ["./duration-question.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DurationQuestionComponent),
			multi: true,
		},
	],
})
export class DurationQuestionComponent extends QuestionComponent<DurationQuestion> implements ControlValueAccessor {
	constructor(
		private pickerController: PickerController,
		private translocoService: TranslocoService,
	) {
		super();
	}

	private setTimerObserveable = () => {
		this.timer$ = this.start$.pipe(
			switchMap(() =>
				interval(100).pipe(
					map(() => {
						const now = new Date();
						const diffMilliseconds = now.getTime() - this.timerStarted.getTime();
						return Math.floor(diffMilliseconds / 1000);
					}),
					takeUntil(this.stop$),
				),
			),
			startWith(this.totalSeconds),
			tap((seconds) => (this.totalSeconds = seconds)),
			map((seconds) => ({
				hours: Math.floor(seconds / 3600),
				minutes: Math.floor((seconds % 3600) / 60),
				seconds: seconds % 60,
			})),
		);
	};

	totalSeconds = 0;

	private timerStarted: Date;
	private stop$ = new Subject<void>();
	private start$ = new Subject<void>();
	timer$: Observable<DurationTime>;
	running = false;

	start() {
		if (!this.running) {
			this.running = true;
			this.timerStarted = new Date();
			this.start$.next();
		}
	}

	stop() {
		this.running = false;
		this.stop$.next();
		if (this.totalSeconds > 0) {
			this.setAnswers(this.totalSeconds + "");
		}
	}

	reset() {
		this.running = false;
		this.totalSeconds = 0;
		this.setAnswers(null);
		this.setTimerObserveable();
	}

	override writeValue(answer: FormQuestion | undefined): void {
		super.writeValue(answer);
		if (answer?.answers) {
			this.totalSeconds = Number(answer?.answers[0]);
		}
		this.setTimerObserveable();
	}

	async edit() {
		if (this.running) {
			return;
		}

		const createColumn = (name: string, digits: number, suffix: string, currentValue: number) => {
			const column: PickerColumn = {
				name: name,
				suffix: suffix,
				selectedIndex: currentValue,
				options: [],
			};

			for (let index = 0; index < digits; index++) {
				column.options.push({
					text: index.toString().padStart(2, "0"),
					value: index,
				});
			}
			return column;
		};

		const columns = [
			createColumn(
				"hours",
				24,
				this.translocoService.translate("CHECKLISTS__QUESTIONS__DURATION_HOURS"),
				Math.floor(this.totalSeconds / 3600),
			),
			createColumn(
				"minutes",
				60,
				this.translocoService.translate("CHECKLISTS__QUESTIONS__DURATION_MINUTES"),
				Math.floor((this.totalSeconds % 3600) / 60),
			),
			createColumn(
				"seconds",
				60,
				this.translocoService.translate("CHECKLISTS__QUESTIONS__DURATION_SECONDS"),
				this.totalSeconds % 60,
			),
		];

		const picker = await this.pickerController.create({
			columns: columns,
			buttons: [
				{
					text: this.translocoService.translate("CHECKLISTS__QUESTIONS__DURATION_CANCEL"),
					role: "cancel",
				},
				{
					text: this.translocoService.translate("CHECKLISTS__QUESTIONS__DURATION_CONFIRM"),
					role: "confirm",
				},
			],
		});

		await picker.present();
		const result = await picker.onDidDismiss<DurationPickerResult>();

		if (result.data !== undefined) {
			const hourSeconds = result.data.hours.value * 3600;
			const minutesSeconds = result.data.minutes.value * 60;
			const seconds = result.data.seconds.value;

			this.totalSeconds = hourSeconds + minutesSeconds + seconds;
			this.setAnswers(this.totalSeconds + "");
			this.setTimerObserveable();
		}
	}
}

interface DurationTime {
	hours: number;
	minutes: number;
	seconds: number;
}

interface DurationPickerResult {
	hours: DurationPickerResultItem;
	minutes: DurationPickerResultItem;
	seconds: DurationPickerResultItem;
}

interface DurationPickerResultItem {
	columnIndex: number;
	text: string;
	value: number;
}

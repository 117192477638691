import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { combineLatest } from "rxjs";
import { LocationStateQuery } from "../../configuration/state/location-state.query";
import { LocationStateService } from "../../configuration/state/location-state.service";
import { UiQuery } from "../../configuration/state/ui.query";
import { ChecklistModalService } from "../../features/checklists/modals/checklist-modal.service";
import { OperatorService } from "../../configuration/services/operator.service";

@Component({
	selector: "app-close-location",
	templateUrl: "./close-location.component.html",
	styleUrls: ["./close-location.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class CloseLocationComponent implements OnInit {
	constructor(
		private modalController: ModalController,
		private alertController: AlertController,
		private translocoService: TranslocoService,
		private uiQuery: UiQuery,
		private locationStateService: LocationStateService,
		private locationStateQuery: LocationStateQuery,
		private checklistModalService: ChecklistModalService,
		private operatorService: OperatorService,
	) {}

	isClosing: boolean;
	dispatches: number;
	riders: number;
	allowChangingDispatchesWhenClosingLocation$ = this.uiQuery.allowChangingDispatchesWhenClosingLocation$;
	allowChangingRidersWhenClosingLocation$ = this.uiQuery.allowChangingRidersWhenClosingLocation$;
	initialize = async () => {
		this.dispatches = await this.getDispatches();
		this.riders = await this.getRiders();
	};
	close = async () => {
		this.isClosing = true;

		try {
			const [inputCycles, inputRiders, closingChecklist] = await combineLatest([
				this.allowChangingDispatchesWhenClosingLocation$,
				this.allowChangingRidersWhenClosingLocation$,
				this.locationStateQuery.closingChecklist$,
			]).firstAsync();

			if (closingChecklist != null) {
				const closingChecklistResult = await this.checklistModalService.show("Closing");
				if (closingChecklistResult == false) {
					return;
				}
			}

			let dispatches = this.dispatches;
			let riders = this.riders;

			if (inputCycles === false) {
				dispatches = 0;
			}

			if (inputRiders === false) {
				riders = 0;
			}

			await this.locationStateService.closeLocation(dispatches, riders);
			await this.modalController.dismiss();
			await this.askForLogout();
		} catch {
			const alert = await this.alertController.create({
				message: this.translocoService.translate("CLOSE_LOCATION__ERROR_MESSAGE"),
				buttons: [this.translocoService.translate("CLOSE_LOCATION__OK")],
				backdropDismiss: true,
			});

			await alert.present();
		} finally {
			this.isClosing = false;
		}
	};
	askForLogout = async () => {
		const askLogout = await this.alertController.create({
			message: this.translocoService.translate("CLOSE_LOCATION__WOULD_YOU_LIKE_TO_SIGN_OUT"),
			id: "close-location-logout-modal",
			buttons: [
				{
					text: this.translocoService.translate("CLOSE_LOCATION__REMAIN_LOGGED_IN"),
					role: "cancel",
				},
				{
					text: this.translocoService.translate("CLOSE_LOCATION__SIGN_OUT"),
					role: "ok",
					handler: () => {
						this.operatorService.logoutOperator();
					},
				},
			],
		});

		await askLogout.present();
	};
	private getDispatches = () => this.locationStateQuery.dispatches$.firstAsync();
	private getRiders = () => this.locationStateQuery.riders$.firstAsync();
	ngOnInit() {
		this.initialize();
	}
}

<app-question-template [form]="form" [question]="question">
	<!-- Show checkboxes if option lines are too long -->
	<ng-container *ngIf="isSingleLineMode; else buttons">
		<ion-item *ngFor="let option of options" (click)="select(option.selectQuestionOption.id)">
			<ion-checkbox
				class="select-question"
				mode="ios"
				justify="start"
				labelPlacement="end"
				[checked]="isOptionSelected(option.selectQuestionOption.id)"
			>
				<ion-icon slot="start" *ngIf="option.showTrigger" name="clipboard-outline"></ion-icon>
				{{ option.selectQuestionOption.text }}
			</ion-checkbox>
		</ion-item>
	</ng-container>

	<!-- Otherwise show two buttons per row -->
	<ng-template #buttons>
		<ion-grid>
			<ion-row>
				<ion-col *ngFor="let option of options" size="6">
					<ion-button
						expand="block"
						shape="round"
						[color]="isOptionSelected(option.selectQuestionOption.id) ? 'primary' : 'light'"
						(click)="select(option.selectQuestionOption.id)"
					>
						{{ option.selectQuestionOption.text }}
						<ion-icon slot="start" *ngIf="option.showTrigger" name="clipboard-outline"></ion-icon>
					</ion-button>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ng-template>
</app-question-template>

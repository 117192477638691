import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";
import { Checklist, Response } from "../checklist.interface";

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "checklistProgress", resettable: true })
export class ChecklistProgressStateStore extends Store<ChecklistProgressState> {
	private static createInitialState = (): ChecklistProgressState => ({
		checklist: undefined,
		currentPageIndex: 0,
		responses: [],
	});
	constructor() {
		super(ChecklistProgressStateStore.createInitialState());
	}

	clear() {
		this.update(ChecklistProgressStateStore.createInitialState());
	}
}

export interface ChecklistProgressState {
	checklist: Checklist;
	currentPageIndex: number;
	responses: Response[];
}

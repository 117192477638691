import { firstValueFrom, Observable } from "rxjs";
export {};

declare module "rxjs" {
	interface Observable<T> {
		firstAsync(): Promise<T>;
	}
}

declare global {
	interface Date {
		addHours(hours: number): Date;
	}
}

export const sleep = (duration: number) => new Promise((resolve) => setTimeout(resolve, duration));

Observable.prototype.firstAsync = function () {
	return firstValueFrom(this);
};

Date.prototype.addHours = function (hours: number) {
	this.setTime(this.getTime() + hours * 60 * 60 * 1000);
	return this;
};

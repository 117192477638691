import { ApplicationRef, Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { AlertController } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { concat, interval } from "rxjs";
import { filter, first } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class UpdateService {
	static updateAlertId = "update-alert";

	constructor(
		private updates: SwUpdate,
		private appRef: ApplicationRef,
		private alertController: AlertController,
		private translocoService: TranslocoService,
	) {}

	private activated: boolean;
	public activate = () => {
		if (this.activated) {
			return;
		}

		this.activated = true;

		this.updates.versionUpdates.pipe(filter((event) => event.type === "VERSION_READY")).subscribe(() => {
			this.blockForUpdate();
		});

		const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
		const everyHour$ = interval(60 * 60 * 1000);
		const everyHourOnceAppIsStable$ = concat(appIsStable$, everyHour$);

		everyHourOnceAppIsStable$.subscribe(() => {
			this.updates.checkForUpdate().catch(() => {
				//we just do a noop incase we are in dev or incognito
			});
		});

		this.updates.unrecoverable.subscribe((_event) => {
			//The app is in an unrecoverable state, because the user has an old version of the app
			//where some of the .js files have been removed from the cache, and cant be loaded
			this.showUnrecoverableError();
		});
	};

	public checkForUpdate = async (): Promise<boolean> => {
		try {
			return await this.updates.checkForUpdate();
		} catch (error) {
			// checkForUpdate will fail if running in dev or incognito, without the serviceworker installed
			return false;
		}
	};

	private showUnrecoverableError = async () => {
		const alert = await this.alertController.create({
			header: this.translocoService.translate("UNRECOVERABLE_ERROR_DETECTED__TITLE"),
			message: this.translocoService.translate("UNRECOVERABLE_ERROR_DETECTED__MESSAGE"),
			buttons: [this.translocoService.translate("UNRECOVERABLE_ERROR_DETECTED__OK")],
			backdropDismiss: false,
		});

		await alert.present();
		await alert.onDidDismiss();

		document.location.reload();
	};

	private blockForUpdate = async () => {
		const alert = await this.alertController.create({
			header: this.translocoService.translate("UPDATE_MODAL__TITLE"),
			message: this.translocoService.translate("UPDATE_MODAL__MESSAGE"),
			buttons: [this.translocoService.translate("UPDATE_MODAL__OK")],
			backdropDismiss: false,
			id: UpdateService.updateAlertId,
		});

		await alert.present();
		await alert.onDidDismiss();

		document.location.reload();
	};
}

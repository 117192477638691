import { HttpClientModule } from "@angular/common/http";
import { ErrorHandler, Injector, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { AkitaNgDevtools } from "@datorama/akita-ngdevtools";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ComponentsModule } from "./components/components.module";
import "./configuration/extensions";
import { httpInterceptorProviders } from "./configuration/interceptors";
import { RaygunErrorHandler } from "./configuration/raygun-error-handler";
import { TranslocoRootModule } from "./configuration/transloco/transloco-root.module";
import { preLoadTranslocoLanguage } from "./configuration/transloco/transloco.preload";
import { ChecklistModule } from "./features/checklists/checklist.module";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { IonicStorageModule } from "@ionic/storage-angular";
import { QuillModule } from "ngx-quill";
import { AssignmentModule } from "./features/assignments/modals/assignment-modal.module";

export let ServiceLocator: Injector;

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		IonicModule.forRoot({ mode: "md", innerHTMLTemplatesEnabled: true }),
		QuillModule.forRoot(),
		AppRoutingModule,
		IonicStorageModule.forRoot(),
		environment.isProduction ? [] : AkitaNgDevtools.forRoot(),
		HttpClientModule,
		TranslocoRootModule,
		ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.isProduction }),
		ComponentsModule,
		ChecklistModule,
		AssignmentModule,
		NgxExtendedPdfViewerModule,
		BrowserAnimationsModule,
	],
	providers: [
		httpInterceptorProviders,
		{ provide: ErrorHandler, useClass: RaygunErrorHandler },
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		preLoadTranslocoLanguage,
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(injector: Injector) {
		ServiceLocator = injector;
	}
}

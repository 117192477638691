import { Component, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { TextEditorModalService } from "source/app/modals/text-editor/text-editor-modal.service";
import { TextEditorComponent } from "source/app/modals/text-editor/text-editor.component";

@Component({
	selector: "app-assignment-description",
	templateUrl: "./assignment-description.component.html",
	styleUrls: ["./assignment-description.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AssignmentDescriptionComponent),
			multi: true,
		},
	],
})
export class AssignmentDescriptionComponent implements ControlValueAccessor {
	constructor(private textEditorModalService: TextEditorModalService) {}

	public description: string;

	edit = async () => {
		const modal = await this.textEditorModalService.create(this.description || "");
		modal.present();

		const result = await modal.onWillDismiss();

		if (result.role === TextEditorComponent.save) {
			this.description = result.data;
			this.onTouched();
			this.onFormChange(result.data);
		}
	};

	//Form Setup
	onFormChange = (_description: string) => {};
	onTouched = () => {};

	disabled = false;

	writeValue(description: string): void {
		this.description = description;
	}

	registerOnChange(fn: (description: string) => void): void {
		this.onFormChange = fn;
	}
	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}

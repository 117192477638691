import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SegmentCustomEvent } from "@ionic/angular";
import { Observable } from "rxjs";

@Component({
	selector: "app-pagination",
	templateUrl: "./pagination.component.html",
	styleUrls: ["pagination.component.scss"],
})
export class PaginationComponent {
	@Input() current!: string;
	@Input() pages!: ChecklistPaginationPage[];
	@Input() isFirstPage: boolean;
	@Input() isLastPage: boolean;

	@Output() goTo: EventEmitter<string> = new EventEmitter<string>();
	@Output() next: EventEmitter<void> = new EventEmitter<void>();
	@Output() previous: EventEmitter<void> = new EventEmitter<void>();

	protected previousPage = (): void => {
		if (this.isFirstPage) {
			return;
		}
		this.previous.emit();
	};

	protected nextPage = (): void => {
		if (this.isLastPage) {
			return;
		}
		this.next.emit();
	};

	protected onPageSelected = (event: Event): void => {
		const pageId = (<SegmentCustomEvent>event).detail.value;
		if (pageId != undefined) {
			this.goTo.emit(pageId as string);
		}
	};
}

export interface ChecklistPaginationPage {
	id: string;
	isCompleted$: Observable<boolean>;
	isVisible$: Observable<boolean>;
}

import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { Observable, switchMap } from "rxjs";
import { Action, ActionType, CreateNoteAction } from "./action.model";
import { ActionsStore, ActionsState } from "./actions.store";
import { LocationStateQuery } from "./location-state.query";

@Injectable({ providedIn: "root" })
export class ActionsQuery extends QueryEntity<ActionsState> {
	constructor(
		protected override store: ActionsStore,
		private locationStateQuery: LocationStateQuery,
	) {
		super(store);
	}

	createNoteActionsFilteredByCurrentLocation$: Observable<Action<CreateNoteAction>[]> =
		this.locationStateQuery.location$.pipe(
			switchMap((location) => {
				return this.selectAll({
					filterBy: (entity) => entity.actionType == ActionType.CREATE_NOTE && entity.location == location.id,
				});
			}),
		);
}

import { Injectable } from "@angular/core";
import { AvailableLangs, LangDefinition, TranslocoService } from "@ngneat/transloco";

@Injectable({
	providedIn: "root",
})
export class LanguageService {
	constructor(private translocoService: TranslocoService) {}

	getAvailableLangs = (): LangDefinition[] => {
		const languages = this.translocoService.getAvailableLangs();
		if (this.islangDefinitionArray(languages)) {
			return languages as LangDefinition[];
		} else {
			throw Error("Transloco getAvailableLangs did not return LangDefinition");
		}
	};

	private islangDefinitionArray = (availableLanguages: AvailableLangs): boolean => {
		if (availableLanguages.length == 0) {
			return true;
		}

		const firstItem = availableLanguages[0] as LangDefinition;
		return (
			"label" in firstItem &&
			typeof firstItem.label === "string" &&
			"id" in firstItem &&
			typeof firstItem.id === "string"
		);
	};
}

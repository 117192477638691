<ion-item (click)="selectAssignees()" lines="full">
	<ion-icon name="person-outline" slot="start"></ion-icon>
	<ion-label>
		{{ "ASSIGNMENT__ASSIGNEE" | transloco }}
		@if (assignees.length === 0) {
			<p>
				<ion-text color="danger">{{ "ASSIGNMENT__ASSIGNEE_EMPTY" | transloco }}</ion-text>
			</p>
		}
	</ion-label>
	<div class="assignees-names">
		@for (assignee of assignees; track assignee.id) {
			<li>{{ assignee.name }}</li>
		} @empty {
			<li class="placeholder">{{ "ASSIGNMENT__TAP_TO_SELECT" | transloco }}</li>
		}
	</div>
</ion-item>

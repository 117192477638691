import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { TextElement } from "../../../checklist.interface";

@Component({
	selector: "app-text-element",
	templateUrl: "./text-element.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextElementComponent {
	@Input() element: TextElement;
}

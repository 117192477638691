<app-question-template [form]="form" [question]="question">
	<ion-item>
		<ion-input
			label="{{ 'CHECKLISTS__QUESTIONS__EMAIL__PLACEHOLDER' | transloco }}"
			labelPlacement="stacked"
			#questionEmail
			(ionInput)="onChange($event)"
			type="email"
			[value]="model.value"
			required
		></ion-input>
	</ion-item>
</app-question-template>

import { ChangeDetectionStrategy, Component, forwardRef } from "@angular/core";
import { ScannerQuestion } from "../../../checklist.interface";
import { QuestionComponent } from "../question.component";
import { ScannerModalService } from "source/app/modals/scanner/scanner-modal.service";
import { AlertController } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "app-scanner-question",
	templateUrl: "./scanner-question.component.html",
	styleUrls: ["./scanner-question.component.scss"],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ScannerQuestionComponent),
			multi: true,
		},
	],
})
export class ScannerQuestionComponent extends QuestionComponent<ScannerQuestion> implements ControlValueAccessor {
	constructor(
		private scannerModalService: ScannerModalService,
		private alertController: AlertController,
		private translocoService: TranslocoService,
	) {
		super();
	}

	private verifyAnswer = async (answer: string): Promise<boolean> => {
		if (this.question.validation === "None") {
			return true;
		}
		if (this.question.validation === "Specific") {
			return answer === this.question.code;
		}
		if (this.question.validation === "Location") {
			const location = await this.locationStateQuery.location$.firstAsync();
			return answer === location.scannerCode || answer === location.id;
		}
		return false;
	};

	scan = async () => {
		const scannerModal = await this.scannerModalService.create();
		await scannerModal.present();

		const result = await scannerModal.onDidDismiss<string>();
		const hasAnswer = typeof result.data === "string" && result.data.length > 0;
		const isVerified = hasAnswer && (await this.verifyAnswer(result.data));

		this.setAnswers(hasAnswer && isVerified ? result.data : null);

		if (hasAnswer === true && isVerified === false) {
			const alert = await this.alertController.create({
				header: this.translocoService.translate("SCANNER_QUESTION_VALIDATION_FAILED_TITLE"),
				message: this.translocoService.translate("SCANNER_QUESTION_VALIDATION_FAILED_DESCRIPTION"),
				buttons: [this.translocoService.translate("SCANNER_QUESTION_VALIDATION_FAILED_BUTTON_OK")],
			});

			await alert.present();
		}
	};
}

import { Component, Input, OnInit } from "@angular/core";
import { ServiceLocator } from "../../../../app.module";
import {
	AssignmentTrigger,
	AssignmentWithData,
	FormAnswerGroup,
	FormQuestion,
	LogicalCondition,
	Question,
	SubmitResponseAdHocScheduleSlotFromTrigger,
} from "../../checklist.interface";
import { ChecklistProgressStateQuery } from "../../state/checklist-progress-state.query";
import { ChecklistProgressStateService } from "../../state/checklist-progress-state.service";
import { ControlValueAccessor, FormControl, FormGroup, FormRecord } from "@angular/forms";
import { PositionService } from "source/app/configuration/services/position.service";
import { LocationStateQuery } from "source/app/configuration/state/location-state.query";
import { AssignmentService } from "source/app/features/assignments/services/assignment.service";

@Component({ template: "" })
export class QuestionComponent<T extends Question> implements ControlValueAccessor, OnInit {
	protected checklistProgressService = ServiceLocator.get(ChecklistProgressStateService);
	protected checklistProgressQuery = ServiceLocator.get(ChecklistProgressStateQuery);
	protected positionService = ServiceLocator.get(PositionService);
	protected locationStateQuery = ServiceLocator.get(LocationStateQuery);
	protected assignmentService = ServiceLocator.get(AssignmentService);

	@Input() question: T;
	@Input() form: FormRecord<FormGroup<FormAnswerGroup>>;

	public answer: FormQuestion | undefined;
	private assignmentTriggers: AssignmentTrigger[] = [];
	private assignmentsFormControl: FormControl<AssignmentWithData[]>;

	protected setAnswers = async (...answers: string[]): Promise<void> => {
		answers = (answers || []).filter((answer) => answer?.length > 0);
		answers = await this.checkAssignmentTriggers(answers);

		if (answers.length === 0) {
			answers = undefined;
			this.answer = undefined;
			this.onFormChange(null);
		} else {
			const operator = await this.locationStateQuery.selectOperator$.firstAsync();

			const formAnswer: FormQuestion = {
				answers: answers,
				answered: new Date().toISOString(),
				position: this.positionService.getCurrentPosition(),
				user: operator.id,
				adHocScheduleSlots: this.getAdHocScheduleSlots(answers),
			};
			this.answer = formAnswer;
			this.onFormChange(formAnswer);
		}
	};

	private triggersOnAnswers = (condition: LogicalCondition, answers: string[]): boolean => {
		//should we activate on other things than eq/neq
		return (
			(condition.comparer === "EQ" && answers.includes(condition.right)) ||
			(answers.length > 0 && condition.comparer === "NEQ" && answers.includes(condition.right) === false)
		);
	};

	private checkAssignmentTriggers = async (answers: string[]): Promise<string[]> => {
		if (this.assignmentTriggers.length === 0) {
			return answers;
		}

		let assignments = this.assignmentsFormControl.value || [];
		const assignmentsFromtriggers = assignments.filter((assignment) => assignment.assignment.fromTrigger != null);
		const trigger = this.assignmentTriggers[0];

		if (this.triggersOnAnswers(trigger.condition, answers)) {
			//if we trigger, we create and assignment, if the user bails, we remove the answer option
			const assignment = await this.assignmentService.createAssignmentFromTrigger(trigger, this.question.id);
			if (assignment != null) {
				assignments.push(assignment);
			} else {
				if (trigger.condition.comparer === "EQ") {
					answers = answers.filter((x) => x !== trigger.condition.right);
				}
				if (trigger.condition.comparer === "NEQ") {
					answers = [];
				}
			}
		}

		//Afterwards we remove any orphaned assignments, that are not longer beeing triggered
		const orphaned = assignmentsFromtriggers.filter(
			(x) =>
				x.assignment.origin.question === this.question.id &&
				x.assignment.fromTrigger &&
				this.triggersOnAnswers(x.assignment.origin.triggerCondition, answers) === false,
		);

		assignments = assignments.filter((x) => orphaned.includes(x) === false);

		//finally we update the assignmentsForm
		this.assignmentsFormControl.setValue(assignments);
		this.assignmentsFormControl.updateValueAndValidity({ onlySelf: false, emitEvent: false });

		return answers;
	};

	getAdHocScheduleSlots = (answers: string[]) => {
		const adHocScheduleSlots: SubmitResponseAdHocScheduleSlotFromTrigger[] = [];

		const slotTriggers = this.question.triggers.filter(
			(trigger) => trigger.type === "AdHocScheduleSlotTrigger",
		) as SubmitResponseAdHocScheduleSlotFromTrigger[];

		slotTriggers.forEach((trigger) => {
			if (this.triggersOnAnswers(trigger.condition, answers)) {
				adHocScheduleSlots.push({
					question: this.question.id,
					...trigger,
					condition: trigger.condition,
				});
			}
		});

		return adHocScheduleSlots;
	};

	//Form Setup
	onFormChange = (_answer: FormQuestion) => {};
	onTouched = () => {};

	disabled = false;

	ngOnInit(): void {
		const questionFormControl: FormGroup<FormAnswerGroup> = this.form.get(
			this.question.id,
		) as FormGroup<FormAnswerGroup>;

		this.assignmentsFormControl = questionFormControl.controls.assignments;

		this.assignmentTriggers = this.question.triggers.filter(
			(trigger) => trigger.type === "AssignmentTrigger",
		) as AssignmentTrigger[];
	}

	registerOnChange(fn: (value: FormQuestion) => void): void {
		this.onFormChange = fn;
	}
	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	writeValue(answer: FormQuestion | undefined): void {
		if (answer != undefined) {
			this.answer = answer;
		}
	}
}

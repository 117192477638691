import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ImageViewerComponent } from "./image-viewer.component";

@Injectable()
export class ImageViewerModalService {
	constructor(private modalController: ModalController) {}

	create = (imageUrl: string) =>
		this.modalController.create({
			id: ImageViewerComponent.modalId,
			cssClass: "image-viewer-modal",
			component: ImageViewerComponent,
			componentProps: {
				imageUrl: imageUrl,
			},
		});
}

import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { AssigneePickerModalComponent } from "./assignee-picker.modal.component";
import { ApiService } from "source/app/configuration/services/api.service";
import { AssignmentDefinition } from "../../assignment.interface";

@Injectable()
export class AssigneePickerModalService {
	constructor(
		private modalcontroller: ModalController,
		private apiService: ApiService,
	) {}

	create = async (assignees: { id: string; name: string }[], definition: AssignmentDefinition) => {
		const result = await this.apiService.assignees(definition?.id);

		return this.modalcontroller.create({
			id: AssigneePickerModalComponent.modalId,
			component: AssigneePickerModalComponent,
			cssClass: "assignee-picker-modal",
			componentProps: {
				possibleAssignees: result.data,
				assignees: assignees,
				singleSelect: definition?.assigneeSelectionMode === "Single" || false,
			},
		});
	};
}

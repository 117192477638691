import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ScannerComponent } from "./scanner.component";

@Injectable()
export class ScannerModalService {
	constructor(private modalController: ModalController) {}

	create = () => {
		return this.modalController.create({
			id: ScannerComponent.modalId,
			cssClass: "scanner-modal",
			component: ScannerComponent,
		});
	};
}

import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { OperatorLoginComponent } from "../operator-login.component";

@Injectable()
export class OperatorLoginModal {
	constructor(private modalController: ModalController) {}

	async show(selectedOperator: Operator): Promise<Operator> {
		const modal = await this.modalController.create({
			component: OperatorLoginComponent,
			id: "operator-login-modal",
			componentProps: {
				operator: selectedOperator,
			},
		});

		await modal.present();

		const value = await modal.onDidDismiss();
		return value?.data?.operator;
	}
}

<ion-app>
	<app-current-user></app-current-user>

	<ion-split-pane contentId="main" when="(min-width: 0px)">
		<ion-menu contentId="main">
			<ion-content>
				<app-menu-bar></app-menu-bar>
			</ion-content>
		</ion-menu>

		<ion-router-outlet id="main"></ion-router-outlet>
	</ion-split-pane>

	<app-environment-bar></app-environment-bar>
</ion-app>

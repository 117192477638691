import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslocoModule } from "@ngneat/transloco";
import { VideoPlayerModalService } from "./video-player-modal.service";
import { VideoPlayerComponent } from "./video-player.component";

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, TranslocoModule],
	declarations: [VideoPlayerComponent],
	providers: [VideoPlayerModalService],
})
export class VideoPlayerModule {}

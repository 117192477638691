import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { FileUploadsComponent } from "./file-uploads.component";

@Injectable()
export class FileUploadsModalService {
	constructor(private modalController: ModalController) {}

	create = (fileUploads: string[]) => {
		return this.modalController.create({
			id: "",
			cssClass: "file-uploads-modal",
			component: FileUploadsComponent,
			componentProps: {
				files: fileUploads,
			},
		});
	};
}

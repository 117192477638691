import { Injectable } from "@angular/core";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { FileTranslation } from "./file-translation.model";

export type FileTranslationsState = EntityState<FileTranslation>;

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "fileTranslations", resettable: true })
export class FileTranslationsStore extends EntityStore<FileTranslationsState> {
	constructor() {
		super();
	}
}

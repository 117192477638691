import { ChangeDetectionStrategy, Component, OnInit, forwardRef } from "@angular/core";
import { MobaroTrigger, SelectQuestion, SelectQuestionOption } from "../../../checklist.interface";
import { QuestionComponent } from "../question.component";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "app-select-question",
	templateUrl: "./select-question.component.html",
	styleUrls: ["./select-question.component.scss"],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectQuestionComponent),
			multi: true,
		},
	],
})
export class SelectQuestionComponent extends QuestionComponent<SelectQuestion> implements OnInit, ControlValueAccessor {
	isOptionSelected = (option: string) => this.answer?.answers?.includes(option);

	options: { selectQuestionOption: SelectQuestionOption; showTrigger: boolean }[] = [];

	isSingleLineMode: boolean;
	select = (option: string) => {
		const currentAnswers = this.answer?.answers || [];
		const isOptionAlreadySelected = this.isOptionSelected(option);

		this.onTouched();

		if (this.question.variation === "Multiple") {
			const answers = isOptionAlreadySelected
				? currentAnswers.filter((answer) => answer !== option)
				: currentAnswers.concat([option]);

			this.setAnswers(...answers);
		} else {
			const answer = isOptionAlreadySelected ? undefined : option;
			this.setAnswers(answer);
		}
	};

	private showAssignmentTrigger = (option: SelectQuestionOption, triggers: MobaroTrigger[]): boolean => {
		return triggers?.some((trigger) => {
			if (trigger.type !== "AssignmentTrigger" || trigger.requireNoIntervention) {
				return false;
			}

			const triggersOnEquals = trigger.condition.comparer == "EQ";
			const triggersOnNotEqual = trigger.condition.comparer === "NEQ";
			const refersToThisOption = trigger.condition.right === option.id;

			return (triggersOnEquals && refersToThisOption) || (triggersOnNotEqual && refersToThisOption === false);
		});
	};

	override ngOnInit() {
		super.ngOnInit();
		this.isSingleLineMode = this.question.options.some((option) => option.text.length > 24);

		this.options = this.question.options.map((option) => {
			return {
				selectQuestionOption: option,
				showTrigger: this.showAssignmentTrigger(option, this.question.triggers),
			};
		});
	}
}

<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="statistics-container">
	<div class="header-container">
		<div class="headline">
			<h1>{{ "STATISTICS_PAGE__HEADLINE" | transloco }}</h1>
			<h3>{{ "STATISTICS_PAGE__SUB_HEADLINE" | transloco }}</h3>
		</div>
		<ion-icon name="close" (click)="close()"></ion-icon>
	</div>

	<app-offline-message *ngIf="offline" [showRetry]="true" (retry)="getStatistics()"></app-offline-message>

	<table *ngIf="statistics != undefined" class="statistics-table">
		<tr>
			<td></td>
			<td class="header column-header">
				{{ "STATISTICS_PAGE__TODAY" | transloco }}
			</td>
			<td class="header column-header">
				{{ "STATISTICS_PAGE__LAST_HOUR" | transloco }}
			</td>
			<td class="header column-header">
				{{ "STATISTICS_PAGE__YOUR_SHIFT" | transloco }}
			</td>
		</tr>

		<tr class="border-bottom">
			<td (click)="select(StatisticsMetric.CapacityUtilization)" class="header row-header">
				<ion-icon
					name="{{
						(dashboardStatisticsMetric$ | async) == StatisticsMetric.CapacityUtilization
							? 'checkmark-circle'
							: 'ellipse-outline'
					}}"
				></ion-icon>
				<span>{{ "STATISTICS_PAGE__RIDE_UTILIZATION" | transloco }}</span>
			</td>
			<td>
				<app-statistics-number
					[value]="statistics.today.rideUtilization"
					[postfix]="'%'"
					[digitsInfo]="'1.1-1'"
				></app-statistics-number>
			</td>
			<td>
				<app-statistics-number
					[value]="statistics.lastHour.rideUtilization"
					[postfix]="'%'"
					[digitsInfo]="'1.1-1'"
				></app-statistics-number>
			</td>
			<td>
				<app-statistics-number
					[value]="statistics.userShift.rideUtilization"
					[postfix]="'%'"
					[digitsInfo]="'1.1-1'"
				></app-statistics-number>
			</td>
		</tr>

		<tr class="border-bottom">
			<td (click)="select(StatisticsMetric.DispatchUtilization)" class="header row-header">
				<ion-icon
					name="{{
						(dashboardStatisticsMetric$ | async) == StatisticsMetric.DispatchUtilization
							? 'checkmark-circle'
							: 'ellipse-outline'
					}}"
				></ion-icon>
				<span>{{ "STATISTICS_PAGE__CART_UTILIZATION" | transloco }}</span>
			</td>
			<td>
				<app-statistics-number
					[value]="statistics.today.cartUtilization"
					[postfix]="'%'"
					[digitsInfo]="'1.1-1'"
				></app-statistics-number>
			</td>
			<td>
				<app-statistics-number
					[value]="statistics.lastHour.cartUtilization"
					[postfix]="'%'"
					[digitsInfo]="'1.1-1'"
				></app-statistics-number>
			</td>
			<td>
				<app-statistics-number
					[value]="statistics.userShift.cartUtilization"
					[postfix]="'%'"
					[digitsInfo]="'1.1-1'"
				></app-statistics-number>
			</td>
		</tr>

		<tr class="border-bottom">
			<td (click)="select(StatisticsMetric.Dispatches)" class="header row-header">
				<ion-icon
					name="{{
						(dashboardStatisticsMetric$ | async) == StatisticsMetric.Dispatches
							? 'checkmark-circle'
							: 'ellipse-outline'
					}}"
				></ion-icon>
				<span>{{ "STATISTICS_PAGE__DISPATCHES" | transloco }}</span>
			</td>
			<td>
				<app-statistics-number [value]="statistics.today.dispatches"></app-statistics-number>
			</td>
			<td>
				<app-statistics-number [value]="statistics.lastHour.dispatches"></app-statistics-number>
			</td>
			<td>
				<app-statistics-number [value]="statistics.userShift.dispatches"></app-statistics-number>
			</td>
		</tr>

		<tr class="border-bottom">
			<td (click)="select(StatisticsMetric.Riders)" class="header row-header">
				<ion-icon
					name="{{
						(dashboardStatisticsMetric$ | async) == StatisticsMetric.Riders
							? 'checkmark-circle'
							: 'ellipse-outline'
					}}"
				></ion-icon>
				<span>{{ "STATISTICS_PAGE__RIDERS" | transloco }}</span>
			</td>
			<td>
				<app-statistics-number [value]="statistics.today.riders"></app-statistics-number>
			</td>
			<td>
				<app-statistics-number [value]="statistics.lastHour.riders"></app-statistics-number>
			</td>
			<td>
				<app-statistics-number [value]="statistics.userShift.riders"></app-statistics-number>
			</td>
		</tr>

		<tr class="border-bottom">
			<td (click)="select(StatisticsMetric.AverageDispatchTime)" class="header row-header">
				<ion-icon
					name="{{
						(dashboardStatisticsMetric$ | async) == StatisticsMetric.AverageDispatchTime
							? 'checkmark-circle'
							: 'ellipse-outline'
					}}"
				></ion-icon>
				<span>{{ "STATISTICS_PAGE__AVG_CYCLE_TIME" | transloco }}</span>
			</td>
			<td>
				<app-statistics-time [totalSeconds]="statistics.today.averageTime"></app-statistics-time>
			</td>
			<td>
				<app-statistics-time [totalSeconds]="statistics.lastHour.averageTime"></app-statistics-time>
			</td>
			<td>
				<app-statistics-time [totalSeconds]="statistics.userShift.averageTime"></app-statistics-time>
			</td>
		</tr>

		<tr>
			<td (click)="select(StatisticsMetric.Uptime)" class="header row-header">
				<ion-icon
					name="{{
						(dashboardStatisticsMetric$ | async) == StatisticsMetric.Uptime
							? 'checkmark-circle'
							: 'ellipse-outline'
					}}"
				></ion-icon>
				<span>{{ "STATISTICS_PAGE__UPTIME" | transloco }}</span>
			</td>
			<td>
				<app-statistics-time [totalSeconds]="statistics.today.uptime"></app-statistics-time>
			</td>
			<td>
				<app-statistics-time [totalSeconds]="statistics.lastHour.uptime"></app-statistics-time>
			</td>
			<td>
				<app-statistics-time [totalSeconds]="statistics.userShift.uptime"></app-statistics-time>
			</td>
		</tr>
	</table>
</div>

import { Injectable } from "@angular/core";
import { NavigationEnd, NavigationError, Router } from "@angular/router";
import rg4js from "raygun4js";
import { environment } from "../../../environments/environment";
import { EnvironmentQuery } from "../state/environment.query";
import { EnvironmentState } from "../state/environment.state";
import { LocationStateQuery } from "../state/location-state.query";

@Injectable({
	providedIn: "root",
})
export class RaygunService {
	constructor(
		private locationStateQuery: LocationStateQuery,
		private environmentQuery: EnvironmentQuery,
		private router: Router,
	) {}

	private activated: boolean | undefined;
	public activate = async () => {
		if (this.activated) {
			return;
		}

		this.activated = true;

		this.trackActiveOperator();
		this.trackNavigationChanges();
		this.configureForActiveEnvironment();
	};

	public send = (error: Error, customData: object) => {
		rg4js("send", {
			error: error,
			customData: customData,
		});
	};

	private trackActiveOperator = () =>
		this.locationStateQuery.selectOperator$.subscribe((operator) => {
			if (operator == undefined) {
				rg4js("setUser", {
					identifier: null,
					isAnonymous: true,
				});
			} else {
				rg4js("setUser", {
					identifier: operator.id,
					isAnonymous: false,
					fullName: operator.name,
				});
			}
		});

	private trackNavigationChanges = () =>
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				rg4js("trackEvent", {
					type: "pageView",
					path: event.url,
				});
			} else if (event instanceof NavigationError) {
				rg4js("send", { error: event.error });
			}
		});

	private configureForActiveEnvironment = () =>
		this.environmentQuery.environment$.subscribe((backendEnvironment) => {
			const isDevelopment = backendEnvironment.environment == EnvironmentState.Development;

			rg4js("withTags", [backendEnvironment.name, environment.buildNumber]);
			rg4js("options", { disableErrorTracking: isDevelopment });
		});
}

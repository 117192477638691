import { Component, OnDestroy, OnInit } from "@angular/core";
import { Html5Qrcode, Html5QrcodeCameraScanConfig } from "html5-qrcode";
import { ModalController, LoadingController, AlertController } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";

@Component({
	selector: "app-scanner",
	templateUrl: "scanner.component.html",
	styleUrls: ["./scanner.component.scss"],
})
export class ScannerComponent implements OnInit, OnDestroy {
	static modalId = "scanner-component";

	constructor(
		private modalController: ModalController,
		private loadingController: LoadingController,
		private alertController: AlertController,
		private translocoService: TranslocoService,
	) {}

	cameraScanConfig: Html5QrcodeCameraScanConfig = {
		fps: 10,
		qrbox: { width: 250, height: 250 },
	};
	html5QrCode: Html5Qrcode | null;

	state: "loading" | "ready" = "loading";

	private scan = async () => {
		const loading = await this.loadingController.create({
			message: this.translocoService.translate("SCANNER_MODAL__OPENING_CAMERA"),
		});

		loading.present();

		try {
			const cameras = await Html5Qrcode.getCameras();
			this.html5QrCode = new Html5Qrcode("reader");

			await this.html5QrCode.start(
				cameras[0].id,
				this.cameraScanConfig,
				(decodedText) => this.dismiss(decodedText),
				undefined, //TS definition file says this is required
			);

			this.state = "ready";
			loading.dismiss();
		} catch {
			loading.dismiss();

			const alert = await this.alertController.create({
				header: this.translocoService.translate("SCANNER_MODAL__ERROR_ALERT__HEADLINE"),
				message: this.translocoService.translate("SCANNER_MODAL__ERROR_ALERT__MESSAGE"),
				buttons: [this.translocoService.translate("SCANNER_MODAL__ERROR_ALERT__OK")],
			});

			await alert.present();
			this.dismiss(null);
		}
	};

	ngOnDestroy(): void {
		this.html5QrCode?.stop();
	}

	ngOnInit(): void {
		this.scan();
	}

	async dismiss(scannerCode: string | null) {
		await this.modalController.dismiss(scannerCode, null, ScannerComponent.modalId);
	}
}

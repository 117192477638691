/**
 * Check if all elements in the comparisonProperties array are present in the sourceProperties array.
 *
 * @param sourceProperties The array of source properties to compare against.
 * @param comparisonProperties The array of properties to check for presence in the sourceProperties array.
 * @returns A boolean indicating whether all elements in comparisonProperties are present in sourceProperties.
 */
export function containsAll(sourceProperties: string[], comparisonProperties: string[]) {
	return comparisonProperties.every((property) => sourceProperties.includes(property));
}

/**
 * Check if any element in the comparisonProperties array is present in the sourceProperties array.
 *
 * @param sourceProperties The array of source properties to compare against.
 * @param comparisonProperties The array of properties to check for presence in the sourceProperties array.
 * @returns A boolean indicating whether any element in comparisonProperties is present in sourceProperties.
 */
export function containsAny(sourceProperties: string[], comparisonProperties: string[]) {
	return comparisonProperties.some((property) => sourceProperties.includes(property));
}

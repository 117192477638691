import { Component, Input } from "@angular/core";

@Component({
	selector: "app-grouping",
	styleUrls: ["./grouping.component.scss"],
	template: `
		<div class="grouping">
			<div class="label">{{ label | transloco }}</div>
			<div class="content">
				<ng-content></ng-content>
			</div>
		</div>
	`,
})
export class GroupingComponent {
	@Input() label: string;
}

export enum ElementType {
	TextElement = "TextElement",
	ImageElement = "ImageElement",
	SelectQuestion = "SelectQuestion",
	TextQuestion = "TextQuestion",
	ImageQuestion = "ImageQuestion",
	DateTimeQuestion = "DateTimeQuestion",
	NumberQuestion = "NumberQuestion",
	EmailQuestion = "EmailQuestion",
	ScannerQuestion = "ScannerQuestion",
	DurationQuestion = "DurationQuestion",
	SignatureQuestion = "SignatureQuestion",
	UnknownQuestion = "UnknownQuestion",
}

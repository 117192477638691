import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ValuePickerComponent, ValuePickerModel } from "./value-picker.component";

@Injectable()
export class ValuePickerModalService {
	constructor(private modalController: ModalController) {}

	create = (model: ValuePickerModel) =>
		this.modalController.create({
			id: ValuePickerComponent.modalId,
			component: ValuePickerComponent,
			componentProps: model,
			showBackdrop: true,
		});
}

<ion-item lines="full">
	<ion-icon name="chatbubble-outline" slot="start"></ion-icon>
	<ion-label>{{ "ASSIGNMENT__DESCRIPTION" | transloco }}</ion-label>
	@if (description === "") {
		<div class="placeholder" *ngIf="description === ''" (click)="edit()">
			{{ "ASSIGNMENT__TAP_TO_SELECT" | transloco }}
		</div>
	} @else {
		<quill-view (click)="edit()" [content]="description" theme="snow"></quill-view>
	}
</ion-item>

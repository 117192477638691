import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild, forwardRef } from "@angular/core";
import { IonInput, TextareaCustomEvent } from "@ionic/angular";
import { EmailQuestion, FormQuestion } from "../../../checklist.interface";
import { QuestionComponent } from "../question.component";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "app-email-question",
	templateUrl: "./email-question.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => EmailQuestionComponent),
			multi: true,
		},
	],
})
export class EmailQuestionComponent
	extends QuestionComponent<EmailQuestion>
	implements AfterViewInit, ControlValueAccessor
{
	@ViewChild("questionEmail") IonInput!: IonInput;
	inputField!: HTMLInputElement;

	model: EmailQuestionModel = {
		value: null,
	};

	onChange = (event: Event) => {
		const value = (<TextareaCustomEvent>event).target.value;
		this.setAnswers(this.inputField.checkValidity() ? value : null);
	};

	override writeValue(answer: FormQuestion | undefined): void {
		super.writeValue(answer);
		if (answer?.answers) {
			this.model.value = answer.answers[0];
		}
	}

	async ngAfterViewInit() {
		this.IonInput.getInputElement().then((value) => {
			this.inputField = value;
		});
	}
}

interface EmailQuestionModel {
	value: string | null;
}

import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from "@angular/core";

@Component({
	selector: "app-number-selector",
	templateUrl: "./number-selector.component.html",
	styleUrls: ["./number-selector.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberSelectorComponent implements OnChanges {
	@Input() selectedValue: number;
	@Output() selectedValueChange = new EventEmitter<number>();

	model: NumberSelectorModel = {
		thousands: 0,
		hundreds: 0,
		tens: 0,
		single: 0,
	};

	addType(type: "thousands" | "hundreds" | "tens" | "single") {
		if (this.model[type] < 9) {
			this.model[type]++;
		} else {
			this.model[type] = 0;
		}

		this.setValueFromModel();
	}

	subtractType(type: "thousands" | "hundreds" | "tens" | "single") {
		if (this.model[type] > 0) {
			this.model[type]--;
		} else {
			this.model[type] = 9;
		}

		this.setValueFromModel();
	}

	add(amount = 1) {
		this.setValue(this.selectedValue + amount);
		this.setModelFromValue();
	}

	subtract(amount = 1) {
		this.setValue(this.selectedValue - amount);
		this.setModelFromValue();
	}

	private setModelFromValue() {
		if (this.selectedValue == null) {
			this.selectedValue = 0;
		}

		const valueString = String(this.selectedValue).padStart(4, "0");

		this.model = {
			thousands: parseInt(valueString[0]),
			hundreds: parseInt(valueString[1]),
			tens: parseInt(valueString[2]),
			single: parseInt(valueString[3]),
		};
	}

	private setValueFromModel() {
		const totalString = "" + this.model.thousands + this.model.hundreds + this.model.tens + this.model.single;
		this.setValue(parseInt(totalString));
	}

	private setValue(value: number) {
		this.selectedValue = value;
		this.selectedValueChange.emit(value);
	}

	ngOnChanges(_changes: SimpleChanges): void {
		this.setModelFromValue();
	}
}

export interface NumberSelectorModel {
	thousands: number;
	hundreds: number;
	tens: number;
	single: number;
}

import { ChangeDetectionStrategy, Component, forwardRef } from "@angular/core";
import { QuestionComponent } from "../question.component";
import { FormQuestion, SignatureQuestion } from "../../../checklist.interface";
import { UtilityService } from "source/app/configuration/services/utility.service";
import { SignatureModalService } from "source/app/modals/signature/signature-modal.service";
import { Subject } from "rxjs";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "app-signature-question",
	templateUrl: "./signature-question.component.html",
	styleUrls: ["./signature-question.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SignatureQuestionComponent),
			multi: true,
		},
	],
})
export class SignatureQuestionComponent extends QuestionComponent<SignatureQuestion> implements ControlValueAccessor {
	constructor(
		private utilityService: UtilityService,
		private signatureModalService: SignatureModalService,
	) {
		super();
	}

	tags = ["Signature"];
	signatureImage = "";

	protected signatureFile$ = new Subject<File>();

	openSignModal = async () => {
		const modal = await this.signatureModalService.create();
		await modal.present();

		const result = await modal.onDidDismiss<File>();

		if (result.data instanceof File) {
			this.signatureFile$.next(result.data);
			this.signatureImage = URL.createObjectURL(result.data);
		}
	};

	private setSignatureFromAnswer = async (signatureAnswer: string) => {
		this.signatureImage = await this.utilityService.getImageUrl(signatureAnswer);
	};

	override writeValue(answer: FormQuestion | undefined): void {
		super.writeValue(answer);
		if (answer?.answers) {
			this.setSignatureFromAnswer(answer.answers[0]);
		}
	}
}

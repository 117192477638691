import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormAnswerGroup, Question } from "../../checklist.interface";

import { CommentComponent } from "../shared/comment/comment.component";
import { AttachmentsComponent } from "../shared/attachments/attachments.component";
import { ControlContainer, FormGroup, FormGroupDirective } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { startWith } from "rxjs";
import { AssignmentsComponent } from "../shared/assignments/assignments.component";

@UntilDestroy()
@Component({
	selector: "app-question-template",
	templateUrl: "./question-template.component.html",
	styleUrls: ["./question-template.component.scss"],
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class QuestionTemplateComponent implements OnInit, AfterViewInit {
	@Input() question: Question;
	@Input() form: FormGroup;

	@ViewChild(CommentComponent, { static: false })
	private commentComponent: CommentComponent;

	@ViewChild(AttachmentsComponent, { static: false })
	private attachmentsComponent: AttachmentsComponent;

	@ViewChild(AssignmentsComponent, { static: false })
	private assignmentsComponents: AssignmentsComponent;

	activateComment = () => {
		this.commentComponent.activateComment();
	};

	activateAttachments = () => {
		this.attachmentsComponent.activateAttachments();
	};

	getImage = () => {
		this.attachmentsComponent.getImage();
	};

	getFromGallery = () => {
		this.attachmentsComponent.getFromGallery();
	};

	createAssignment = () => {
		this.assignmentsComponents.createAssignment();
	};

	showMoreButton = false;

	ngAfterViewInit(): void {
		const questionFormControl: FormGroup<FormAnswerGroup> = this.form.get(
			this.question.id,
		) as FormGroup<FormAnswerGroup>;
		const commentControl = questionFormControl.controls.comment;
		const attachmentFormControl = questionFormControl.controls.attachments;

		commentControl.statusChanges
			.pipe(untilDestroyed(this), startWith(commentControl.status))
			.subscribe((status) => {
				if (status === "INVALID") {
					this.activateComment();
				}
			});

		attachmentFormControl.statusChanges
			.pipe(untilDestroyed(this), startWith(attachmentFormControl.status))
			.subscribe((status) => {
				if (status === "INVALID") {
					this.activateAttachments();
				}
			});
	}

	ngOnInit(): void {
		this.showMoreButton =
			this.question.allowCameraRoll || this.question.allowComments || this.question.allowImageAttachment;
	}
}

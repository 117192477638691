import { APP_INITIALIZER } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { LocationStateQuery } from "../state/location-state.query";
import { LanguageService } from "../services/language.service";

export function preloadLanguage(
	locationStateQuery: LocationStateQuery,
	translocoService: TranslocoService,
	languageService: LanguageService,
) {
	return async function () {
		const [location, operator] = await locationStateQuery.locationAndOperator$.firstAsync();
		const availableLanguages = languageService.getAvailableLangs().map((x) => x.id);

		let language: string = operator?.language || location?.language || "en";

		if (availableLanguages.includes(language) === false) {
			language = "en";
		}

		translocoService.setActiveLang(language);
		return await translocoService.load(language, { fallbackLangs: ["source"] }).firstAsync();
	};
}

export const preLoadTranslocoLanguage = {
	provide: APP_INITIALIZER,
	multi: true,
	useFactory: preloadLanguage,
	deps: [LocationStateQuery, TranslocoService, LanguageService],
};

import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { UserPickerComponent } from "./user-picker.component";
import { RidePosition } from "source/app/configuration/state/location-state.store";
import { LocationStateQuery } from "source/app/configuration/state/location-state.query";
import { OperatorService } from "source/app/configuration/services/operator.service";

@Injectable()
export class UserPickerModal {
	constructor(
		private modalController: ModalController,
		private locationStateQuery: LocationStateQuery,
		private operatorService: OperatorService,
	) {}

	selectAttendant = async (ridePosition: RidePosition) => {
		const attendants = await this.locationStateQuery.attendants$.firstAsync();
		const requiredCompetenciesForAttendants =
			await this.locationStateQuery.requiredCompetenciesForAttendants$.firstAsync();

		const modal = await this.create(
			ridePosition.name,
			[...ridePosition.requiredCompetencies, ...requiredCompetenciesForAttendants],
			attendants,
			ridePosition.attendant?.user?.id,
		);

		await modal.present();
		const result = await modal.onDidDismiss<Operator>();

		if (result.data === undefined) {
			return;
		}

		await this.operatorService.selectAttendant(result.data, ridePosition);
	};

	create = (
		label: string,
		requiredCompetencies: DocumentReference[],
		operators: Operator[],
		currentOperatorId: string | undefined,
	) =>
		this.modalController.create({
			id: "attendant-picker-modal",
			component: UserPickerComponent,
			componentProps: {
				label,
				requiredCompetencies,
				operators,
				currentOperatorId,
			},
		});
}

import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { FileUploadsStore, FileUploadsState } from "./file-uploads.store";

@Injectable({ providedIn: "root" })
export class FileUploadsQuery extends QueryEntity<FileUploadsState> {
	constructor(store: FileUploadsStore) {
		super(store);
	}
}

import { Component, Input } from "@angular/core";
import { Element } from "../../checklist.interface";

@Component({
	selector: "app-element-template",
	templateUrl: "./element-template.component.html",
	styleUrls: ["./element-template.component.scss"],
})
export class ElementTemplateComponent {
	@Input() element: Element;
}

import { Observable, of } from "rxjs";
import { throwExhaustiveError } from "source/app/configuration/errors/exhaustive-error";
import { containsAll, containsAny } from "source/app/configuration/utils";
import { MembershipCondition } from "../../checklist.interface";

/**
 * Evaluates memberships based on the provided comparer and test values.
 *
 * The function handles the following comparers:
 * - "ContainsAny": Returns true if any of the test values are present in the memberships.
 * - "NotContainsAny": Returns true if none of the test values are present in the memberships.
 * - "ContainsAll": Returns true if all of the test values are present in the memberships.
 * - "NotContainsAll": Returns true if not all of the test values are present in the memberships.
 *
 * @param condition - The membership condition to evaluate.
 * @param memberships - An array of memberships to check against the condition.
 * @returns An Observable<boolean> indicating the result of the evaluation.
 * @throws When an unhandled logical comparer is encountered, an exhaustive error is thrown.
 */
export function evaluateMembershipCondition(
	condition: MembershipCondition,
	memberships: string[],
): Observable<boolean> {
	const testValues = condition.right.split(",");

	switch (condition.comparer) {
		case "ContainsAny":
			return of(containsAny(memberships, testValues));
		case "NotContainsAny":
			return of(!containsAny(memberships, testValues));
		case "ContainsAll":
			return of(containsAll(memberships, testValues));
		case "NotContainsAll":
			return of(!containsAll(memberships, testValues));
		default:
			throwExhaustiveError("Unhandled logical comparer", condition.comparer);
	}
}

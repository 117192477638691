<div class="modal-container">
	<h1>{{ label }}</h1>

	<ng-container *ngLet="{ currentOperatorId: currentOperatorId, operators: operators$ | async } as model">
		<div class="users-container">
			<ion-card
				*ngFor="let operator of model.operators"
				class="operator"
				(click)="select(operator)"
				[ngClass]="{ disabled: operator.missingCompetencies?.length > 0 }"
			>
				<ion-card-header>
					<ion-button *ngIf="model.currentOperatorId === operator.id" shape="round" class="logout">
						{{ "OPERATORS_PAGE__LOGOUT" | transloco }}
					</ion-button>
					<app-avatar
						*ngIf="model.currentOperatorId !== operator.id"
						[file]="operator.profileImage"
					></app-avatar>
				</ion-card-header>

				<ion-card-title>{{ operator.name }}</ion-card-title>
			</ion-card>
		</div>

		<div class="no-searchresults" *ngIf="model.operators.length === 0">
			<span class="title">{{ "OPERATORS_PAGE__NO_OPERATORS_WHEN_SEARCHING_FOR" | transloco }}</span>
			<span class="subtitle">&nbsp;{{ userSearch.value }}</span>
		</div>

		<ion-item>
			<ion-input
				type="text"
				placeholder="{{ 'OPERATORS_PAGE__SEARCH' | transloco }}"
				clear-input="true"
				[formControl]="userSearch"
			>
				<div slot="label"><ion-icon name="search"></ion-icon></div>
			</ion-input>
		</ion-item>
	</ng-container>
</div>

import { trigger, style, animate, transition, state } from "@angular/animations";

export const fadeInOnEnter = trigger("fadeInOnEnter", [
	transition(":enter", [style({ opacity: 0 }), animate("500ms ease-out", style({ opacity: 1 }))]),
]);

export const fadeOutOnLeave = trigger("fadeOutOnLeave", [
	transition(":leave", [animate("500ms ease-in", style({ opacity: 0 }))]),
]);

export const scaleOut = trigger("scaleOut", [
	state("false", style({ transform: "scale(1)" })),
	state("true", style({ transform: "scale(0.975)" })),
	transition("false <=> true", animate("150ms ease-in")),
]);

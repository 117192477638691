import { Component, Input, OnInit } from "@angular/core";
import { UtilityService } from "../../../../../configuration/services/utility.service";
import { ValuePickerModalService } from "../../../../../modals/value-picker/value-picker-modal.service";
import { ValuePickerItem } from "../../../../../modals/value-picker/value-picker.component";
import {
	DocumentationReference,
	ElementDocumentation,
	ManualReference,
	VideoReference,
} from "../../../checklist.interface";

@Component({
	selector: "app-documentation",
	templateUrl: "./documentation.component.html",
})
export class DocumentationComponent implements OnInit {
	constructor(
		private valuePickerModalService: ValuePickerModalService,
		private utilityService: UtilityService,
	) {}

	@Input() documentation: ElementDocumentation;
	visible: boolean;
	manuals = async () => {
		const items = this.documentation.manuals.map(async (manual) => ({
			name: this.getManualName(manual),
			url: await this.utilityService.getFileUrl(manual.file),
		}));

		const selection = await this.select("CHECKLISTS__DOCUMENTATION__SELECT_MANUAL", await Promise.all(items));

		this.showSelection(selection);
	};
	videos = async () => {
		const getUrl = (video: VideoReference) => {
			switch (video.type) {
				case "YouTube":
					return `https://www.youtube.com/embed/${video.code}?autohide=1&showinfo=0&rel=0&modestbranding=1`;
				case "Vimeo":
					return `https://player.vimeo.com/video/${video.code}?color=fafafa&title=0&byline=0&portrait=0&badge=0"`;
				default:
					return null;
			}
		};

		const items = this.documentation.videos.map((video) => ({
			name: this.getName(video),
			url: getUrl(video),
		}));

		const selection = await this.select("CHECKLISTS__DOCUMENTATION__SELECT_VIDEO", items);

		this.showSelection(selection);
	};
	links = async () => {
		const items = this.documentation.links.map((link) => ({
			name: this.getName(link),
			url: link.url,
		}));

		const selection = await this.select("CHECKLISTS__DOCUMENTATION__SELECT_LINK", items);

		this.showSelection(selection);
	};
	private showSelection = (selection: DocumentationPickerItem) => {
		if (selection?.url == null) {
			return;
		}

		window.open(selection.url, "_blank");
	};
	private getManualName = (reference: ManualReference) => {
		const name = this.getName(reference);
		return reference.section == null || reference.section.length == 0 ? name : `${name} (${reference.section})`;
	};
	private getName = (reference: DocumentationReference) => {
		return reference.version == null || reference.version.length == 0
			? reference.name
			: `${reference.version}: ${reference.name}`;
	};
	ngOnInit(): void {
		this.visible =
			this.documentation &&
			(this.documentation.links?.length > 0 ||
				this.documentation.manuals?.length > 0 ||
				this.documentation.videos?.length > 0);
	}

	private async select(title: string, elements: DocumentationPickerItem[]): Promise<DocumentationPickerItem> {
		if (elements.length === 1) {
			return elements[0];
		}

		const valuePickerModal = await this.valuePickerModalService.create({
			title: title,
			elements: elements,
		});

		await valuePickerModal.present();

		const response = await valuePickerModal.onDidDismiss<DocumentationPickerItem>();
		const selection = response.data;

		return selection;
	}
}

interface DocumentationPickerItem extends ValuePickerItem {
	url: string;
}

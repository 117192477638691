import { map, Observable } from "rxjs";
import { throwExhaustiveError } from "source/app/configuration/errors/exhaustive-error";
import { VisibilityCondition } from "../../checklist.interface";

/**
 * Evaluates the visibility condition based on the provided elements' visibility status.
 *
 * The function handles the following comparers:
 * - "Visible": Returns the Observable of the element's visibility status.
 * - "NotVisible": Returns an Observable that emits the negation of the element's visibility status.
 *
 * @param condition - The visibility condition to evaluate.
 * @param elementsVisible - An array of objects containing element IDs and their visibility status Observables.
 * @returns An Observable<boolean> representing the evaluation result of the visibility condition.
 * @throws When an unhandled logical comparer is encountered, an exhaustive error is thrown.
 */
export function evaluateVisibilityCondition(
	condition: VisibilityCondition,
	elementsVisible: { id: string; isVisible$: Observable<boolean> }[],
): Observable<boolean> {
	const elementVisible$ = elementsVisible.find((x) => x.id === condition.left)?.isVisible$;

	switch (condition.comparer) {
		case "Visible":
			return elementVisible$;
		case "NotVisible":
			return elementVisible$.pipe(map((visible) => !visible));
		default:
			throwExhaustiveError("Unhandled logical comparer", condition.comparer);
	}
}

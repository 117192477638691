import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";

@Injectable({ providedIn: "root" })
export class LoadingService {
	constructor(
		private loadingController: LoadingController,
		private translocoService: TranslocoService,
	) {}

	createLoader(message = "LOADING_MESSAGE__PLEASE_WAIT") {
		return this.loadingController.create({
			message: this.translocoService.translate(message),
		});
	}
}

import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { combineLatest } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ActionsService } from "source/app/configuration/state/actions.service";
import { LocationStateQuery } from "source/app/configuration/state/location-state.query";
import { NoteTemplateReference } from "source/app/configuration/state/location-state.store";
import { Note } from "source/app/configuration/state/note.model";

@Component({
	selector: "app-note-editor",
	templateUrl: "./note-editor.component.html",
	styleUrls: ["./note-editor.component.scss"],
})
export class NoteEditorComponent implements OnInit {
	constructor(
		private modalController: ModalController,
		private formBuilder: FormBuilder,
		private locationStateQuery: LocationStateQuery,
		private actionsService: ActionsService,
		private translocoService: TranslocoService,
	) {}

	selectedTemplate: NoteTemplateReference;
	form: FormGroup = this.formBuilder.group({
		description: ["", [Validators.required]],
		type: [null, [Validators.required]],
	});

	typeValueChanges$ = this.form.controls["type"].valueChanges.pipe(startWith(null));
	templates$ = this.locationStateQuery.noteTemplates$;

	// Filter out deleted noteTypes unless the current note is of that type
	noteTypes$ = combineLatest([this.locationStateQuery.noteTypes$, this.typeValueChanges$]).pipe(
		map(([noteTypes, typeValue]) => noteTypes.filter((x) => x.isDeleted === false || x.id === typeValue)),
	);

	@Input() mode: NoteEditorComponentMode = NoteEditorComponentMode.Create;
	@Input() noteTemplate: NoteTemplateReference;
	@Input() note: Note;

	isCreateMode = false;

	private getDefaultNoteType = async () => {
		const noteTypes = await this.noteTypes$.firstAsync();

		if (noteTypes.length === 1) {
			return noteTypes[0].id;
		}

		return noteTypes.find((type) => type.isDefault)?.id;
	};
	close = () => {
		this.modalController.dismiss();
	};
	save = () => {
		if (this.form.invalid) {
			return;
		}

		this.actionsService.addCreateNoteAction(this.form.value.description, this.form.value.type);
		this.modalController.dismiss();
	};
	selectTemplate(template: NoteTemplateReference) {
		if (template == this.selectedTemplate) {
			this.selectedTemplate = null;
			this.form.controls["description"].setValue(null);
			this.form.controls["type"].setValue(null);
		} else {
			this.selectedTemplate = template;
			this.form.controls["description"].setValue(template.description);
			this.form.controls["type"].setValue(template.noteType);
		}
	}
	async ngOnInit() {
		let description = "";
		let type: string = await this.getDefaultNoteType();

		if (this.mode == NoteEditorComponentMode.Create) {
			this.isCreateMode = true;
			if (this.noteTemplate != undefined) {
				description = this.noteTemplate.description;
				type = this.noteTemplate.noteType;
			}
		} else {
			description = this.note.description;
			type = this.note.type.id;
		}

		// setTimeout is necessary to emit the value to the forms valueChanges observable
		setTimeout(() => {
			this.form.controls["description"].setValue(description);
			this.form.controls["type"].setValue(type);

			if (this.mode === NoteEditorComponentMode.View) {
				this.form.disable();
			}
		});
	}
}

export enum NoteEditorComponentMode {
	Create,
	View,
}

<div class="signature-content">
	<ion-header>
		<ion-toolbar>
			<ion-title color="primary">{{ "SIGNATURE_MODAL__SIGN" | transloco }}</ion-title>
			<ion-buttons slot="end">
				<ion-button color="primary" (click)="cancel()">
					<ion-icon slot="icon-only" name="close"></ion-icon>
				</ion-button>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>

	<div class="signature-wrapper">
		<canvas #canvas></canvas>
	</div>

	<div class="modal-content-below">
		<div class="signature-help-text">
			<span>{{ "SIGNATURE_MODAL__SIGN_ABOVE" | transloco }}</span>
		</div>
		<div class="button-container">
			<ion-button shape="round" (click)="save()" color="primary">
				{{ "SIGNATURE_MODAL__SAVE" | transloco }}
			</ion-button>
		</div>
	</div>
</div>

import { Directive, ElementRef, OnInit } from "@angular/core";

@Directive({
	selector: "[appAutoFocus]",
})
export class AutoFocusDirective implements OnInit {
	constructor(private elementReference: ElementRef) {}

	ngOnInit(): void {
		setTimeout(() => {
			this.elementReference.nativeElement.setFocus();
		}, 500);
	}
}

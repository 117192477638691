import { Component, Input } from "@angular/core";

@Component({
	selector: "app-statistics-number",
	templateUrl: "./statistics-number.component.html",
	styleUrls: ["./statistics-number.component.scss"],
})
export class StatisticsNumberComponent {
	@Input() value: number;
	@Input() postfix: string;
	@Input() digitsInfo: string;
}

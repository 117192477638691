import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class PositionService {
	constructor() {
		const onSuccess = (response: GeolocationPosition) => {
			this.currentPosition = {
				longitude: response.coords.longitude,
				latitude: response.coords.latitude,
				accuracy: response.coords.accuracy,
				timestamp: new Date(response.timestamp).toISOString(),
			};
		};

		const onError = () => {
			this.currentPosition = undefined;
		};

		navigator.geolocation.watchPosition(onSuccess, onError);
	}
	private currentPosition: MobaroPosition;

	getCurrentPosition = () => this.currentPosition;
}

export interface MobaroPosition {
	longitude: number;
	latitude: number;
	accuracy: number;
	timestamp: string;
}

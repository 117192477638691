<div class="number-selector-container">
	<div class="number-selector">
		<ion-icon class="single-stepper" name="remove-outline" color="primary" (click)="subtract(1)"></ion-icon>

		<div class="digit-container">
			<ion-icon name="chevron-up-outline" color="primary" (click)="addType('thousands')"></ion-icon>
			<div class="digit-value">
				{{ model.thousands }}
			</div>
			<ion-icon name="chevron-down-outline" color="primary" (click)="subtractType('thousands')"></ion-icon>
		</div>

		<div class="digit-container">
			<ion-icon name="chevron-up-outline" color="primary" (click)="addType('hundreds')"></ion-icon>
			<div class="digit-value">
				{{ model.hundreds }}
			</div>
			<ion-icon name="chevron-down-outline" color="primary" (click)="subtractType('hundreds')"></ion-icon>
		</div>

		<div class="digit-container">
			<ion-icon name="chevron-up-outline" color="primary" (click)="addType('tens')"></ion-icon>
			<div class="digit-value">
				{{ model.tens }}
			</div>
			<ion-icon name="chevron-down-outline" color="primary" (click)="subtractType('tens')"></ion-icon>
		</div>

		<div class="digit-container">
			<ion-icon name="chevron-up-outline" color="primary" (click)="addType('single')"></ion-icon>
			<div class="digit-value last-digit">
				{{ model.single }}
			</div>
			<ion-icon name="chevron-down-outline" color="primary" (click)="subtractType('single')"></ion-icon>
		</div>

		<ion-icon class="single-stepper" name="add-outline" color="primary" (click)="add(1)"></ion-icon>
	</div>
</div>

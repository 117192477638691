<ion-toolbar *ngIf="visible">
	<ion-buttons slot="end">
		<ion-button *ngIf="documentation.manuals.length > 0" (click)="manuals()">
			<ion-icon slot="icon-only" name="document-text-outline"></ion-icon>
		</ion-button>
		<ion-button *ngIf="documentation.videos.length > 0" (click)="videos()">
			<ion-icon slot="icon-only" name="film-outline"></ion-icon>
		</ion-button>
		<ion-button *ngIf="documentation.links.length > 0" (click)="links()">
			<ion-icon slot="icon-only" name="link-outline"></ion-icon>
		</ion-button>
	</ion-buttons>
</ion-toolbar>

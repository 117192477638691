import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { OperatorSelectedGuard } from "./configuration/guards/operator-selected.guard";
import { RideOpsKeySetGuard } from "./configuration/guards/rideops-key-set.guard";
import { RideOpsKeyNotSetGuard } from "./configuration/guards/rideops-key-not-set.guard";

const routes: Routes = [
	{
		path: "dashboard",
		loadChildren: () => import("./features/dashboard/dashboard.module").then((m) => m.DashboardModule),
		canActivate: [RideOpsKeySetGuard, OperatorSelectedGuard],
	},
	{
		path: "operators",
		loadChildren: () => import("./features/operators/operators.module").then((m) => m.OperatorsPageModule),
		canActivate: [RideOpsKeySetGuard],
	},
	{
		path: "setup",
		loadChildren: () => import("./features/setup/setup.module").then((m) => m.SetupPageModule),
		canActivate: [RideOpsKeyNotSetGuard],
	},
	{
		path: "manuals",
		loadChildren: () => import("./features/manuals/manuals.module").then((m) => m.ManualsPageModule),
		canActivate: [RideOpsKeySetGuard, OperatorSelectedGuard],
	},
	{
		path: "notes",
		loadChildren: () => import("./features/notes/notes.module").then((m) => m.NotesPageModule),
		canActivate: [RideOpsKeySetGuard, OperatorSelectedGuard],
	},
	{
		path: "",
		redirectTo: "dashboard",
		pathMatch: "full",
	},
	{
		path: "**",
		redirectTo: "dashboard",
		pathMatch: "full",
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from "@angular/core";
import { Checklist, Question } from "../checklist.interface";
import { ElementType } from "../checklist.models";
import { AlertController } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { UpdateService } from "source/app/configuration/services/update.service";

@Injectable()
export class UnsupportedQuestionsService {
	static supportedElements = Object.values(ElementType);
	static unsupportedAnswerValue = "_NOT_SUPPORTED_";

	constructor(
		private alertController: AlertController,
		private translocoService: TranslocoService,
		private updateService: UpdateService,
	) {}

	checkForUnsupportedQuestions = async (checklist: Checklist): Promise<UnsupportedElementsCheckResult> => {
		const unsupportedQuestions = this.getUnsupportedQuestions(checklist);

		if (unsupportedQuestions.length === 0) {
			return UnsupportedElementsCheckResult.Continue;
		}

		const pendingUpdate = await this.updateService.checkForUpdate();

		if (pendingUpdate) {
			this.showUpdateRequired();
			return UnsupportedElementsCheckResult.Cancel;
		}

		const continueDespiteUnsupportedElements = await this.alertUnsupportedQuestions(
			checklist,
			unsupportedQuestions,
		);

		return continueDespiteUnsupportedElements
			? UnsupportedElementsCheckResult.Continue
			: UnsupportedElementsCheckResult.Cancel;
	};

	isElementSupported = (element: ElementType) => UnsupportedQuestionsService.supportedElements.includes(element);

	private showUpdateRequired = async () => {
		//The update service itself, might already be showing an alert to update
		const alertElement = await this.alertController.getTop();
		if (alertElement?.id === UpdateService.updateAlertId) {
			return;
		}

		const alert = await this.alertController.create({
			header: this.translocoService.translate("CHECKLIST_UNSUPPORTED_QUESTIONS_UPDATE_AVAILABLE_HEADER"),
			message: this.translocoService.translate("CHECKLIST_UNSUPPORTED_QUESTIONS_UPDATE_AVAILABLE_MESSAGE"),
			buttons: [this.translocoService.translate("CHECKLIST_UNSUPPORTED_QUESTIONS_UPDATE_AVAILABLE_OK")],
		});

		alert.onDidDismiss().then(() => document.location.reload());

		await alert.present();
	};

	private alertUnsupportedQuestions = async (checklist: Checklist, questions: Question[]): Promise<boolean> => {
		const alert = await this.alertController.create({
			header: this.translocoService.translate("CHECKLIST_UNSUPPORTED_QUESTIONS_HEADER"),
			message: this.translocoService.translate("CHECKLIST_UNSUPPORTED_QUESTIONS_MESSAGE", {
				checklist: checklist.name,
				questions: questions.map((q) => q.text).join(", "),
			}),
			buttons: [
				{
					text: this.translocoService.translate("CHECKLIST_UNSUPPORTED_QUESTIONS_CANCEL"),
					role: "cancel",
				},
				{
					text: this.translocoService.translate("CHECKLIST_UNSUPPORTED_QUESTIONS_CONTINUE"),
					role: "continue",
				},
			],
			backdropDismiss: false,
		});

		await alert.present();
		const response = await alert.onDidDismiss();

		return response.role === "continue";
	};

	private getUnsupportedQuestions = (checklist: Checklist) => {
		return checklist.pages
			.flatMap((page) => page.elements)
			.filter(
				(element) => element.stereotype === "Question" && this.isElementSupported(element.type) === false,
			) as Question[];
	};
}

export enum UnsupportedElementsCheckResult {
	Continue,
	Cancel,
}

import { Component } from "@angular/core";
import { RaygunService } from "./configuration/services/raygun.service";
import { UpdateService } from "./configuration/services/update.service";
import { LocationStateService } from "./configuration/state/location-state.service";

@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
	styleUrls: ["app.component.scss"],
})
export class AppComponent {
	constructor(
		updateService: UpdateService,
		raygunService: RaygunService,
		locationStateService: LocationStateService,
	) {
		raygunService.activate();
		updateService.activate();

		locationStateService.activate();
	}
}

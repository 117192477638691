import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup, FormRecord } from "@angular/forms";
import { ChecklistPageWithVisibility } from "../modals/checklist-modal.component";
import { FormAnswerGroup } from "../checklist.interface";

@Component({
	selector: "app-checklist",
	templateUrl: "./checklist.component.html",
	changeDetection: ChangeDetectionStrategy.Default,
})
export class ChecklistComponent {
	@Input() page!: ChecklistPageWithVisibility;
	@Input() form: FormRecord<FormGroup<FormAnswerGroup>>;
}

<ion-list>
	<ion-item (click)="changeLanguage()">
		<ion-label>{{ "SETTINGS__LANGUAGE" | transloco }}</ion-label>
		<ion-note slot="end">{{ currentLanguage$ | async }}</ion-note>
	</ion-item>

	<ion-item (click)="setDashboardStatistics()" *ngIf="allowStatisticsForOperator$ | async">
		<ion-label>{{ "SETTINGS__DASHBOARD_STATISTICS" | transloco }}</ion-label>
		<ion-note slot="end">{{ dashboardStatisticsMetricName$ | async }}</ion-note>
	</ion-item>

	<ion-item>
		<ion-toggle
			[disabled]="locked$ | async"
			(ionChange)="setRidersWidgetVisibility($event)"
			[checked]="(widgetOptions$ | async).riders.visible"
		>
			{{ "SETTINGS__SHOW_RIDERS_WIDGET" | transloco }}
		</ion-toggle>
	</ion-item>

	<ion-item>
		<ion-toggle
			[disabled]="locked$ | async"
			(ionChange)="setQueueWidgetVisibility($event)"
			[checked]="(widgetOptions$ | async).queueTime.visible"
		>
			{{ "SETTINGS__SHOW_QUEUE_TIME_WIDGET" | transloco }}
		</ion-toggle>
	</ion-item>

	<ion-item>
		<ion-toggle
			[disabled]="locked$ | async"
			(ionChange)="setInputCyclesOnClose($event)"
			[checked]="allowChangingDispatchesWhenClosingLocation$ | async"
		>
			{{ "SETTINGS__INPUT_CYCLES_AT_CLOSE" | transloco }}
		</ion-toggle>
	</ion-item>

	<ion-item>
		<ion-toggle
			[disabled]="locked$ | async"
			(ionChange)="setInputRidersOnClose($event)"
			[checked]="allowChangingRidersWhenClosingLocation$ | async"
		>
			{{ "SETTINGS__INPUT_RIDERS_AT_CLOSE" | transloco }}
		</ion-toggle>
	</ion-item>

	<ion-item>
		<ion-button style="width: 100%" (click)="changeLocation()" color="primary">
			{{ "SETTINGS__CHANGE_LOCATION" | transloco }}
		</ion-button>
	</ion-item>

	<ion-item>
		<ion-button style="width: 100%" (click)="changePin()" color="primary">
			{{ "SETTINGS__CHANGE_PIN" | transloco }}
		</ion-button>
	</ion-item>
</ion-list>

<ion-button style="margin: 10px" expand="full" size="small" (click)="factoryReset()" color="warning">
	{{ "SETTINGS__FACTORY_RESET" | transloco }}
</ion-button>

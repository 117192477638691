/**
 * Custom error class for handling exhaustive errors.
 *
 * @param message The error message.
 * @param customData Additional custom data related to the error.
 */
class ExhaustiveError extends Error {
	constructor(
		message: string,
		public customData: unknown,
	) {
		super(message);
		this.name = "ExhaustiveError";
	}
}

/**
 * Throws an ExhaustiveError with the provided message and custom data.
 *
 * @param {string} message - The error message to be displayed.
 * @param {unknown} customData - Additional custom data related to the error.
 * @returns {never} This function never returns a value as it always throws an error.
 */
export function throwExhaustiveError(message: string, customData: never): never;
export function throwExhaustiveError(message: string, customData: unknown) {
	throw new ExhaustiveError(message, customData);
}

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { NavController } from "@ionic/angular";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { LocationStateQuery } from "../state/location-state.query";

@Injectable({
	providedIn: "root",
})
export class RideOpsKeyNotSetGuard {
	constructor(
		private locationStateQuery: LocationStateQuery,
		private navController: NavController,
	) {}

	canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
		const rideOpsKeyNotSet$ = this.locationStateQuery.rideOpsKeyIsSet$.pipe(
			map((rideOpsKeySet) => {
				return rideOpsKeySet == false;
			}),
		);

		return rideOpsKeyNotSet$.pipe(
			tap((rideOpsKeyNotSet) => {
				if (rideOpsKeyNotSet == false) {
					this.navController.navigateRoot("operators");
				}
			}),
		);
	}
}

import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
	selector: "app-image-viewer",
	templateUrl: "./image-viewer.component.html",
})
export class ImageViewerComponent {
	static modalId: "image-viewer-component";

	constructor(private modalController: ModalController) {}

	@Input() imageUrl: string;

	close = () => {
		this.modalController.dismiss();
	};
}

import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { map } from "rxjs/operators";
import { EnvironmentStore } from "./environment.store";
import { EnvironmentState } from "./environment.state";

@Injectable({ providedIn: "root" })
export class EnvironmentQuery extends Query<{ environment: EnvironmentState }> {
	constructor(protected override store: EnvironmentStore) {
		super(store);
	}

	readonly configurations: BackEndConfiguration[] = [
		{
			environment: EnvironmentState.Development,
			name: "Development",
			api: {
				url: "http://localhost:5000/",
			},
			frontEnd: {
				url: "http://localhost:5001/",
			},
		},
		{
			environment: EnvironmentState.Staging,
			name: "Staging",
			api: {
				url: "https://app.staging.mobaro.com/",
			},
			frontEnd: {
				url: "https://app.staging.mobaro.com/",
			},
		},
		{
			environment: EnvironmentState.Production,
			name: "Production",
			api: {
				url: "https://app.mobaro.com/",
			},
			frontEnd: {
				url: "https://app.mobaro.com/",
			},
		},
	];

	getAvailableEnvironments = () => {
		return this.configurations.map((config) => {
			return { id: config.environment, name: config.name };
		});
	};

	environment$ = this.select((state) => state).pipe(
		map((state) => {
			return this.configurations[state.environment];
		}),
	);
}

export interface BackEndConfiguration {
	environment: EnvironmentState;
	name: string;
	api: {
		url: string;
	};
	frontEnd: {
		url: string;
	};
}

import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { SignatureComponent } from "./signature.component";

@Injectable()
export class SignatureModalService {
	constructor(private modalController: ModalController) {}

	create = () => {
		return this.modalController.create({
			id: SignatureComponent.modalId,
			cssClass: "signature-modal",
			component: SignatureComponent,
		});
	};
}

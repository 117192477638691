import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { NavController } from "@ionic/angular";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { LocationStateQuery } from "../state/location-state.query";

@Injectable({
	providedIn: "root",
})
export class OperatorSelectedGuard {
	constructor(
		private locationStateQuery: LocationStateQuery,
		private navController: NavController,
	) {}

	canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
		return this.locationStateQuery.operatorIsSelected$.pipe(
			tap((operatorIsSelected) => {
				if (!operatorIsSelected) {
					this.navController.navigateRoot("operators");
				}
			}),
		);
	}
}

<ion-header>
	<ion-toolbar>
		<ion-title>{{ "ASSIGNMENT_MODAL__TITLE" | transloco }}</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="close()">
				<ion-icon slot="icon-only" name="close-outline"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content [formGroup]="assignmentForm" *ngIf="ready">
	<ion-list>
		@if (trigger != null) {
			<ion-item>
				@if (trigger.description == "") {
					<ion-label>{{ "ASSIGNMENT_MODAL__DESCRIPTION" | transloco }}</ion-label>
				} @else {
					<ion-label class="trigger-description">{{ trigger.description }}</ion-label>
				}
			</ion-item>
		}

		<ion-item lines="full">
			<ion-icon aria-hidden="true" name="build-outline" slot="start"></ion-icon>
			<ion-input label="{{ 'ASSIGNMENT__TITLE' | transloco }}" formControlName="title"></ion-input>
		</ion-item>

		<app-assignment-description formControlName="description"></app-assignment-description>

		<!-- hidden if there is a solution -->
		<ion-item *ngIf="definition && assignmentForm.controls.solution.value == null" lines="full">
			<ion-icon aria-hidden="true" name="build-outline" slot="start"></ion-icon>
			<ion-select
				formControlName="definitionState"
				label="{{ 'ASSIGNMENT__STATE' | transloco }}"
				[value]="this.assignment.definitionState"
				[compareWith]="compareWith"
			>
				<ion-select-option *ngFor="let state of states" [value]="state">
					{{ state.name }}
				</ion-select-option>
			</ion-select>
		</ion-item>

		<!-- shows state from solution if solution is present -->
		<ion-item *ngIf="definition && assignmentForm.controls.solution.value?.definitionState != null" lines="full">
			<ion-icon aria-hidden="true" name="build-outline" slot="start"></ion-icon>
			<ion-select
				label="{{ 'ASSIGNMENT__STATE' | transloco }}"
				[value]="assignmentForm.controls.solution.value.definitionState"
				disabled="true"
			>
				<ion-select-option [value]="assignmentForm.controls.solution.value.definitionState">
					{{ assignmentForm.controls.solution.value.definitionState.name }}
				</ion-select-option>
			</ion-select>
		</ion-item>

		<ion-item *ngIf="definition && viewConfig.showPriority" lines="full">
			<ion-icon name="star-outline" slot="start"></ion-icon>
			<ion-select
				formControlName="definitionPriority"
				label="{{ 'ASSIGNMENT__PRIORITY' | transloco }}"
				[value]="assignment.definitionPriority"
				[compareWith]="compareWith"
			>
				<ion-select-option *ngFor="let priority of definition.priorities" [value]="priority">
					{{ priority.name }}
				</ion-select-option>
			</ion-select>
		</ion-item>

		<ion-item *ngIf="definition == null && viewConfig.showPriority" lines="full">
			<ion-icon name="star-outline" slot="start"></ion-icon>
			<ion-toggle formControlName="priority" color="danger">
				{{ "ASSIGNMENT__PRIORITY" | transloco }}
				<ion-text *ngIf="assignmentForm.controls.priority.value === false">
					{{ "ASSIGNMENT__PRIORITY_NO" | transloco }}
				</ion-text>
				<ion-text *ngIf="assignmentForm.controls.priority.value" color="danger">
					{{ "ASSIGNMENT__PRIORITY_YES" | transloco }}
				</ion-text>
			</ion-toggle>
		</ion-item>

		<app-asset formControlName="asset"></app-asset>

		<app-assignees
			*ngIf="viewConfig.showAssignee"
			formControlName="assignees"
			[definition]="definition"
		></app-assignees>

		<ion-item lines="full" *ngIf="viewConfig.showCategories">
			<ion-icon name="pricetag-outline" slot="start"></ion-icon>
			<ion-select
				formControlName="categories"
				label="{{ 'ASSIGNMENT__CATEGORIES' | transloco }}"
				placeholder="{{ 'ASSIGNMENT__TAP_TO_SELECT' | transloco }}"
				[multiple]="true"
				[value]="assignmentForm.controls.categories.value"
			>
				<ion-select-option *ngFor="let category of categories" [value]="category">
					{{ category }}
				</ion-select-option>
			</ion-select>
		</ion-item>

		<ion-item
			*ngIf="definition && definition.categories.length > 0 && viewConfig.showDefinitionCategories"
			lines="full"
		>
			<ion-icon name="pricetag-outline" slot="start"></ion-icon>
			<ion-select
				formControlName="definitionCategories"
				label="{{ 'ASSIGNMENT__DEFINITION_CATEGORIES' | transloco }}"
				placeholder="{{ 'ASSIGNMENT__TAP_TO_SELECT' | transloco }}"
				[multiple]="definition.categorySelectionType === 'Multiple'"
			>
				<ion-select-option *ngFor="let category of definition.categories" [value]="category.id">
					{{ category.name }}
				</ion-select-option>
			</ion-select>
		</ion-item>

		<ion-item lines="full" *ngIf="viewConfig.showDateTime">
			<ion-icon name="time-outline" slot="start"></ion-icon>
			<ion-label>
				{{ "ASSIGNMENT__TIME_START" | transloco }}
			</ion-label>
			<ion-datetime-button datetime="start">
				<ion-icon
					name="calendar"
					slot="date-target"
					*ngIf="assignmentForm.controls.start.value === undefined"
				></ion-icon>
				<ion-icon
					name="time-outline"
					slot="time-target"
					*ngIf="assignmentForm.controls.start.value === undefined"
				></ion-icon>
			</ion-datetime-button>
		</ion-item>
		<ion-item lines="full" *ngIf="viewConfig.showDateTime">
			<ion-icon name="time-outline" slot="start"></ion-icon>
			<ion-label>
				{{ "ASSIGNMENT__DEADLINE" | transloco }}
			</ion-label>
			<ion-datetime-button datetime="deadline">
				<ion-icon
					name="calendar"
					slot="date-target"
					*ngIf="assignmentForm.controls.end.value === undefined"
				></ion-icon>
				<ion-icon
					name="time-outline"
					slot="time-target"
					*ngIf="assignmentForm.controls.end.value === undefined"
				></ion-icon>
			</ion-datetime-button>
		</ion-item>

		<ion-item>
			<app-attachments formControlName="attachments" [showAttachments]="true"></app-attachments>
		</ion-item>

		<app-solution formControlName="solution" [defintion]="definition"></app-solution>
	</ion-list>

	<ion-popover [keepContentsMounted]="true">
		<ng-template>
			<ion-datetime
				formControlName="start"
				showClearButton="true"
				mode="md"
				name="start"
				preferWheel="false"
				[showDefaultButtons]="true"
				[firstDayOfWeek]="1"
				id="start"
			></ion-datetime>
		</ng-template>
	</ion-popover>

	<ion-popover [keepContentsMounted]="true">
		<ng-template>
			<ion-datetime
				formControlName="end"
				showClearButton="true"
				mode="md"
				name="deadline"
				[min]="assignmentForm.controls.start.value"
				preferWheel="false"
				[showDefaultButtons]="true"
				[firstDayOfWeek]="1"
				id="deadline"
			></ion-datetime>
		</ng-template>
	</ion-popover>
</ion-content>
<ion-footer *ngIf="ready">
	<ion-toolbar>
		<ion-item>
			<div class="button-container">
				<ion-button (click)="save()" shape="round" [disabled]="assignmentForm.invalid">
					<ion-label>{{ "ASSIGNMENT__BUTTON_SAVE" | transloco }}</ion-label>
				</ion-button>
			</div>
		</ion-item>
	</ion-toolbar>
</ion-footer>

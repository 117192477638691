import { Injectable } from "@angular/core";
import {
	distinctUntilChanged,
	fromEvent,
	map,
	merge,
	Observable,
	of,
	shareReplay,
	startWith,
	switchMap,
	throttleTime,
	timer,
} from "rxjs";

@Injectable({
	providedIn: "root",
})
export class IdleService {
	private activityEvents = [
		fromEvent(window, "mousemove"),
		fromEvent(window, "resize"),
		fromEvent(document, "keyup"),
		fromEvent(document, "touchend"),
		fromEvent(document, "visibilitychange"),
	];

	private isInactiveAfterMilliseconds: number = 2.5 * 60000; // 2.5 minutes;

	isInactive$: Observable<boolean> = merge(...this.activityEvents).pipe(
		throttleTime(1000), // Ignore multiple event in the same second.
		switchMap(() =>
			merge(
				// Emit `false` immediately on activity
				of(false),
				// Emit `true` after inactivity threshold
				timer(this.isInactiveAfterMilliseconds).pipe(map(() => true)),
			),
		),
		distinctUntilChanged(),
		startWith(false),
		shareReplay(1), // Use as a "hot" observable.
	);
}

/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ModalController } from "@ionic/angular";

@Component({
	selector: "video-player",
	templateUrl: "./video-player.component.html",
	styleUrls: ["./video-player.component.scss"],
	providers: [],
})
export class VideoPlayerComponent implements OnInit {
	static modalId = "video-player-component";

	constructor(
		private modalController: ModalController,
		private sanitizer: DomSanitizer,
	) {}

	@Input() video: VideoVersion;
	videoUrl: SafeResourceUrl;

	ngOnInit() {
		const id = this.video?.providerSettings?.videoId;
		const type = this.video?.providerSettings?.type;

		if (type == "Vimeo") {
			this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
				"https://player.vimeo.com/video/" + id + "?byline=0&portrait=0",
			);
		} else if (type == "YouTube") {
			this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/" + id);
		}
	}
}

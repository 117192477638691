import { Injectable } from "@angular/core";
import { Action } from "./action.model";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ActionsState = EntityState<Action<any>>;

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "actions", resettable: true })
export class ActionsStore extends EntityStore<ActionsState> {
	constructor() {
		super();
	}
}

/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
	selector: "value-picker",
	templateUrl: "./value-picker.component.html",
})
export class ValuePickerComponent implements ValuePickerModel, OnInit {
	static modalId = "value-picker-component";

	constructor(private modalController: ModalController) {}

	@Input() title: string;
	@Input() description: string;
	@Input() searchPlaceholder: string;
	@Input() elements: ValuePickerItem[];

	protected filteredElements: ValuePickerItem[];

	search = (searchTerm: string = null) => {
		if (searchTerm == null || searchTerm == "") {
			this.filteredElements = this.elements;
			return;
		}

		this.filteredElements = this.elements.filter(
			(x) => x.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1,
		);
	};
	ngOnInit(): void {
		this.search();
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSearchChange(event: any) {
		this.search(event.detail?.value);
	}
	async dismiss(element: ValuePickerItem) {
		await this.modalController.dismiss(element, null, ValuePickerComponent.modalId);
	}
}

export interface ValuePickerModel {
	title: string;
	description?: string;
	searchPlaceholder?: string;
	elements: ValuePickerItem[];
}

export interface ValuePickerItem {
	name: string;
	id?: string;
}

<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div *ngIf="numbers.length > 0" class="number-grid">
	<div
		class="number"
		[ngClass]="{ selected: number == selectedValue }"
		*ngFor="let number of numbers"
		(click)="select(number)"
	>
		{{ number }}
	</div>
</div>

<div class="no-seats" *ngIf="numbers.length == 0">
	{{ "WIDGET__NO_SEATS_ARE_CURRENTLY_AVAILABLE" | transloco }}
</div>

import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { SolutionDetailsModalComponent } from "./solution-details.modal.component";
import { AssignmentDefinition, AssignmentSolutionData } from "../../assignment.interface";

@Injectable()
export class AssignmentSolutionDetailsModalService {
	constructor(private modalController: ModalController) {}

	create = async (solution: AssignmentSolutionData, definition: AssignmentDefinition) =>
		this.modalController.create({
			id: SolutionDetailsModalComponent.modalId,
			component: SolutionDetailsModalComponent,
			cssClass: "assignment-solution-details-modal",
			componentProps: {
				solution: solution,
				definition: definition,
			},
		});
}

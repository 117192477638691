import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { AssetPickerModalComponent } from "./asset-picker.modal.component";
import { ApiService } from "source/app/configuration/services/api.service";

@Injectable()
export class AssetPickerModalService {
	constructor(
		private modalController: ModalController,
		private apiService: ApiService,
	) {}

	create = async () => {
		const asssets = await this.apiService.assets();
		return this.modalController.create({
			id: AssetPickerModalComponent.modalId,
			component: AssetPickerModalComponent,
			cssClass: "asset-picker-modal",
			componentProps: {
				assetHierarchy: asssets,
			},
		});
	};
}

import { Observable, of } from "rxjs";
import { throwExhaustiveError } from "source/app/configuration/errors/exhaustive-error";
import { StateCondition } from "../../checklist.interface";

/**
 * Evaluates a state condition based on the provided comparer and state value.
 *
 * The function handles the following comparers:
 * - "EQ": Checks if the state is equal to the condition's right value.
 * - "NEQ": Checks if the state is not equal to the condition's right value.
 *
 * @param condition - The StateCondition object containing the comparer and right value to compare.
 * @param state - The current state value to compare against.
 * @returns An Observable<boolean> indicating the result of the evaluation.
 * @throws When an unhandled logical comparer is encountered, an exhaustive error is thrown.
 */
export function evaluateStateCondition(condition: StateCondition, state: string): Observable<boolean> {
	switch (condition.comparer) {
		case "EQ":
			return of(state === condition.right);
		case "NEQ":
			return of(state !== condition.right);
		default:
			throwExhaustiveError("Unhandled logical comparer", condition.comparer);
	}
}

<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div id="pin-input">
	<div class="pin-container">
		<div class="pin-placeholder">
			<ion-icon name="ellipse-outline" *ngIf="pin.length == 0"></ion-icon>
			<ion-icon name="ellipse" *ngIf="pin.length >= 1"></ion-icon>
			<ion-icon name="ellipse-outline" *ngIf="pin.length <= 1"></ion-icon>
			<ion-icon name="ellipse" *ngIf="pin.length >= 2"></ion-icon>
			<ion-icon name="ellipse-outline" *ngIf="pin.length <= 2"></ion-icon>
			<ion-icon name="ellipse" *ngIf="pin.length >= 3"></ion-icon>
			<ion-icon name="ellipse-outline" *ngIf="pin.length <= 3"></ion-icon>
			<ion-icon name="ellipse" *ngIf="pin.length == 4"></ion-icon>
		</div>
	</div>

	<div class="numpad">
		<div class="number-row">
			<div class="number field" (click)="add(1)">1</div>
			<div class="number field" (click)="add(2)">2</div>
			<div class="number field" (click)="add(3)">3</div>
		</div>
		<div class="number-row">
			<div class="number field" (click)="add(4)">4</div>
			<div class="number field" (click)="add(5)">5</div>
			<div class="number field" (click)="add(6)">6</div>
		</div>
		<div class="number-row">
			<div class="number field" (click)="add(7)">7</div>
			<div class="number field" (click)="add(8)">8</div>
			<div class="number field" (click)="add(9)">9</div>
		</div>
		<div class="number-row">
			<div class="text field" (click)="clear()" [ngClass]="{ disabled: pin.length <= 0 }">
				{{ "OPERATOR_LOGIN__CLEAR" | transloco }}
			</div>
			<div class="number field" (click)="add(0)">0</div>
			<div class="text field" (click)="delete()" [ngClass]="{ disabled: pin.length <= 0 }">
				{{ "OPERATOR_LOGIN__DELETE" | transloco }}
			</div>
		</div>
	</div>
</div>

<ion-toolbar>
	<ion-buttons slot="start">
		<ion-button shape="round" fill="clear" (click)="previousPage()" [disabled]="isFirstPage">
			<ion-icon name="caret-back-outline"></ion-icon>
		</ion-button>
	</ion-buttons>

	<div class="pagination-container">
		<ion-segment
			[scrollable]="true"
			[value]="current"
			[mode]="'md'"
			(ionChange)="onPageSelected($event)"
			selectOnFocus="true"
		>
			<ng-container *ngFor="let page of pages">
				<ion-segment-button
					[value]="page.id"
					[ngStyle]="{ '--background-focused': '#ffffff' }"
					class="indicator"
				>
					<ng-container *ngIf="page.isCompleted$ | async; else unanswered">
						<ion-icon name="ellipse" color="primary"></ion-icon>
					</ng-container>

					<ng-template #unanswered>
						<ion-icon name="ellipse-outline"></ion-icon>
					</ng-template>
				</ion-segment-button>
			</ng-container>
		</ion-segment>
	</div>

	<ion-buttons slot="end">
		<ion-button shape="round" fill="clear" (click)="nextPage()" [disabled]="isLastPage">
			<ion-icon name="caret-forward-outline"></ion-icon>
		</ion-button>
	</ion-buttons>
</ion-toolbar>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslocoModule } from "@ngneat/transloco";
import { ScannerComponent } from "./scanner.component";
import { ScannerModalService } from "./scanner-modal.service";

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, TranslocoModule],
	declarations: [ScannerComponent],
	providers: [ScannerModalService],
})
export class ScannerModule {}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslocoModule } from "@ngneat/transloco";
import { ImageViewerComponent } from "./image-viewer.component";
import { ImageViewerModalService } from "./image-viewer.modal.service";

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, TranslocoModule],
	declarations: [ImageViewerComponent],
	providers: [ImageViewerModalService],
})
export class ImageViewerModule {}

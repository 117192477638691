import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";

@Injectable({
	providedIn: "root",
})
export class NotificationService {
	constructor(private toastController: ToastController) {}

	async showErrorToast(message: string): Promise<void> {
		const toast = await this.toastController.create({
			message,
			icon: "warning",
			color: "danger",
			duration: 5000,
			position: "top",
		});

		toast.present();
	}
}

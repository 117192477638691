<app-question-template [form]="form" [question]="question">
	<div class="checkmark-container">
		<ion-icon *ngIf="answer?.answers?.length === 1" name="checkmark-circle-outline"></ion-icon>
	</div>

	<div class="button-container">
		<ion-button shape="round" (click)="scan()">
			<ion-label>{{ "CHECKLISTS__QUESTIONS__SCAN__SCAN" | transloco }}</ion-label>
			<ion-icon name="scan-outline" slot="end"></ion-icon>
		</ion-button>
	</div>
</app-question-template>

import { Injectable } from "@angular/core";
import { ChecklistStateStore } from "./checklist-state.store";
import { ChecklistStateQuery } from "./checklist-state.query";
import { addMinutes, isAfter } from "date-fns";
import { combineLatest, filter, interval } from "rxjs";
import { RaygunService } from "source/app/configuration/services/raygun.service";
import { LocationStateQuery } from "source/app/configuration/state/location-state.query";

@Injectable({ providedIn: "root" })
export class ChecklistStateService {
	constructor(
		private checklistStore: ChecklistStateStore,
		private checklistStateQuery: ChecklistStateQuery,
		private locationStateQuery: LocationStateQuery,
		private raygunService: RaygunService,
	) {
		this.activate();
	}

	setPreopeningChecklistSubmitted = (resultId: string) => {
		this.checklistStore.update({
			preopeningChecklistSubmitted: { result: resultId, submitted: new Date().toISOString() },
		});
	};

	clearPreopeningChecklistSubmitted = () => {
		this.checklistStore.update({ preopeningChecklistSubmitted: null });
	};

	private activate = () => {
		const maxAgeMinutes = 5;

		combineLatest([
			this.checklistStateQuery.preopeningChecklistSubmitted$,
			this.locationStateQuery.lastServerUpdate$,
			interval(5000),
		])
			.pipe(filter(([preopeningChecklist]) => preopeningChecklist !== null))
			.subscribe(([preopeningChecklist, lastServerUpdate]) => {
				const now = new Date();
				const validUntil = addMinutes(preopeningChecklist.submitted, maxAgeMinutes);

				if (isAfter(now, validUntil)) {
					//The Result should be included in the location status by now
					//we remove it from submitted, and log it to RayGun
					this.raygunService.send(new Error(`Result is not completed after ${maxAgeMinutes} minutes`), {
						resultId: preopeningChecklist.result,
						submitted: preopeningChecklist.submitted,
						lastServerUpdate,
					});
					this.clearPreopeningChecklistSubmitted();
				}
			});
	};
}

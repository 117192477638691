import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { guid } from "@datorama/akita";
import { retryBackoff } from "backoff-rxjs";
import { combineLatest, Subject } from "rxjs";
import { concatMap, filter, take, tap } from "rxjs/operators";
import { Action, ActionType, CreateDispatchAction, CreateNoteAction, UpdateQueueTimeAction } from "./action.model";
import { ActionsQuery } from "./actions.query";
import { ActionsStore } from "./actions.store";
import { EnvironmentQuery } from "./environment.query";
import { LocationStateQuery } from "./location-state.query";
import { LocationStateService } from "./location-state.service";

export const INIT_INTERVAL_MS = 100; // 100 ms
export const MAX_INTERVAL_MS = 60 * 1000; // 60 sec

@Injectable({ providedIn: "root" })
export class ActionsService {
	constructor(
		private actionsStore: ActionsStore,
		private locationStateQuery: LocationStateQuery,
		private locationStateService: LocationStateService,
		private environmentQuery: EnvironmentQuery,
		private actionsQuery: ActionsQuery,
		private http: HttpClient,
	) {
		this.sendActions();
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	actionSentToServer$ = new Subject<Action<any>>();

	addCreateNoteAction = (description: string, type: string) => {
		this.locationStateQuery.locationAndOperator$.pipe(take(1)).subscribe(([location, operator]) => {
			const now = new Date();
			const action: Action<CreateNoteAction> = {
				id: guid(),
				actionType: ActionType.CREATE_NOTE,
				location: location.id,
				when: now.toISOString(),
				operator: operator.id,
				payLoad: {
					type: type,
					description: description,
				},
			};

			this.actionsStore.add(action);
		});
	};

	addCreateDispatchAction = (riders: number) => {
		this.locationStateQuery.opsLocOpsSettingsCartsnSeats$
			.pipe(take(1))
			.subscribe(([[operator, location, rideOpsSettings], carts, seats]) => {
				const now = new Date();
				const action: Action<CreateDispatchAction> = {
					id: guid(),
					actionType: ActionType.CREATE_DISPATCH,
					location: location.id,
					when: now.toISOString(),
					operator: operator.id,
					payLoad: {
						riders: riders,
						dispatchUnitCapacity: rideOpsSettings.dispatchUnitCapacity,
						dispatchUnitsAvailable: carts,
						totalUnavailableCapacity: seats,
					},
				};

				this.actionsStore.add(action);
				this.locationStateService.updateDispatchesAndRiders(now, riders);
			});
	};

	addSignOutAction = () => {
		this.locationStateQuery.operatorLocationOperationalSettings$.pipe(take(1)).subscribe(([operator, location]) => {
			const now = new Date();
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const action: Action<any> = {
				id: guid(),
				actionType: ActionType.SIGN_OUT,
				location: location.id,
				when: now.toISOString(),
				operator: operator.id,
				payLoad: {},
			};

			this.actionsStore.add(action);
		});
	};

	addUpdateQueueTimeAction = (queueTimeInMinutes: number) => {
		this.locationStateQuery.operatorLocationOperationalSettings$.pipe(take(1)).subscribe(([operator, location]) => {
			const now = new Date();
			const action: Action<UpdateQueueTimeAction> = {
				id: guid(),
				actionType: ActionType.UPDATE_QUEUE_TIME,
				location: location.id,
				when: now.toISOString(),
				operator: operator.id,
				payLoad: {
					queueTimeInMinutes: queueTimeInMinutes,
				},
			};

			this.actionsStore.add(action);
			this.locationStateService.updateQueueTime(now, queueTimeInMinutes);
		});
	};

	private sendActions = () => {
		combineLatest([
			this.environmentQuery.environment$,
			this.actionsQuery.selectFirst(),
			this.locationStateQuery.selectOperator$,
		])
			.pipe(
				// we dont want to do anything, if the actions are empty or if no operator is loggedin, as the endpoints require auth
				filter(([_environment, action, operator]) => action !== undefined && operator !== undefined),
				concatMap(([environment, action]) => {
					const endpoint = environment.api.url + this.getEndpointUrl(action);
					const request = this.getRequest(action);

					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					return this.http.post<ApiResponseWithData<any>>(endpoint, request).pipe(
						tap((response) => {
							if (response.success == false) {
								return;
							}

							this.actionsStore.remove(action.id);
							this.actionSentToServer$.next(action);
						}),
					);
				}),
				retryBackoff({
					initialInterval: INIT_INTERVAL_MS,
					maxInterval: MAX_INTERVAL_MS,
					resetOnSuccess: true,
				}),
			)
			.subscribe();
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private getEndpointUrl = (action: Action<any>): string => {
		switch (action.actionType) {
			case ActionType.CREATE_DISPATCH:
				return "api/rideops/create-dispatch";
			case ActionType.UPDATE_QUEUE_TIME:
				return "api/rideops/update-queue";
			case ActionType.CREATE_NOTE:
				return "api/rideops/create-note";
			case ActionType.SIGN_OUT:
				return "api/rideops/logout";
			default:
				throw new Error("ActionType '" + action.actionType + "' was unknown.");
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private getRequest = (action: Action<any>): BackendRequest => {
		switch (action.actionType) {
			case ActionType.CREATE_DISPATCH:
				return this.getCreateDispatchRequest(action);
			case ActionType.UPDATE_QUEUE_TIME:
				return this.getUpdateQueueTimeRequest(action);
			case ActionType.CREATE_NOTE:
				return this.getCreateNoteRequest(action);
			case ActionType.SIGN_OUT:
				return this.getSignoutRequest(action);
			default:
				throw new Error("ActionType '" + action.actionType + "' was unknown.");
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private getSignoutRequest = (action: Action<any>): any => {
		return {
			when: action.when,
			location: action.location,
			user: action.operator,
		};
	};

	private getCreateDispatchRequest = (action: Action<CreateDispatchAction>): CreateDispatchRequest => {
		const request = {
			when: action.when,
			operator: action.operator,
			location: action.location,
			riders: action.payLoad.riders,
			dispatchUnitCapacity: action.payLoad.dispatchUnitCapacity,
			dispatchUnitsAvailable: action.payLoad.dispatchUnitsAvailable,
			totalUnavailableCapacity: action.payLoad.totalUnavailableCapacity,
		};

		if (request.dispatchUnitCapacity <= 0) {
			request.dispatchUnitCapacity = 1;
		}

		if (request.dispatchUnitsAvailable <= 0) {
			request.dispatchUnitsAvailable = 1;
		}

		if (request.riders < 0) {
			request.riders = 0;
		}

		return request;
	};

	private getUpdateQueueTimeRequest = (action: Action<UpdateQueueTimeAction>): UpdateQueueTimeRequest => {
		return {
			when: action.when,
			operator: action.operator,
			location: action.location,
			queueTimeInMinutes: action.payLoad.queueTimeInMinutes,
		};
	};

	private getCreateNoteRequest = (action: Action<CreateNoteAction>): CreateNoteRequest => {
		return {
			when: action.when,
			operator: action.operator,
			location: action.location,
			description: action.payLoad.description,
			type: action.payLoad.type,
		};
	};
}

export interface BackendRequest {
	operator: string;
	location?: string;
	when?: string;
}

export interface CreateNoteRequest extends BackendRequest {
	description: string;
	type: string;
}

export interface UpdateQueueTimeRequest extends BackendRequest {
	queueTimeInMinutes: number;
}

export interface CreateDispatchRequest extends BackendRequest {
	riders: number;
	dispatchUnitCapacity: number;
	dispatchUnitsAvailable: number;
	totalUnavailableCapacity: number;
}

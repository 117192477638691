import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { TranslocoModule } from "@ngneat/transloco";
import { DirectivesModule } from "../configuration/directives/directives.module";
import { ChangePinCodeModal } from "../modals/change-pin-code/change-pin-code.modal";
import { LanguagePickerModalModule } from "../modals/language-picker/language-picker.module";
import { LogoutModalModule } from "../modals/logout/logout.module";
import { AvatarComponent } from "./avatar/avatar.component";
import { CurrentUserComponent } from "./current-user/current-user.component";
import { EnvironmentBarComponent } from "./environment-bar/environment-bar.component";
import { LocationStatusComponent } from "./location-status/location-status.component";
import { MenuBarComponent } from "./menu-bar/menu-bar.component";
import { NumberGridComponent } from "./number-grid/number-grid.component";
import { NumberSelectorComponent } from "./number-selector/number-selector.component";
import { OperatorLoginModal } from "./operator-login/modals/operator-login.modal";
import { OperatorLoginComponent } from "./operator-login/operator-login.component";
import { PinCodeInputComponent } from "./pin-code-input/pin-code-input.component";
import { SettingsComponent } from "./settings/settings.component";
import { StatisticsNumberComponent } from "./statistics/statistics-number.component";
import { StatisticsTimeComponent } from "./statistics/statistics-time.component";
import { StatisticsComponent } from "./statistics/statistics.component";
import { NoteTemplateComponent } from "../features/notes/components/note-template.component";
import { GroupingComponent } from "./grouping/grouping.component";
import { OfflineMessageComponent } from "./offline-message/offline-message.component";
import { ScannerModule } from "../modals/scanner/scanner.module";
import { UserPickerModal } from "../features/operators/modals/user-picker.modal";
import { UserPickerComponent } from "../features/operators/modals/user-picker.component";
import { SignatureModule } from "../modals/signature/signature.module";
import { FileUploadsModule } from "../modals/file-uploads/file-uploads.module";
import { ImageViewerModule } from "../modals/image-viewer/image-viewer.module";
import { TextEditorModule } from "../modals/text-editor/text-editor.module";
import { ReactiveFormsModule } from "@angular/forms";

const components = [
	MenuBarComponent,
	EnvironmentBarComponent,
	CurrentUserComponent,
	LocationStatusComponent,
	AvatarComponent,
	OperatorLoginComponent,
	NumberSelectorComponent,
	NumberGridComponent,
	SettingsComponent,
	PinCodeInputComponent,
	StatisticsComponent,
	StatisticsTimeComponent,
	StatisticsNumberComponent,
	NoteTemplateComponent,
	GroupingComponent,
	OfflineMessageComponent,
	UserPickerComponent,
];

@NgModule({
	imports: [
		CommonModule,
		IonicModule,
		RouterModule,
		TranslocoModule,

		LanguagePickerModalModule,
		LogoutModalModule,
		ScannerModule,
		SignatureModule,
		FileUploadsModule,
		ImageViewerModule,
		TextEditorModule,
		DirectivesModule,
		ReactiveFormsModule,
	],
	providers: [OperatorLoginModal, ChangePinCodeModal, UserPickerModal],
	declarations: components,
	exports: components,
})
export class ComponentsModule {}

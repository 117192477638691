import { ChangeDetectorRef, Component, forwardRef, Input } from "@angular/core";
import { ActionSheetController } from "@ionic/angular";
import { AssignmentDefinition } from "source/app/features/assignments/assignment.interface";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { AssignmentService } from "source/app/features/assignments/services/assignment.service";
import { TranslocoService } from "@ngneat/transloco";
import { AssignmentWithData } from "../../../checklist.interface";

@Component({
	selector: "app-assignments",
	templateUrl: "./assignments.component.html",
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AssignmentsComponent),
			multi: true,
		},
	],
})
export class AssignmentsComponent implements ControlValueAccessor {
	constructor(
		private translocoService: TranslocoService,
		private actionSheetController: ActionSheetController,
		private assignmentService: AssignmentService,
		private changeDetector: ChangeDetectorRef,
	) {}

	@Input() questionId: string;

	private assignments: AssignmentWithData[] = [];
	public visibleAssignment: AssignmentWithData[] = [];

	private filterVisibleAssignments = (assignments: AssignmentWithData[]): AssignmentWithData[] => {
		return assignments.filter((x) => x.triggeredBy == null || x.triggeredBy.requireNoIntervention == false);
	};

	option = async (assignment: AssignmentWithData) => {
		const cancelButton = {
			text: this.translocoService.translate("BASE__CANCEL"),
			role: "cancel",
			data: {
				action: "cancel",
			},
		};

		const editButton = {
			text: this.translocoService.translate("ASSIGNMENT__OPTIONS_EDIT"),
			data: {
				action: "edit",
			},
		};

		const removeButton = {
			text: this.translocoService.translate("ASSIGNMENT__OPTIONS_REMOVE"),
			role: "destructive",
			data: {
				action: "delete",
			},
		};

		const actionSheet = await this.actionSheetController.create({
			header: this.translocoService.translate("ASSIGNMENT__OPTIONS"),
			mode: "ios",
			buttons:
				assignment.triggeredBy != null ? [editButton, cancelButton] : [editButton, removeButton, cancelButton],
		});

		await actionSheet.present();
		const action = await actionSheet.onWillDismiss();

		if (action.role === "destructive") {
			this.assignments = this.assignments.filter((x) => x != assignment);

			this.updateForm();
		}
		if (action.data?.action === "edit") {
			const editedAssignment = await this.assignmentService.openAssignment(assignment);

			if (editedAssignment != null) {
				const index = this.assignments.findIndex((x) => x === assignment);
				this.assignments.splice(index, 1, editedAssignment);

				this.updateForm();
			}
		}
	};

	private updateForm = () => {
		this.onTouched();
		this.onChange(this.assignments);
		this.changeDetector.markForCheck();
		this.visibleAssignment = this.filterVisibleAssignments(this.assignments);
	};

	createAssignment = async () => {
		let definition: AssignmentDefinition;
		try {
			definition = await this.assignmentService.selectDefinition();
		} catch {
			// definition selection was cancelled
			return;
		}

		const assignment = await this.assignmentService.createAssignment(this.questionId, definition);

		if (assignment != null) {
			const assignments = [...this.assignments, assignment];
			this.assignments = assignments;

			this.updateForm();
		}
	};

	// form setup
	onChange = (_assignments: AssignmentWithData[]) => {};
	onTouched = () => {};
	disabled = false;

	writeValue(assignments: AssignmentWithData[]): void {
		this.assignments = assignments;
		this.visibleAssignment = this.filterVisibleAssignments(assignments);
	}

	registerOnChange(fn: (value: AssignmentWithData[]) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}

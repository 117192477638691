<ion-card>
	<ion-card-header>
		<ion-card-title>
			{{ "ASSIGNMENT__DESCRIPTION" | transloco }}
		</ion-card-title>
		<ion-card-subtitle>
			{{ "ASSIGNMENT__DESCRIPTION_EDITOR_DESCRIPTION" | transloco }}
		</ion-card-subtitle>
	</ion-card-header>

	<ion-card-content>
		<quill-editor [modules]="modules" [(ngModel)]="text"></quill-editor>
		<div class="button-container">
			<ion-button color="light" shape="round" (click)="cancel()">{{ "BASE__CANCEL" | transloco }}</ion-button>
			<ion-button color="primary" shape="round" (click)="save()">{{ "BASE__SAVE" | transloco }}</ion-button>
		</div>
	</ion-card-content>
</ion-card>

import { Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { storage } from "source/main";
import { ChecklistProgressStateStore } from "../../features/checklists/state/checklist-progress-state.store";
import { ChecklistStateStore } from "../../features/checklists/state/checklist-state.store";
import { ActionsStore } from "../state/actions.store";
import { EnvironmentStore } from "../state/environment.store";
import { LocationStateStore } from "../state/location-state.store";
import { UiStore } from "../state/ui.store";
import { StorageService } from "./storage.service";
import { FileTranslationsStore } from "../state/file-translations.store";
import { FileUploadsStore } from "../state/file-uploads.store";

@Injectable({
	providedIn: "root",
})
export class FactoryResetService {
	constructor(
		private translocoService: TranslocoService,
		private alertController: AlertController,
		private uiStore: UiStore,
		private actionsStore: ActionsStore,
		private environmentStore: EnvironmentStore,
		private locationStateStore: LocationStateStore,
		private checklistStore: ChecklistStateStore,
		private checklistProgressStore: ChecklistProgressStateStore,
		private fileTranslationStore: FileTranslationsStore,
		private fileUploadsStore: FileUploadsStore,
		private storageService: StorageService,
	) {}

	public factoryReset = async (): Promise<void> => {
		const alert = await this.alertController.create({
			header: this.translocoService.translate("FACTORY_RESET__TITLE"),
			message: this.translocoService.translate("FACTORY_RESET__DESCRIPTION"),
			buttons: [
				{
					text: this.translocoService.translate("FACTORY_RESET__CANCEL"),
					role: "cancel",
				},
				{
					text: this.translocoService.translate("FACTORY_RESET__OK"),
					role: "ok",
					handler: this.nuke,
				},
			],
		});

		await alert.present();
	};

	private nuke = async () => {
		this.uiStore.reset();
		this.actionsStore.reset();
		this.locationStateStore.reset();
		this.environmentStore.reset();
		this.checklistStore.reset();
		this.checklistProgressStore.reset();
		this.fileTranslationStore.reset();
		this.fileUploadsStore.reset();
		await this.storageService.reset();

		//Clears the persisted state directly, to avoid the 1s debounce.
		storage.clearStore();

		document.location.reload();
	};
}

<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<app-question-template [form]="form" [question]="question">
	<div class="duration-container">
		<div class="timer" *ngIf="timer$ | async as time" (click)="edit()">
			<!-- Hours -->
			{{ time.hours | number: "2.0-0" }}
			<span class="number-denotation">{{ "CHECKLISTS__QUESTIONS__DURATION_HOURS" | transloco }}</span>

			<!-- Minutes -->
			{{ time.minutes | number: "2.0-0" }}
			<span class="number-denotation">{{ "CHECKLISTS__QUESTIONS__DURATION_MINUTES" | transloco }}</span>

			<!-- Seconds -->
			{{ time.seconds | number: "2.0-0" }}
			<span class="number-denotation">{{ "CHECKLISTS__QUESTIONS__DURATION_SECONDS" | transloco }}</span>
		</div>

		<ion-button (click)="start()" *ngIf="running === false && this.totalSeconds === 0">
			<ion-icon name="play"></ion-icon>
		</ion-button>

		<ion-button (click)="stop()" *ngIf="running">
			<ion-icon name="stop"></ion-icon>
		</ion-button>

		<ion-button (click)="reset()" *ngIf="running === false && this.totalSeconds > 0">
			<ion-icon name="refresh"></ion-icon>
		</ion-button>
	</div>
</app-question-template>

@if (solution != null) {
	<ion-item lines="none">
		<ion-label (click)="solve()">
			<ion-icon name="checkmark-done-outline"></ion-icon>
			{{ "INLINE_RESOLVE__SOLUTION" | transloco }}
		</ion-label>

		<div (click)="solve()" class="description">
			@if (solution.description !== "") {
				{{ solution.description }}
			} @else {
				<em>{{ "INLINE_RESOLVE__NO_DESCRIPTION" | transloco }}</em>
			}
		</div>

		<ion-icon slot="end" (click)="remove()" name="close-outline"></ion-icon>
	</ion-item>
} @else {
	<ion-item lines="none">
		<div class="button-container">
			<ion-button shape="round" color="primary" (click)="solve()">
				{{ "INLINE_RESOLVE__ADD_SOLUTION" | transloco }}
			</ion-button>
		</div>
	</ion-item>
}

import { Component, EventEmitter, Output, Input } from "@angular/core";

@Component({
	selector: "app-offline-message",
	templateUrl: "./offline-message.component.html",
	styleUrls: ["./offline-message.component.scss"],
})
export class OfflineMessageComponent {
	@Input() showRetry: boolean;
	@Output() retry = new EventEmitter<void>();
}

<div id="change-pin">
	<div class="pin-holder">
		<div *ngIf="state === 'EnterCurrent'">
			<div class="pin-titel">{{ "CHANGE_PIN__CHANGE_PIN" | transloco }}</div>
			<div class="pin-type">{{ "CHANGE_PIN__CURRENT_PIN" | transloco }}</div>
		</div>

		<div *ngIf="state === 'ChooseNew'">
			<div class="pin-titel">{{ "CHANGE_PIN__CHANGE_PIN" | transloco }}</div>
			<div class="pin-type">{{ "CHANGE_PIN__NEW_PIN" | transloco }}</div>
		</div>

		<div *ngIf="state === 'ConfirmNew'">
			<div class="pin-titel">{{ "CHANGE_PIN__CHANGE_PIN" | transloco }}</div>
			<div class="pin-type">
				{{ "CHANGE_PIN__CONFIRM_NEW_PIN" | transloco }}
			</div>
		</div>

		<app-pin-code-input [pin]="currentPin" (done)="onPinCodeEntered($event)"></app-pin-code-input>
	</div>
</div>

import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { UtilityService } from "source/app/configuration/services/utility.service";
import SignaturePad from "signature_pad";

@Component({
	selector: "app-signature",
	templateUrl: "signature.component.html",
	styleUrls: ["./signature.component.scss"],
})
export class SignatureComponent implements OnInit {
	static modalId = "signature-component";

	constructor(
		private modalController: ModalController,
		private utilityService: UtilityService,
	) {}

	@ViewChild("canvas", { static: true }) canvasRef!: ElementRef<HTMLCanvasElement>;

	signaturePad: SignaturePad;

	private resizeCanvas = () => {
		//this is to make sure pixel scaling works on high DPI screens
		const canvas: HTMLCanvasElement = this.canvasRef.nativeElement;
		const ratio = Math.max(window.devicePixelRatio || 1, 1);
		const width = 600;
		const height = 250;
		canvas.width = width * ratio;
		canvas.height = height * ratio;
		canvas.getContext("2d").scale(ratio, ratio);
	};

	private initSignaturePad = () => {
		const canvas: HTMLCanvasElement = this.canvasRef.nativeElement;
		this.signaturePad = new SignaturePad(canvas, {
			backgroundColor: "transparent",
		});
		this.resizeCanvas();
	};

	cancel = () => {
		this.modalController.dismiss();
	};

	save = async () => {
		if (this.signaturePad.isEmpty()) {
			return;
		}

		const signatureFile = await this.utilityService.dataURLtoFile(this.signaturePad.toDataURL(), "signature.png");
		this.modalController.dismiss(signatureFile);
	};

	ngOnInit(): void {
		this.initSignaturePad();
	}
}

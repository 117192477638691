<ng-container *ngIf="ready">
	<ion-header>
		<ion-toolbar>
			<ion-title color="primary">{{ activePage.name }}</ion-title>
			<ion-buttons slot="end">
				<ion-button color="primary" (click)="cancel()">
					<ion-icon slot="icon-only" name="close"></ion-icon>
				</ion-button>
			</ion-buttons>
		</ion-toolbar>

		<app-pagination
			[current]="activePage.id"
			[pages]="visiblePages$ | async"
			[isFirstPage]="isFirstPage"
			[isLastPage]="isLastPage"
			(goTo)="goToPage($event)"
			(next)="nextPage()"
			(previous)="previousPage()"
		></app-pagination>
	</ion-header>

	<ion-content>
		<app-checklist [page]="activePage" [form]="form"></app-checklist>
	</ion-content>

	<ion-footer>
		<ion-toolbar>
			<ion-buttons slot="start">
				<ion-button shape="round" fill="clear" (click)="previousPage()" [disabled]="isFirstPage">
					<ion-icon name="caret-back-outline"></ion-icon>
				</ion-button>
			</ion-buttons>

			<div class="finish-checklist-button-container">
				<ion-button [disabled]="form.invalid" fill="solid" shape="round" color="primary" (click)="submit()">
					{{ "CHECKLISTS__QUESTIONS__BUTTONS__SUBMIT" | transloco }}
				</ion-button>
			</div>

			<ion-buttons slot="end">
				<ion-button shape="round" fill="clear" (click)="nextPage()" [disabled]="isLastPage">
					<ion-icon name="caret-forward-outline"></ion-icon>
				</ion-button>
			</ion-buttons>
		</ion-toolbar>
	</ion-footer>
</ng-container>

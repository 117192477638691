import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input, ViewChild } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { OperatorService } from "../../configuration/services/operator.service";
import { PinCodeInputComponent } from "../pin-code-input/pin-code-input.component";

@Component({
	selector: "app-operator-login",
	templateUrl: "./operator-login.component.html",
	styleUrls: ["./operator-login.component.scss"],
})
export class OperatorLoginComponent {
	constructor(
		private modalController: ModalController,
		private operatorService: OperatorService,
		private alertController: AlertController,
		private translocoService: TranslocoService,
	) {}

	@ViewChild(PinCodeInputComponent) pinCodeInput: PinCodeInputComponent;
	@Input() operator: Operator;

	login = async (pin: string) => {
		try {
			await this.operatorService.login(this.operator.id, pin);
			await this.modalController.dismiss({
				operator: Object.assign({}, this.operator, { pin: pin }),
			});
		} catch (exception) {
			if (exception instanceof HttpErrorResponse) {
				await this.showErrorAlert(exception);
			}
			this.pinCodeInput.clear();
		}
	};

	private showErrorAlert = async (error: HttpErrorResponse) => {
		let message = "OPERATOR_LOGIN__PIN_UNKNOWN_ERROR";

		if (error.status !== 401) {
			message = "OPERATOR_LOGIN__PLEASE_TRY_AGAIN";
		} else {
			const authenticationError = error.headers.get("x-authentication-error");

			if (authenticationError == "InvalidCredentials") {
				message = "OPERATOR_LOGIN__PIN_INVALID";
				this.pinCodeInput.clear();
			} else if (authenticationError == "PinCodeLocked") {
				message = "OPERATOR_LOGIN__PIN_CODE_LOCKED";
			}
		}

		const alert = await this.alertController.create({
			message: this.translocoService.translate(message),
			buttons: [this.translocoService.translate("OPERATOR_LOGIN__OK")],
			backdropDismiss: true,
		});

		await alert.present();
	};
}

<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="note-editor-modal-content">
	<form [formGroup]="form" novalidate (ngSubmit)="save()">
		<div class="headline">
			<span *ngIf="noteTemplate?.name">{{ noteTemplate.name }}</span>
			<span *ngIf="noteTemplate == null && isCreateMode">{{ "NOTE_EDITOR__CREATE_NOTE" | transloco }}</span>
			<span *ngIf="noteTemplate == null && isCreateMode === false">
				{{ "NOTE_EDITOR__VIEW_NOTE" | transloco }}
			</span>
		</div>

		<ng-container *ngIf="isCreateMode">
			<ng-container *ngLet="templates$ | async as templates">
				<app-grouping [label]="'NOTE_EDITOR__TEMPLATES'" *ngIf="templates?.length > 0">
					<div class="template-buttons">
						<ion-button
							class="template-button"
							*ngFor="let template of templates"
							(click)="selectTemplate(template)"
							[ngStyle]="{ '--background': template.color }"
							[ngClass]="{
								disabled: selectedTemplate && selectedTemplate.id != template.id
							}"
						>
							<ion-icon
								name="checkmark-circle-outline"
								slot="start"
								*ngIf="template.id == selectedTemplate?.id"
							></ion-icon>
							{{ template.name }}
						</ion-button>
					</div>
				</app-grouping>
			</ng-container>
		</ng-container>

		<app-grouping [label]="'NOTE_EDITOR__DESCRIPTION'">
			<ion-textarea appAutoFocus rows="10" formControlName="description" class="content-text"></ion-textarea>
		</app-grouping>

		<ng-container *ngIf="noteTypes$ | async as types">
			<app-grouping [label]="'NOTE_EDITOR__TYPE'" [hidden]="types.length < 2">
				<ion-select
					formControlName="type"
					interface="popover"
					placeholder="{{ 'NOTE_EDITOR__TYPE_PLACEHOLDER' | transloco }}"
					style="margin-top: -16px"
				>
					<ion-select-option *ngFor="let type of types" value="{{ type.id }}">
						{{ type.name }}
					</ion-select-option>
				</ion-select>
			</app-grouping>
		</ng-container>

		<div class="button-container">
			<ion-button
				*ngIf="isCreateMode === false"
				color="light"
				shape="round"
				class="no-transform"
				(click)="close()"
			>
				{{ "NOTE_EDITOR__CLOSE" | transloco }}
			</ion-button>
			<ion-button *ngIf="isCreateMode" color="light" shape="round" class="no-transform" (click)="close()">
				{{ "NOTE_EDITOR__CANCEL" | transloco }}
			</ion-button>
			<ion-button
				*ngIf="isCreateMode"
				color="primary"
				shape="round"
				class="no-transform"
				type="submit"
				[disabled]="form.invalid"
			>
				{{ "NOTE_EDITOR__SAVE" | transloco }}
			</ion-button>
		</div>
	</form>
</div>

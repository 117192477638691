import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { AssignmentTrigger, AssignmentWithData } from "../../checklists/checklist.interface";
import { AssignmentDefinition, AssignmentSolutionData, ManifestAssignment } from "../assignment.interface";
import { OverlayEventDetail } from "@ionic/core";
import { AssignmentModalComponent } from "./assignment-modal.component";

@Injectable()
export class AssignmentModalService {
	constructor(private modalController: ModalController) {}

	open = async (
		assignment: ManifestAssignment,
		definition: AssignmentDefinition,
		trigger: AssignmentTrigger = null,
		solution: AssignmentSolutionData = null,
	): Promise<AssignmentWithData> => {
		const assignmentModal = await this.modalController.create({
			component: AssignmentModalComponent,
			componentProps: {
				assignment: assignment,
				definition: definition,
				trigger: trigger,
				solution: solution,
			},
			id: AssignmentModalComponent.modalId,
			cssClass: "assignment-modal",
		});

		await assignmentModal.present();
		const result: OverlayEventDetail<AssignmentWithData> = await assignmentModal.onDidDismiss();
		return result.data;
	};

	// openAssignment = async (assignment: AssignmentWithData): Promise<AssignmentWithData> => {
	// 	let definition: AssignmentDefinition;

	// 	if (assignment.assignment.definition != null) {
	// 		const assignmentDefinitionSettings = await this.apiService.assignmentDefinitionSettings();
	// 		definition = assignmentDefinitionSettings.definitions.find(
	// 			(x) => x.id === assignment.assignment.definition.id,
	// 		);
	// 	}

	// 	const assignmentModal = await this.modalController.create({
	// 		component: AssignmentModalComponent,
	// 		componentProps: {
	// 			assignment: assignment.assignment,
	// 			definition: definition,
	// 			solution: assignment.solution,
	// 		},
	// 		id: AssignmentModalComponent.modalId,
	// 		cssClass: "assignment-modal",
	// 	});

	// 	await assignmentModal.present();
	// 	return (await assignmentModal.onDidDismiss()).data;
	// };
}

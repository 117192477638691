import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslocoModule } from "@ngneat/transloco";
import { SignatureComponent } from "./signature.component";
import { SignatureModalService } from "./signature-modal.service";

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, TranslocoModule],
	declarations: [SignatureComponent],
	providers: [SignatureModalService],
})
export class SignatureModule {}

export enum StatisticsMetric {
	CapacityUtilization = "capacity-utilization",
	DispatchUtilization = "dispatch-utilization",
	Dispatches = "dispatches",
	Riders = "riders",
	AverageDispatchTime = "average-dispatch-time",
	Uptime = "uptime",
	None = "none",
}

export const StatisticsMetrics = [
	{
		id: StatisticsMetric.CapacityUtilization,
		name: "STATISTICS_PAGE__RIDE_UTILIZATION",
	},
	{
		id: StatisticsMetric.DispatchUtilization,
		name: "STATISTICS_PAGE__CART_UTILIZATION",
	},
	{
		id: StatisticsMetric.Dispatches,
		name: "STATISTICS_PAGE__DISPATCHES",
	},
	{
		id: StatisticsMetric.Riders,
		name: "STATISTICS_PAGE__RIDERS",
	},
	{
		id: StatisticsMetric.AverageDispatchTime,
		name: "STATISTICS_PAGE__AVG_CYCLE_TIME",
	},
	{
		id: StatisticsMetric.Uptime,
		name: "STATISTICS_PAGE__UPTIME",
	},
	{
		id: StatisticsMetric.None,
		name: "STATISTICS_PAGE__NONE",
	},
];

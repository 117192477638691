<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="downtime-modal-content">
	<div class="headline">{{ "DOWNTIME_MODAL__DOWNTIME" | transloco }}</div>

	<ng-container *ngLet="templates$ | async as templates">
		<app-grouping [label]="'DOWNTIME_MODAL__TEMPLATES'" *ngIf="templates?.length > 0">
			<div class="downtime-template-buttons">
				<ion-button
					class="downtime-template-button"
					*ngFor="let template of templates"
					(click)="toggleTemplate(template)"
					[ngStyle]="{ '--background': template.color }"
					[ngClass]="{
						disabled: selectedTemplate && selectedTemplate.id != template.id
					}"
				>
					<ion-icon
						name="checkmark-circle-outline"
						slot="start"
						*ngIf="template.id == selectedTemplate?.id"
					></ion-icon>
					{{ template.name }}
				</ion-button>
			</div>
		</app-grouping>
	</ng-container>

	<app-grouping [label]="'DOWNTIME_MODAL__DESCRIPTION'">
		<ion-textarea [(ngModel)]="description" class="content-text"></ion-textarea>
	</app-grouping>

	<div class="button-container">
		<div class="close-text">
			{{ "DOWNTIME_MODAL__SET_STATUS_AS_DOWN" | transloco }}
		</div>
		<ion-button [disabled]="saving" color="primary" shape="round" class="no-transform" (click)="startDowntime()">
			{{ "DOWNTIME_MODAL__START_DOWNTIME" | transloco }}
		</ion-button>
	</div>
</div>

import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { map } from "rxjs/operators";
import { NumberGridConfiguration, UiState, UiStore } from "./ui.store";
import { StatisticsMetric } from "source/app/components/statistics/statistics.types";

@Injectable({ providedIn: "root" })
export class UiQuery extends Query<UiState> {
	constructor(protected override store: UiStore) {
		super(store);
	}

	widgetOptions$ = this.select((state) => state.widgetOptions);
	locked$ = this.select((state) => state.lockedSettings);
	seatsUnavailable$ = this.select((state) => state.seatsUnavailable);
	cartsInUse$ = this.select((state) => state.cartsInUse).pipe(map((value) => (value <= 0 ? 1 : value)));
	allowChangingDispatchesWhenClosingLocation$ = this.select(
		(state) => state.allowChangingDispatchesWhenClosingLocation,
	);
	allowChangingRidersWhenClosingLocation$ = this.select((state) => state.allowChangingRidersWhenClosingLocation);
	defaultCartsHasBeenSet$ = this.select((state) => state.defaultCartsHasBeenSet);
	numberGridConfiguration$ = this.select(
		(state) =>
			state.widgetOptions.riders.numberGridConfiguration ||
			<NumberGridConfiguration>{
				amount: 100,
				amountConfigured: false,
				direction: "ascending",
			},
	);
	dashboardStatisticsMetric$ = this.select((state) => state.dashboardStatisticsMetric).pipe(
		map((value) => (value == undefined ? StatisticsMetric.None : value)),
	);
}

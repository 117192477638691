import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { UtilityService } from "../../configuration/services/utility.service";

@Component({
	selector: "app-avatar",
	styleUrls: ["./avatar.component.scss"],
	template: `
		<ion-avatar>
			<ion-img
				[src]="url"
				(ionError)="onError()"
				[ngStyle]="{
					'width.px': this.size,
					'max-width.px': this.size,
					'height.px': this.size,
					'max-height.px': this.size
				}"
			></ion-img>
		</ion-avatar>
	`,
})
export class AvatarComponent implements OnInit, OnChanges {
	private static readonly FallbackProfileImage = "../../assets/images/default_user.jpg";

	constructor(private utilityService: UtilityService) {}
	@Input() file: string;
	@Input() size = 65;
	url: string;

	setImage = async () => {
		this.url =
			this.file == null || this.file == undefined || this.file == ""
				? AvatarComponent.FallbackProfileImage
				: await this.utilityService.getImageUrl(this.file, undefined, "fill", this.size);
	};

	ngOnChanges(_changes: SimpleChanges): void {
		this.setImage();
	}

	onError() {
		this.url = AvatarComponent.FallbackProfileImage;
	}

	ngOnInit() {
		this.setImage();
	}
}

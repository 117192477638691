import { ChangeDetectionStrategy, Component, ViewChild, forwardRef } from "@angular/core";
import { IonTextarea, TextareaCustomEvent } from "@ionic/angular";
import { FormQuestion, TextQuestion } from "../../../checklist.interface";
import { QuestionComponent } from "../question.component";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "app-text-question",
	templateUrl: "./text-question.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TextQuestionComponent),
			multi: true,
		},
	],
})
export class TextQuestionComponent extends QuestionComponent<TextQuestion> implements ControlValueAccessor {
	@ViewChild("questionText", { static: true }) ionTextArea!: IonTextarea;

	onChange = (event: Event) => {
		const value = (<TextareaCustomEvent>event).target.value;
		const empty = value === "";

		this.setAnswers(empty ? undefined : value);
		this.onTouched();
	};

	override writeValue(answer: FormQuestion | undefined): void {
		super.writeValue(answer);
		if (answer?.answers) {
			this.ionTextArea.value = answer.answers[0];
		}
	}
}

export interface Action<T> {
	id: string;
	operator: string;
	location: string;
	when: string;
	actionType: ActionType;
	payLoad: T;
}

export enum ActionType {
	CREATE_DISPATCH = 0,
	UPDATE_QUEUE_TIME = 1,
	CREATE_NOTE = 2,
	SIGN_OUT = 3,
}

export interface CreateDispatchAction {
	riders: number;
	dispatchUnitCapacity: number;
	dispatchUnitsAvailable: number;
	totalUnavailableCapacity: number;
}

export interface UpdateQueueTimeAction {
	queueTimeInMinutes: number;
}

export interface CreateNoteAction {
	type: string;
	description: string;
}

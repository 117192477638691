import { Component, Input, OnChanges } from "@angular/core";

@Component({
	selector: "app-statistics-time",
	templateUrl: "./statistics-time.component.html",
	styleUrls: ["./statistics-time.component.scss"],
})
export class StatisticsTimeComponent implements OnChanges {
	@Input() totalSeconds: number;

	model: StatisticsTimeModel;

	ngOnChanges(): void {
		this.model = StatisticsTimeModel.create(this.totalSeconds);
	}
}

class StatisticsTimeModel {
	private constructor(totalSeconds: number) {
		this.hours = Math.floor(totalSeconds / 60 / 60);
		this.minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
		this.seconds = Math.floor(totalSeconds % 60);

		this.showHours = this.hours > 0;
		this.showMinutes = this.showHours || this.minutes > 0;
	}

	hours: number;
	minutes: number;
	seconds: number;

	showHours: boolean;
	showMinutes: boolean;

	static create(totalSeconds: number) {
		if (totalSeconds == null) {
			return null;
		}

		return new StatisticsTimeModel(totalSeconds);
	}
}

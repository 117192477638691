import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslocoModule } from "@ngneat/transloco";
import { TextEditorComponent } from "./text-editor.component";
import { TextEditorModalService } from "./text-editor-modal.service";
import { QuillEditorComponent } from "ngx-quill";

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, TranslocoModule, QuillEditorComponent],
	declarations: [TextEditorComponent],
	providers: [TextEditorModalService],
})
export class TextEditorModule {}

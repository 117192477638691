/* eslint-disable @angular-eslint/directive-selector */
import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";

interface NgLetContext<T> {
	ngLet: T;
	$implicit: T;
}

// https://github.com/nigrosimone/ng-let/blob/main/projects/ng-let/src/lib/ng-let.directive.ts

@Directive({
	selector: "[ngLet]",
})
export class NgLetDirective<T> {
	/** @internal */
	public static ngLetUseIfTypeGuard: void;
	/**
	 * Assert the correct type of the expression bound to the `NgLet` input within the template.
	 *
	 * The presence of this static field is a signal to the Ivy template type check compiler that
	 * when the `NgLet` structural directive renders its template, the type of the expression bound
	 * to `NgLet` should be narrowed in some way. For `NgLet`, the binding expression itself is used to
	 * narrow its type, which allows the strictNullChecks feature of TypeScript to work with `NgLet`.
	 */
	static ngTemplateGuard_ngLet: "binding";
	constructor(
		private viewContainer: ViewContainerRef,
		private template: TemplateRef<NgLetContext<T>>,
	) {}
	private context: NgLetContext<T | null> = { ngLet: null, $implicit: null };

	private hasView = false;

	@Input()
	set ngLet(value: T) {
		this.context.$implicit = this.context.ngLet = value;
		if (!this.hasView) {
			this.viewContainer.createEmbeddedView(this.template, this.context);
			this.hasView = true;
		}
	}

	/**
	 * Asserts the correct type of the context for the template that `NgLet` will render.
	 *
	 * The presence of this method is a signal to the Ivy template type-check compiler that the
	 * `NgLet` structural directive renders its template with a specific context type.
	 */
	static ngTemplateContextGuard<T>(
		_directive: NgLetDirective<T>,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		context: any,
	): context is NgLetContext<Exclude<T, false | 0 | "" | null | undefined>> {
		return true;
	}
}

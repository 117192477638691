<div class="video-container">
	<div *ngIf="video.providerSettings.type === 'Vimeo'" style="padding: 56.25% 0 0 0; position: relative">
		<iframe
			[src]="videoUrl"
			style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
			frameborder="0"
			allow="autoplay; fullscreen; picture-in-picture"
			allowfullscreen
		></iframe>
	</div>

	<div *ngIf="video.providerSettings.type === 'YouTube'">
		<iframe
			[src]="videoUrl"
			width="100%"
			height="315"
			frameborder="0"
			webkitallowfullscreen
			mozallowfullscreen
			allowfullscreen
		></iframe>
	</div>

	<div class="text-container">
		<div class="video-title">{{ video.name }}</div>
	</div>
</div>

<script src="https://player.vimeo.com/api/player.js"></script>

<ng-container [formGroup]="form">
	@for (element of page.elements; track element.element.id) {
		@if (element.element.stereotype === "Question") {
			<div [formGroupName]="element.element.id" class="question">
				@switch (element.element.type) {
					@case ("TextQuestion") {
						<app-text-question
							[question]="element.element"
							[form]="form"
							[formControlName]="'question'"
							[hidden]="(element.isVisible$ | async) === false"
						></app-text-question>
					}
					@case ("ImageQuestion") {
						<app-image-question
							[question]="element.element"
							[form]="form"
							[formControlName]="'question'"
							[hidden]="(element.isVisible$ | async) === false"
						></app-image-question>
					}
					@case ("SelectQuestion") {
						<app-select-question
							[question]="element.element"
							[form]="form"
							[formControlName]="'question'"
							[hidden]="(element.isVisible$ | async) === false"
						></app-select-question>
					}
					@case ("NumberQuestion") {
						<app-number-question
							[question]="element.element"
							[form]="form"
							[formControlName]="'question'"
							[hidden]="(element.isVisible$ | async) === false"
						></app-number-question>
					}
					@case ("DateTimeQuestion") {
						<app-date-time-question
							[question]="element.element"
							[form]="form"
							[formControlName]="'question'"
							[hidden]="(element.isVisible$ | async) === false"
						></app-date-time-question>
					}
					@case ("ScannerQuestion") {
						<app-scanner-question
							[question]="element.element"
							[form]="form"
							[formControlName]="'question'"
							[hidden]="(element.isVisible$ | async) === false"
						></app-scanner-question>
					}
					@case ("EmailQuestion") {
						<app-email-question
							[question]="element.element"
							[form]="form"
							[formControlName]="'question'"
							[hidden]="(element.isVisible$ | async) === false"
						></app-email-question>
					}
					@case ("DurationQuestion") {
						<app-duration-question
							[question]="element.element"
							[form]="form"
							[formControlName]="'question'"
							[hidden]="(element.isVisible$ | async) === false"
						></app-duration-question>
					}
					@case ("SignatureQuestion") {
						<app-signature-question
							[question]="element.element"
							[form]="form"
							[formControlName]="'question'"
							[hidden]="(element.isVisible$ | async) === false"
						></app-signature-question>
					}
					@default {
						<app-uknown-question
							[question]="element.element"
							[form]="form"
							[formControlName]="'question'"
							[hidden]="(element.isVisible$ | async) === false"
						></app-uknown-question>
					}
				}
			</div>
		}
		@if (element.element.stereotype === "Static") {
			@switch (element.element.type) {
				@case ("TextElement") {
					<app-text-element
						[element]="element.element"
						[hidden]="(element.isVisible$ | async) === false"
					></app-text-element>
				}
				@case ("ImageElement") {
					<app-image-element
						[element]="element.element"
						[hidden]="(element.isVisible$ | async) === false"
					></app-image-element>
				}
			}
		}
	}
</ng-container>

import { Injectable } from "@angular/core";

import { Storage } from "@ionic/storage-angular";
import { from, map } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class StorageService {
	constructor(private storage: Storage) {
		this._storageIsReady = this.init();
	}
	private _storage: Storage | null = null;
	private _storageIsReady: Promise<void>;

	public get = async (key: string) => {
		await this._storageIsReady;
		return this._storage?.get(key);
	};

	public getBlobWithKey = (key: string) => {
		return from(this.get(key)).pipe(
			map((blob: Blob) => {
				return { key, blob };
			}),
		);
	};

	public deleteFiles = async (keys: string[]) => {
		await this._storageIsReady;
		const deletePromises = keys.map((key) => this.storage.remove(key));
		await Promise.all(deletePromises);
	};

	public set = async (key: string, blob: Blob) => {
		await this._storageIsReady;
		return this._storage?.set(key, blob);
	};

	public reset = async () => {
		await this._storageIsReady;
		return this._storage.clear();
	};

	async init() {
		// If using, define drivers here: await this.storage.defineDriver(/*...*/);
		const storage = await this.storage.create();
		this._storage = storage;
	}
}

<div class="user-container" *ngIf="operator$ | async as operator" [routerLink]="['/operators']" routerDirection="root">
	<div class="info">
		<div class="name">
			{{ operator.name }}
			<span *ngIf="(positionsWithAttendant$ | async)?.length > 0">
				{{
					"CURRENT_USER__AND_NUMBER_OF_ATTENDANTS"
						| transloco: { attendants: (positionsWithAttendant$ | async).length }
				}}
			</span>
		</div>
		<div class="hours" *ngIf="timeLoggedIn$ | async as timeLoggedIn">
			<span *ngIf="timeLoggedIn.hours === 0 && timeLoggedIn.minutes === 0">
				{{ "CURRENT_USER__JUST_LOGGED_IN" | transloco }}
			</span>
			<span *ngIf="timeLoggedIn.hours === 0 && timeLoggedIn.minutes > 0">
				{{ "CURRENT_USER__OPERATING_MINUTES" | transloco: { minutes: timeLoggedIn.minutes } }}
			</span>
			<span *ngIf="timeLoggedIn.hours > 0">
				{{
					"CURRENT_USER__OPERATING_HOURS_MINUTES"
						| transloco: { hours: timeLoggedIn.hours, minutes: timeLoggedIn.minutes }
				}}
			</span>
		</div>
	</div>

	<app-avatar [file]="operator.profileImage" [size]="48"></app-avatar>
</div>

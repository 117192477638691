import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "app-pin-code-input",
	templateUrl: "./pin-code-input.component.html",
	styleUrls: ["./pin-code-input.component.scss"],
})
export class PinCodeInputComponent {
	@Input() pin = "";
	@Output() done = new EventEmitter<string>();

	add = (digit: number): void => {
		if (this.pin.length >= 4) {
			return;
		}

		if (this.pin.length < 4) {
			this.pin = this.pin + digit;
		}

		if (this.pin.length === 4) {
			this.done.emit(this.pin);
		}
	};

	clear = (): void => {
		this.pin = "";
	};

	delete = async () => {
		this.pin = this.pin.slice(0, this.pin.length - 1);
	};
}

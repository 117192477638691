import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { VideoPlayerComponent } from "./video-player.component";

@Injectable()
export class VideoPlayerModalService {
	constructor(private modalController: ModalController) {}

	create = (video: VideoVersion) =>
		this.modalController.create({
			id: VideoPlayerComponent.modalId,
			component: VideoPlayerComponent,
			componentProps: {
				video: video,
			},
		});
}

<ion-header>
	<ion-toolbar>
		<ion-title>{{ title | transloco }}</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="dismiss(null)">
				<ion-icon slot="icon-only" name="close-outline"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
	<ion-toolbar *ngIf="description">
		<ion-text>
			<p class="ion-padding-start ion-padding-end">
				{{ description | transloco }}
			</p>
		</ion-text>
		<ion-searchbar
			mode="ios"
			[placeholder]="searchPlaceholder || 'SEARCH_FIELD__PLACEHOLDER' | transloco"
			(ionInput)="onSearchChange($event)"
		></ion-searchbar>
	</ion-toolbar>
</ion-header>

<ion-content>
	<ion-list>
		<ion-item *ngFor="let element of filteredElements">
			<div tabindex="0"></div>
			<!-- Weird temp fix for safari 14 -->
			<ion-label (click)="dismiss(element)">{{ element.name }}</ion-label>
		</ion-item>
	</ion-list>
</ion-content>

import { Component, Input, OnInit } from "@angular/core";
import { Observable, combineLatest, map, of, startWith } from "rxjs";
import { OperatorService } from "source/app/configuration/services/operator.service";
import { ModalController } from "@ionic/angular";
import { FormControl } from "@angular/forms";

@Component({
	selector: "app-user-picker",
	templateUrl: "./user-picker.component.html",
	styleUrls: ["./user-picker.component.scss"],
})
export class UserPickerComponent implements OnInit {
	constructor(
		private modalController: ModalController,
		private operatorService: OperatorService,
	) {}

	@Input() label: string;
	@Input() requiredCompetencies: DocumentReference[] = [];
	@Input() operators: Operator[] = [];
	@Input() currentOperatorId: string | undefined;

	userSearch = new FormControl("");
	searchValue$ = this.userSearch.valueChanges.pipe(
		startWith(""),
		map((x) => x.toLocaleLowerCase()),
	);

	operatorsWithMissingCompetencies = this.operatorService.populateMissingCompetencies(
		this.operators,
		this.requiredCompetencies,
	);

	operators$: Observable<Operator[]>;

	select = async (operator: Operator) => {
		this.modalController.dismiss(operator);
	};

	private setup = () => {
		this.operatorsWithMissingCompetencies = this.operatorService.populateMissingCompetencies(
			this.operators,
			this.requiredCompetencies,
		);

		this.operators$ = combineLatest([of(this.operatorsWithMissingCompetencies), this.searchValue$]).pipe(
			map(([operators, searchValue]) => {
				const sortByCurrentOperatorAndName = (a: Operator, b: Operator): number => {
					const aIsOperator = a.id == this.currentOperatorId ? 0 : 1;
					const bIsOperator = b.id == this.currentOperatorId ? 0 : 1;

					return aIsOperator - bIsOperator || a.name.localeCompare(b.name);
				};

				const filteredOperators =
					searchValue === ""
						? operators
						: operators.filter((x) => x.name.toLocaleLowerCase().indexOf(searchValue) > -1);
				return [...filteredOperators].sort(sortByCurrentOperatorAndName);
			}),
		);
	};

	ngOnInit(): void {
		this.setup();
	}
}

import { ErrorHandler } from "@angular/core";
import rg4js from "raygun4js";
import { environment } from "../../environments/environment";

rg4js("apiKey", "hXS8nneDPWTtjllqS0nwAA");
rg4js("setVersion", environment.buildNumber);
rg4js("enableCrashReporting", true);
rg4js("enablePulse", true);
rg4js("options", { excludedHostnames: ["localhost"] });

export class RaygunErrorHandler implements ErrorHandler {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleError(error: any) {
		rg4js("send", { error: error });
		console.error(error);
	}
}

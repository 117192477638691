import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { combineLatest, ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UiQuery } from "../../configuration/state/ui.query";
import { NumberGridConfiguration } from "../../configuration/state/ui.store";

@Component({
	selector: "app-number-grid",
	templateUrl: "./number-grid.component.html",
	styleUrls: ["./number-grid.component.scss"],
})
export class NumberGridComponent implements OnInit, OnDestroy {
	constructor(private uiQuery: UiQuery) {}

	@Input() selectedValue: number;
	@Output() selectedValueChange = new EventEmitter<number>();

	numbers: number[];

	private destroyed$: ReplaySubject<void> = new ReplaySubject(1);
	ngOnInit() {
		const configurationObservable = combineLatest([
			this.uiQuery.numberGridConfiguration$,
			this.uiQuery.cartsInUse$,
			this.uiQuery.seatsUnavailable$,
		]);

		configurationObservable.pipe(takeUntil(this.destroyed$)).subscribe((values) => this.configure(...values));
	}

	select(value: number) {
		if (value == null) {
			return;
		}

		this.selectedValue = value;
		this.selectedValueChange.emit(value);
	}

	private configure(configuration: NumberGridConfiguration, cartsInUse: number, seatsUnavailable: number) {
		let amount = configuration.amount;

		if (cartsInUse == 1) {
			amount -= seatsUnavailable;
		}

		if (amount <= 0) {
			amount = 0;
		}

		// Add one to allow making empty dispatches.
		amount++;

		const numbers = Array.from({ length: amount }, (_, index) => index);
		if (configuration.direction === "descending") {
			numbers.reverse();
		}

		this.numbers = numbers;
		this.populateEmptyColumns();
	}

	private populateEmptyColumns() {
		// Fill missing columns with empty fields.
		const columnsPerRow = 5;
		const columnsUsed = this.numbers.length % columnsPerRow || columnsPerRow;

		const emptyColumns = columnsPerRow - columnsUsed;
		if (emptyColumns > 0) {
			this.numbers = this.numbers.concat([...Array(emptyColumns)]);
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}

<div class="file-uploads-container">
	<div class="header">
		<div class="headline">{{ "FILEUPLOADS_MODAL__UPLOADING_FILES" | transloco }}</div>
		<div class="sub-headline">{{ "FILEUPLOADS_MODAL__UPLOADING_FILES_SUBHEADLINE" | transloco }}</div>
	</div>

	<div class="progress">
		{{ "FILEUPLOADS_MODAL__CURRENTLY_UPLOADED" | transloco: { current: current, total: total } }}
		<ion-progress-bar [value]="progress | async"></ion-progress-bar>
	</div>

	<div class="button-container">
		<ion-button shape="round" (click)="close()">
			<ion-label>{{ "BASE__CANCEL" | transloco }}</ion-label>
		</ion-button>
	</div>
</div>

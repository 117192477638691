<ion-item (click)="selectAsset()" lines="full">
	<ion-icon name="cube-outline" slot="start"></ion-icon>
	<ion-label>{{ "ASSIGNMENT__ASSET" | transloco }}</ion-label>

	@if (asset != null) {
		<div>{{ asset.name }}</div>
	} @else {
		<div class="placeholder">{{ "ASSIGNMENT__TAP_TO_SELECT" | transloco }}</div>
	}
</ion-item>

<ion-header>
	<ion-toolbar>
		<ion-title>{{ "ASSIGNEE_PICKER__TITLE" | transloco }}</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="close()">
				<ion-icon slot="icon-only" name="close-outline"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
	<ion-toolbar>
		<ion-text>
			<p class="ion-padding-start ion-padding-end">
				{{ "ASSIGNEE_PICKER__DESCRIPTION" | transloco }}
			</p>
		</ion-text>
		<ion-searchbar
			mode="ios"
			[placeholder]="'SEARCH_FIELD__PLACEHOLDER' | transloco"
			(ionInput)="onSearchChange($event)"
		></ion-searchbar>

		<div class="assignees">
			<ion-button (click)="removeAssignee(assignee)" shape="round" *ngFor="let assignee of assignees">
				{{ assignee.name }}
				<ion-icon
					slot="start"
					name="person-outline"
					size="small"
					*ngIf="assignee.id.startsWith('users/')"
				></ion-icon>
				<ion-icon
					slot="start"
					name="people-outline"
					size="small"
					*ngIf="assignee.id.startsWith('usergroups/')"
				></ion-icon>
				<ion-icon slot="end" name="close"></ion-icon>
			</ion-button>
		</div>

		<ion-segment value="users" [mode]="'md'" [value]="segmentChoice" (ionChange)="toggleChoice($event)">
			<ion-segment-button value="users">
				<ion-label>{{ "ASSIGNEE_PICKER__USERS" | transloco }}</ion-label>
			</ion-segment-button>
			<ion-segment-button value="usergroups">
				<ion-label>{{ "ASSIGNEE_PICKER__USER_GROUPS" | transloco }}</ion-label>
			</ion-segment-button>
		</ion-segment>
	</ion-toolbar>
</ion-header>

<ion-content>
	<ion-list *ngIf="segmentChoice === 'users'">
		<ion-item *ngFor="let user of filteredUsers" (click)="selectAssignee(user)">{{ user.name }}</ion-item>
	</ion-list>
	<ion-list *ngIf="segmentChoice === 'usergroups'">
		<ion-item *ngFor="let usergroup of filteredUserGroups" (click)="selectAssignee(usergroup)">
			{{ usergroup.name }}
		</ion-item>
	</ion-list>
</ion-content>
<ion-footer>
	<ion-toolbar>
		<div class="button-container">
			<ion-button (click)="close()" shape="round">{{ "ASSIGNMENT__BUTTON_CANCEL" | transloco }}</ion-button>
			<ion-button (click)="save()" shape="round" color="primary" [disabled]="assignees.length === 0">
				{{ "ASSIGNMENT__BUTTON_SAVE" | transloco }}
			</ion-button>
		</div>
	</ion-toolbar>
</ion-footer>

import { Component, forwardRef, Input } from "@angular/core";
import { AssignmentDefinition, AssignmentSolutionData } from "../../assignment.interface";
import { AssignmentSolutionDetailsModalService } from "../../modals/solution-details/solution-details.modal.service";
import { SolutionDetailsModalComponent } from "../../modals/solution-details/solution-details.modal.component";
import { AlertController } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "app-solution",
	templateUrl: "./solution.component.html",
	styleUrls: ["./solution.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SolutionComponent),
			multi: true,
		},
	],
})
export class SolutionComponent implements ControlValueAccessor {
	constructor(
		private assignmentSolutionDetailsModalService: AssignmentSolutionDetailsModalService,
		private alertController: AlertController,
		private translocoService: TranslocoService,
	) {}

	@Input() defintion: AssignmentDefinition;
	public solution: AssignmentSolutionData;

	remove = async () => {
		const alert = await this.alertController.create({
			header: this.translocoService.translate("INLINE_RESOLVE__CONFIRM_REMOVE_TITLE"),
			message: this.translocoService.translate("INLINE_RESOLVE__CONFIRM_REMOVE_BODY"),
			cssClass: "resoluton-warning-modal",
			buttons: [
				{
					text: this.translocoService.translate("BASE__CANCEL"),
					role: "cancel",
				},
				{
					text: this.translocoService.translate("INLINE_RESOLVE__CONFIRM_REMOVE_OK"),
					role: "ok",
				},
			],
		});

		await alert.present();
		const result = await alert.onDidDismiss();

		if (result.role === "ok") {
			this.solution = null;
			this.onTouched();
			this.onFormChange(this.solution);
		}
	};

	solve = async () => {
		const modal = await this.assignmentSolutionDetailsModalService.create(this.solution, this.defintion);
		modal.present();

		const result = await modal.onDidDismiss();

		if (result.role == SolutionDetailsModalComponent.save) {
			this.solution = result.data;
			this.onTouched();
			this.onFormChange(this.solution);
		}
	};

	onFormChange = (_solution: AssignmentSolutionData) => {};
	onTouched = () => {};
	//Form Setup
	disabled = false;

	writeValue(solution: AssignmentSolutionData): void {
		this.solution = solution;
	}
	registerOnChange(fn: (solution: AssignmentSolutionData) => void): void {
		this.onFormChange = fn;
	}
	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}

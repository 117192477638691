import { Injectable } from "@angular/core";
import { map, take } from "rxjs/operators";
import { UiQuery } from "./ui.query";
import { NumberGridConfiguration, UiStore } from "./ui.store";
import { UIDeviceSettings } from "../state/location-state.service";
import { LocationStateQuery } from "./location-state.query";
import { combineLatest } from "rxjs";
import { StatisticsMetric } from "source/app/components/statistics/statistics.types";

@Injectable({ providedIn: "root" })
export class UiService {
	constructor(
		private uiStore: UiStore,
		private uiQuery: UiQuery,
		private locationStateQuery: LocationStateQuery,
	) {}

	setRidersVisible = (visible: boolean) => {
		return this.uiStore.update((state) => ({
			widgetOptions: {
				...state.widgetOptions,
				riders: {
					...state.widgetOptions.riders,
					visible: visible,
				},
			},
		}));
	};

	setDashboardStatisticsMetric = (metric: StatisticsMetric) => {
		return this.uiStore.update((state) => ({
			...state,
			dashboardStatisticsMetric: metric,
		}));
	};

	setQueueTimeVisible = (visible: boolean) => {
		return this.uiStore.update((state) => ({
			widgetOptions: {
				...state.widgetOptions,
				queueTime: { visible: visible },
			},
		}));
	};

	setAllowChangingDispatchesWhenClosingLocation = (value: boolean) => {
		return this.uiStore.update({
			allowChangingDispatchesWhenClosingLocation: value,
		});
	};

	setAllowChangingRidersWhenClosingLocation = (value: boolean) => {
		return this.uiStore.update({
			allowChangingRidersWhenClosingLocation: value,
		});
	};

	setRidersMode = (mode: "grid" | "selector") => {
		return this.uiStore.update((state) => ({
			widgetOptions: {
				...state.widgetOptions,
				riders: {
					...state.widgetOptions.riders,
					visible: true,
					mode: mode,
				},
			},
		}));
	};

	setRidersInputMetric = (metric: "emptySeats" | "occupiedSeats") => {
		return this.uiStore.update((state) => ({
			widgetOptions: {
				...state.widgetOptions,
				riders: {
					...state.widgetOptions.riders,
					metric: metric,
				},
			},
		}));
	};

	setNumberGridConfiguration = (configuration: NumberGridConfiguration) => {
		return this.uiStore.update((state) => ({
			widgetOptions: {
				...state.widgetOptions,
				riders: {
					...state.widgetOptions.riders,
					numberGridConfiguration: configuration,
				},
			},
		}));
	};

	updateSeatsUnavailable = async (seatsUnavailable: number): Promise<void> => {
		const totalCapacity = await combineLatest([this.locationStateQuery.rideOpsSettings$, this.uiQuery.cartsInUse$])
			.pipe(
				map(([settings, dispatchUnitsAvailable]) => settings.dispatchUnitCapacity * dispatchUnitsAvailable),
				take(1),
			)
			.firstAsync();

		if (seatsUnavailable > totalCapacity) {
			seatsUnavailable = totalCapacity;
		}

		if (seatsUnavailable < 0) {
			seatsUnavailable = 0;
		}

		return this.uiStore.update({ seatsUnavailable: seatsUnavailable });
	};

	updateCartsInUse = (carts: number) => {
		if (carts <= 0) {
			carts = 1;
		}

		return this.uiStore.update({ cartsInUse: carts });
	};

	setDefaultCarts = (carts: number) => {
		this.uiQuery.defaultCartsHasBeenSet$.pipe(take(1)).subscribe((defaultCartsHasBeenSet) => {
			if (defaultCartsHasBeenSet) {
				return;
			}

			this.uiStore.update({
				cartsInUse: carts,
				defaultCartsHasBeenSet: true,
			});
		});
	};

	setLockedUI = (deviceSettings: UIDeviceSettings) => {
		this.uiStore.update((state) => ({
			lockedSettings: true,

			allowChangingDispatchesWhenClosingLocation: deviceSettings.allowChangingDispatchesWhenClosingLocation,
			allowChangingRidersWhenClosingLocation: deviceSettings.allowChangingRidersWhenClosingLocation,

			widgetOptions: {
				...state.widgetOptions,
				queueTime: {
					...state.widgetOptions.queueTime,
					visible: deviceSettings.showQueueTimeWidget,
				},
				riders: {
					...state.widgetOptions.riders,
					visible: deviceSettings.showDispatchWidget,
					mode: deviceSettings.dispatchWidgetType,
					numberGridConfiguration: {
						...state.widgetOptions.riders.numberGridConfiguration,
						direction: deviceSettings.dispatchGridNumberDirection,
						amount: deviceSettings.maximumRidersPerDispatch,
						amountConfigured: true,
					},
					metric: deviceSettings.dispatchMetric,
				},
			},
		}));
	};

	setUnlockedUI = () => {
		this.uiStore.update({ lockedSettings: false });
	};

	setDefaultNumberGridAmount = (amount: number) => {
		this.uiQuery.numberGridConfiguration$.pipe(take(1)).subscribe((configuration) => {
			if (configuration.amountConfigured) {
				return;
			}

			this.uiStore.update((state) => ({
				widgetOptions: {
					...state.widgetOptions,
					riders: {
						...state.widgetOptions.riders,
						numberGridConfiguration: {
							...state.widgetOptions.riders.numberGridConfiguration,
							amount: amount,
						},
					},
				},
			}));
		});
	};

	reset = () => {
		this.uiStore.reset();
	};
}

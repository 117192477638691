<app-question-template [form]="form" [question]="question">
	<ion-img *ngIf="imgUrl != null" [src]="imgUrl" (click)="takePhoto()"></ion-img>

	<div class="button-container" *ngIf="imgUrl == null">
		<ion-button shape="round" color="primary" (click)="takePhoto()">
			<ion-icon slot="end" name="camera-outline" />
			{{ "IMAGE_CAPTURE__ADD_BUTTON" | transloco }}
		</ion-button>
	</div>
</app-question-template>

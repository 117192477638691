import { Injectable } from "@angular/core";
import { AssignmentDefinition, ManifestAssignment, ManifestOrigin } from "../assignment.interface";
import { ApiService } from "source/app/configuration/services/api.service";
import { TranslocoService } from "@ngneat/transloco";
import { ActionSheetController, ModalController } from "@ionic/angular";
import { AssignmentModalComponent } from "../modals/assignment-modal.component";
import { AssignmentTrigger, AssignmentWithData } from "../../checklists/checklist.interface";
import { LocationStateQuery } from "source/app/configuration/state/location-state.query";
import { ChecklistProgressStateQuery } from "../../checklists/state/checklist-progress-state.query";
import { addDays, addHours, endOfDay } from "date-fns";
import { AssignmentModalService } from "../modals/assignment-modal.service";

@Injectable({ providedIn: "root" })
export class AssignmentService {
	constructor(
		private apiService: ApiService,
		private translocoService: TranslocoService,
		private actionSheetController: ActionSheetController,
		private modalController: ModalController,
		private locationStateQuery: LocationStateQuery,
		private checklistProgressQuery: ChecklistProgressStateQuery,
		private assignmentModalService: AssignmentModalService,
	) {}

	createAssignment = async (questionId: string, definition: AssignmentDefinition) => {
		const location = await this.locationStateQuery.location$.firstAsync();
		const checklist = await this.checklistProgressQuery.checklist$.firstAsync();

		const initialState = definition?.states.find((x) => x.type === "Initial");
		const initialPriority = definition?.priorities.find((x) => x.default);

		const origin: ManifestOrigin = {
			checklist: checklist.id,
			question: questionId,
			schedule: location.id,
			option: null,
			triggerCondition: null,
		};

		const assignment: ManifestAssignment = {
			organization: {
				id: checklist.organization,
				name: "",
			},
			target: {
				id: location.id,
				name: location.name,
			},
			asset: null,
			assignees: [],
			attachments: [],
			origin: origin,
			description: "",
			definition: definition,
			definitionState: initialState,
			definitionPriority: initialPriority,
			start: new Date().toISOString(),
			end: endOfDay(addDays(new Date(), 7)).toISOString(),
		};

		return this.assignmentModalService.open(assignment, definition);
	};

	createAssignmentFromTrigger = async (trigger: AssignmentTrigger, questionId: string) => {
		const definitions = await this.getDefinitions();
		const definition = definitions.find((x) => x.id == trigger.definition?.id);
		const initialState = definition?.states.find((x) => x.type === "Initial");
		const location = await this.locationStateQuery.location$.firstAsync();
		const user = await this.locationStateQuery.operator$.firstAsync();
		const checklist = await this.checklistProgressQuery.checklist$.firstAsync();

		const origin: ManifestOrigin = {
			checklist: checklist.id,
			question: questionId,
			schedule: location.id,
			option: trigger.condition.right,
			triggerCondition: trigger.condition,
		};

		const start = new Date().toISOString();
		let end = endOfDay(addDays(new Date(), 7)).toISOString();

		if (trigger.deadline) {
			end =
				trigger.deadlineUnit === "Days"
					? addDays(start, trigger.deadlineAmount).toISOString()
					: addHours(start, trigger.deadlineAmount).toISOString();
		}

		const assignment: ManifestAssignment = {
			organization: {
				id: checklist.organization,
				name: "",
			},
			target: {
				id: location.id,
				name: location.name,
			},
			asset: null,
			assignees: trigger.assignToPerformingUser ? [{ id: user.id, name: user.name }] : trigger.assignees,
			categories: trigger.categories,
			attachments: [],
			origin: origin,
			fromTrigger: true,
			description: "",
			definition: trigger.definition,
			definitionState: initialState,
			priority: trigger.priority,
			definitionPriority: trigger.definitionPriority,
			subscribers: trigger.subscribers,
			start: start,
			name: trigger.name,
			end: end,
		};

		if (trigger.requireNoIntervention) {
			const assignmentWithData: AssignmentWithData = {
				assignment: assignment,
				solution: null,
				triggeredBy: trigger,
			};

			return assignmentWithData;
		}

		return await this.assignmentModalService.open(assignment, definition, trigger);
	};

	openAssignment = async (assignment: AssignmentWithData): Promise<AssignmentWithData> => {
		const definitions = await this.getDefinitions();
		const definition = definitions.find((x) => x.id === assignment.assignment.definition?.id);

		const assignmentModal = await this.modalController.create({
			component: AssignmentModalComponent,
			componentProps: {
				assignment: assignment.assignment,
				definition: definition,
				trigger: assignment.triggeredBy,
				solution: assignment.solution,
			},
			id: AssignmentModalComponent.modalId,
			cssClass: "assignment-modal",
		});

		await assignmentModal.present();
		return (await assignmentModal.onDidDismiss()).data;
	};

	getDefinitions = async (): Promise<AssignmentDefinition[]> => {
		const assignmentDefinitionSettings = await this.apiService.assignmentDefinitionSettings();
		return assignmentDefinitionSettings.definitions;
	};

	selectDefinition = async (): Promise<AssignmentDefinition> => {
		const assignmentDefinitionSettings = await this.apiService.assignmentDefinitionSettings();

		const defaultDefinition = {
			text: this.translocoService.translate("ASSIGNMENT_LIST__SELECT_DEFINITION_DEFAULT"),
			data: {
				action: "",
			},
		};

		const cancelButton = {
			text: this.translocoService.translate("BASE__CANCEL"),
			role: "cancel",
			data: {
				action: "cancel",
			},
		};

		const definitionButtons = assignmentDefinitionSettings.definitions.map((definition) => {
			return {
				text: definition.name,
				data: {
					action: definition.id,
				},
			};
		});

		const buttons = assignmentDefinitionSettings.disableDefaultDefinition
			? [...definitionButtons, cancelButton]
			: [defaultDefinition, ...definitionButtons, cancelButton];

		const actionSheet = await this.actionSheetController.create({
			header: this.translocoService.translate("ASSIGNMENT_LIST__SELECT_DEFINITION_TITLE"),
			mode: "ios",
			buttons: buttons,
		});

		await actionSheet.present();

		const action = await actionSheet.onWillDismiss();

		if (action.role === "cancel") {
			throw new Error("definition selection cancelled");
		}

		if (action.data.action === "") {
			return null;
		}

		return assignmentDefinitionSettings.definitions.find((x) => x.id === action.data.action);
	};
}

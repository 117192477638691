import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ChangePinCodeComponent, ChangePinCodeState } from "./change-pin-code.component";

@Injectable()
export class ChangePinCodeModal {
	constructor(private modalController: ModalController) {}

	async show(properties: ChangePinCodeModalProperties = null): Promise<void> {
		const modal = await this.modalController.create({
			id: "change-pin-modal",
			component: ChangePinCodeComponent,
			componentProps: {
				currentPin: properties.currentPin || "",
				state: properties.requireCurrentPin ? ChangePinCodeState.EnterCurrent : ChangePinCodeState.ChooseNew,
			},
		});

		await modal.present();
		await modal.onDidDismiss();
	}
}

export interface ChangePinCodeModalProperties {
	currentPin?: string;
	requireCurrentPin: boolean;
}

import { Observable, of } from "rxjs";
import { throwExhaustiveError } from "source/app/configuration/errors/exhaustive-error";
import { containsAll, containsAny } from "source/app/configuration/utils";
import { PropertyCondition } from "../../checklist.interface";

/**
 * Evaluates a property based on the provided comparer and test values.
 *
 * The function handles the following comparers:
 * - "ContainsAny": Returns true if any of the test values are present in the properties.
 * - "NotContainsAny": Returns true if none of the test values are present in the properties.
 * - "ContainsAll": Returns true if all of the test values are present in the properties.
 * - "NotContainsAll": Returns true if not all of the test values are present in the properties.
 *
 * @param condition The property condition to evaluate.
 * @param properties The array of properties to compare against the test values.
 * @returns An Observable<boolean> indicating the result of the evaluation.
 * @throws When an unhandled logical comparer is encountered, an exhaustive error is thrown.
 *
 */
export function evaluatePropertyCondition(condition: PropertyCondition, properties: string[]): Observable<boolean> {
	const testValues = condition.right.split(",");

	switch (condition.comparer) {
		case "ContainsAny":
			return of(containsAny(properties, testValues));
		case "NotContainsAny":
			return of(!containsAny(properties, testValues));
		case "ContainsAll":
			return of(containsAll(properties, testValues));
		case "NotContainsAll":
			return of(!containsAll(properties, testValues));
		default:
			throwExhaustiveError("Unhandled logical comparer", condition.comparer);
	}
}

import { formatDate } from "@angular/common";
import { Injectable } from "@angular/core";
import { EnvironmentQuery } from "../state/environment.query";
import { LocationStateQuery } from "../state/location-state.query";

export type ResizePreset = "thumbnail" | "medium" | "large" | "profile";
export type ResizeMode = "fit" | "fill";

@Injectable({
	providedIn: "root",
})
export class UtilityService {
	constructor(
		locationStateQuery: LocationStateQuery,
		private environmentQuery: EnvironmentQuery,
	) {
		locationStateQuery.operator$.subscribe((operator) => {
			if (operator?.dateTimeSettings == undefined || operator?.dateTimeSettings == null) {
				return;
			}

			this.currentDateFormat = operator.dateTimeSettings.dateFormat == 1 ? "MM/dd/yyyy" : "dd-MM-yyyy";
			this.currentTimeFormat = operator.dateTimeSettings.useTwentyFourHourClock == true ? "HH:mm" : "h:mm a";
			this.currentTimeFormatWithSeconds =
				operator.dateTimeSettings.useTwentyFourHourClock == true ? "HH:mm:ss" : "h:mm:ss a";

			// When we have included all locales, we can comment out the line below.
			// this.currentLocale = operator.language ?? "en";
		});
	}

	private currentDateFormat = "dd-MM-yyyy";
	private currentTimeFormat = "HH:mm";
	private currentTimeFormatWithSeconds = "HH:mm:ss";
	private currentLocale = "en"; // Hardcoded for now, because we need to figure out how to include all locales

	getImageUrl = async (fileIdentifier: string, preset?: ResizePreset, mode?: ResizeMode, size?: number) => {
		if (!fileIdentifier) {
			return undefined;
		}

		const params = new URLSearchParams();

		params.append("path", fileIdentifier);
		mode && params.append("mode", String(mode));
		preset && params.append("preset", String(preset));
		size && params.append("width", String(size));
		size && params.append("height", String(size));

		const environment = await this.environmentQuery.environment$.firstAsync();
		const url = `${environment.frontEnd.url}api/files/get?path=${fileIdentifier}`;

		return preset == null ? url : `${url}&preset=${preset}`;
	};

	getFileUrl = async (fileIdentifier: string) => {
		const environment = await this.environmentQuery.environment$.firstAsync();
		return `${environment.frontEnd.url}api/files/get?path=${fileIdentifier}`;
	};

	dataURLtoFile = async (dataurl: string, filename: string) => {
		const base64Response = await fetch(dataurl);
		const blob = await base64Response.blob();
		return new File([blob], filename);
	};

	formatDate = (value: string | number | Date) => {
		if (value == null) {
			return null;
		}

		return formatDate(value, this.currentDateFormat, this.currentLocale);
	};

	formatTime = (value: string | number | Date, withSeconds = false) => {
		const timeFormat = withSeconds == false ? this.currentTimeFormat : this.currentTimeFormatWithSeconds;
		return formatDate(value, timeFormat, this.currentLocale);
	};

	formatDateTime = (value: string | number | Date, withSeconds = false) => {
		if (value == null) {
			return null;
		}

		return this.formatDate(value) + " " + this.formatTime(value, withSeconds);
	};

	getMinutesSince = (value: string | Date) => {
		const secondsSinceValue = this.getSecondsSince(value);
		if (secondsSinceValue == null) {
			return null;
		}

		const minutesSinceValue = secondsSinceValue / 60;
		return Math.floor(minutesSinceValue);
	};

	getSecondsSince = (value: string | Date) => {
		if (value == null) {
			return null;
		}

		const since = new Date(value).getTime();
		const now = new Date().getTime();

		const secondsSinceDate = (now - since) / 1000;
		return Math.floor(secondsSinceDate);
	};
}

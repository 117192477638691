<ion-card>
	<ion-card-header>
		<ion-card-title>
			{{ question.text }}
		</ion-card-title>

		<ion-fab *ngIf="showMoreButton" vertical="top" horizontal="end">
			<ion-fab-button size="small">
				<ion-icon name="add-circle-outline"></ion-icon>
			</ion-fab-button>
			<ion-fab-list side="start">
				<ion-fab-button *ngIf="question.allowComments" (click)="activateComment()">
					<ion-icon name="chatbox-outline"></ion-icon>
				</ion-fab-button>
				<ion-fab-button *ngIf="question.allowImageAttachment" (click)="getImage()">
					<ion-icon name="camera-outline"></ion-icon>
				</ion-fab-button>
				<ion-fab-button *ngIf="question.allowCameraRoll" (click)="getFromGallery()">
					<ion-icon name="images-outline"></ion-icon>
				</ion-fab-button>
				<ion-fab-button (click)="createAssignment()">
					<ion-icon name="clipboard-outline"></ion-icon>
				</ion-fab-button>
			</ion-fab-list>
		</ion-fab>

		<ion-card-subtitle [innerHtml]="question.description"></ion-card-subtitle>
	</ion-card-header>

	<ion-card-content>
		<app-reference-images [images]="question.images"></app-reference-images>
		<ng-content></ng-content>
		<div [formGroupName]="question.id">
			<app-comment formControlName="comment"></app-comment>
			<app-attachments formControlName="attachments"></app-attachments>
			<app-assignments [questionId]="question.id" formControlName="assignments"></app-assignments>
		</div>
	</ion-card-content>

	<ion-footer>
		<app-documentation [documentation]="question.documentation"></app-documentation>
	</ion-footer>
</ion-card>

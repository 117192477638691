import { Component } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { LocationStateQuery } from "../../configuration/state/location-state.query";
import { CloseLocationComponent } from "../../modals/close-location/close-location.component";
import { DownLocationComponent } from "../../modals/down-location/down-location.component";

@Component({
	selector: "app-location-status",
	templateUrl: "./location-status.component.html",
	styleUrls: ["./location-status.component.scss"],
})
export class LocationStatusComponent {
	constructor(
		private modalController: ModalController,
		private locationStateQuery: LocationStateQuery,
	) {}

	locationState$ = this.locationStateQuery.selectState$;

	closeLocation = async () => {
		const closeModal = await this.modalController.create({
			component: CloseLocationComponent,
			cssClass: "close-location-modal auto-height",
		});

		await closeModal.present();
	};

	downLocation = async () => {
		const modal = await this.modalController.create({
			component: DownLocationComponent,
			cssClass: "down-location-modal auto-height",
		});

		await modal.present();
	};
}

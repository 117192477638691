import { Injectable } from "@angular/core";
import { ValuePickerModalService } from "../value-picker/value-picker-modal.service";
import { LanguageService } from "source/app/configuration/services/language.service";

@Injectable()
export class LanguagePickerModal {
	constructor(
		private valuePickerModalService: ValuePickerModalService,
		private languageService: LanguageService,
	) {}

	async show(): Promise<LanguageReference> {
		const availableLanguages = this.languageService.getAvailableLangs();
		const languages = availableLanguages
			.filter((language) => language.id !== "source") // We don't want to show the "source" language in the language picker
			.map((language) => ({ id: language.id, name: language.label }));

		const modal = await this.valuePickerModalService.create({
			elements: languages,
			title: "LANGUAGE_PICKER__TITLE",
			description: "LANGUAGE_PICKER__DESCRIPTION",
		});

		await modal.present();
		const result = await modal.onDidDismiss<LanguageReference>();

		return result?.data;
	}
}

import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ApiService } from "../../configuration/services/api.service";
import { UiQuery } from "source/app/configuration/state/ui.query";
import { UiService } from "source/app/configuration/state/ui.service";
import { firstValueFrom } from "rxjs";
import { StatisticsMetric } from "./statistics.types";

@Component({
	selector: "app-statistics",
	templateUrl: "./statistics.component.html",
	styleUrls: ["./statistics.component.scss"],
})
export class StatisticsComponent implements OnInit {
	constructor(
		private apiService: ApiService,
		private modalController: ModalController,
		private uiQuery: UiQuery,
		private uiService: UiService,
	) {}

	readonly StatisticsMetric = StatisticsMetric; // Make enum available in template

	statistics: LocationStatisticsResult;
	offline = false;

	dashboardStatisticsMetric$ = this.uiQuery.dashboardStatisticsMetric$;

	select = async (metric: StatisticsMetric) => {
		const currentMetric = await firstValueFrom(this.dashboardStatisticsMetric$);
		const newMetric = currentMetric == metric ? StatisticsMetric.None : metric;
		this.uiService.setDashboardStatisticsMetric(newMetric);
	};
	getStatistics = async () => {
		this.offline = false;
		try {
			this.statistics = await this.apiService.getStatistics();
		} catch {
			this.offline = true;
		}
	};
	close = () => {
		this.modalController.dismiss();
	};
	ngOnInit() {
		this.getStatistics();
	}
}

import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
	selector: "app-text-editor",
	templateUrl: "./text-editor.component.html",
	styleUrls: ["./text-editor.component.scss"],
})
export class TextEditorComponent {
	static modalId = "text-editor-component";

	static save = "save";
	static cancel = "cancel";

	constructor(private modalController: ModalController) {}
	@Input() text: string;

	save = () => {
		this.modalController.dismiss(this.text, TextEditorComponent.save, TextEditorComponent.modalId);
	};

	cancel = () => {
		this.modalController.dismiss("", TextEditorComponent.cancel, TextEditorComponent.modalId);
	};

	modules = {
		toolbar: [
			["bold", "italic", "underline", "strike"],
			[{ list: "ordered" }, { list: "bullet" }],
		],
	};
}

import { Component, forwardRef, Input } from "@angular/core";
import { AssigneePickerModalService } from "../../modals/assignee-picker/assignee-picker.modal.service";
import { AssignmentDefinition } from "../../assignment.interface";
import { AssigneePickerModalComponent } from "../../modals/assignee-picker/assignee-picker.modal.component";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "app-assignees",
	templateUrl: "./assignees.component.html",
	styleUrl: "./assignees.component.scss",
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AssigneesComponent),
			multi: true,
		},
	],
})
export class AssigneesComponent implements ControlValueAccessor {
	constructor(private assigneePickerModalService: AssigneePickerModalService) {}
	public assignees: DocumentReference[];

	@Input() definition: AssignmentDefinition;

	selectAssignees = async () => {
		const modal = await this.assigneePickerModalService.create(this.assignees, this.definition);
		modal.present();

		const result = await modal.onWillDismiss();

		if (result.role === AssigneePickerModalComponent.save) {
			this.assignees = result.data;
			this.onTouched();
			this.onFormChange(result.data);
		}
	};

	//Form Setup
	onFormChange = (_description: string) => {};
	onTouched = () => {};

	disabled = false;

	writeValue(assignees: DocumentReference[]): void {
		this.assignees = assignees;
	}

	registerOnChange(fn: (description: string) => void): void {
		this.onFormChange = fn;
	}
	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}

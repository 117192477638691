import { Component } from "@angular/core";
import { ModalController, ToggleCustomEvent } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { map } from "rxjs/operators";
import { LocationChangeService } from "source/app/configuration/services/location-change.service";
import { FactoryResetService } from "../../configuration/services/factoryreset.service";
import { UiQuery } from "../../configuration/state/ui.query";
import { UiService } from "../../configuration/state/ui.service";
import { ChangePinCodeModal } from "../../modals/change-pin-code/change-pin-code.modal";
import { LanguagePickerModal } from "../../modals/language-picker/language-picker.modal";
import { ValuePickerModalService } from "source/app/modals/value-picker/value-picker-modal.service";
import { ValuePickerItem, ValuePickerModel } from "source/app/modals/value-picker/value-picker.component";
import { StatisticsMetrics } from "../statistics/statistics.types";
import { LocationStateQuery } from "source/app/configuration/state/location-state.query";
import { LanguageService } from "source/app/configuration/services/language.service";

@Component({
	selector: "app-settings",
	templateUrl: "./settings.component.html",
})
export class SettingsComponent {
	constructor(
		private uiQuery: UiQuery,
		private uiService: UiService,
		private languagePickerModal: LanguagePickerModal,
		private translocoService: TranslocoService,
		private factoryResetService: FactoryResetService,
		private changePinCodeModal: ChangePinCodeModal,
		private modalController: ModalController,
		private locationChangeService: LocationChangeService,
		private valuePickerModalService: ValuePickerModalService,
		private locationStateQuery: LocationStateQuery,
		private languageService: LanguageService,
	) {}

	widgetOptions$ = this.uiQuery.widgetOptions$;
	allowChangingDispatchesWhenClosingLocation$ = this.uiQuery.allowChangingDispatchesWhenClosingLocation$;
	allowChangingRidersWhenClosingLocation$ = this.uiQuery.allowChangingRidersWhenClosingLocation$;
	allowStatisticsForOperator$ = this.locationStateQuery.allowStatisticsForOperator$;

	locked$ = this.uiQuery.locked$;

	currentLanguage$ = this.translocoService.langChanges$.pipe(
		map((language) => {
			const availableLanguages = this.languageService.getAvailableLangs();
			const currentLang = availableLanguages.find((x) => x.id == language);
			return currentLang.label;
		}),
	);

	dashboardStatisticsMetricName$ = this.uiQuery.dashboardStatisticsMetric$.pipe(
		map((selectedMetric) => {
			const metric = StatisticsMetrics.find((x) => x.id == selectedMetric);
			return this.translocoService.translate(metric?.name);
		}),
	);

	changeLanguage = async () => {
		const language = await this.languagePickerModal.show();
		if (language == null) {
			return;
		}

		this.translocoService.setActiveLang(language.id);
	};
	setDashboardStatistics = async () => {
		const metrics = StatisticsMetrics.map(
			(metric) =>
				<ValuePickerItem>{
					id: metric.id,
					name: this.translocoService.translate(metric.name),
				},
		);

		const metricPickerSettings: ValuePickerModel = {
			title: "STATISTICS_SETTINGS_TITLE",
			description: "STATISTICS_SETTINGS_DESCRIPTION",
			elements: metrics,
		};

		const modal = await this.valuePickerModalService.create(metricPickerSettings);

		await modal.present();
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const result = await modal.onDidDismiss<any>();

		if (result.data == null) {
			return;
		}

		this.uiService.setDashboardStatisticsMetric(result.data.id);
	};
	changeLocation = async () => {
		await this.tryHideModal();
		await this.locationChangeService.startLocationChange();
	};
	factoryReset = async () => {
		await this.tryHideModal();
		await this.factoryResetService.factoryReset();
	};
	changePin = async () => {
		await this.tryHideModal();

		this.changePinCodeModal.show({
			requireCurrentPin: false,
		});
	};
	private tryHideModal = async () => {
		try {
			await this.modalController.dismiss();
			// eslint-disable-next-line no-empty
		} catch {}
	};
	setRidersWidgetVisibility(event: Event) {
		this.uiService.setRidersVisible((<ToggleCustomEvent>event).detail.checked);
	}

	setQueueWidgetVisibility(event: Event) {
		this.uiService.setQueueTimeVisible((<ToggleCustomEvent>event).detail.checked);
	}

	setInputCyclesOnClose(event: Event) {
		this.uiService.setAllowChangingDispatchesWhenClosingLocation((<ToggleCustomEvent>event).detail.checked);
	}

	setInputRidersOnClose(event: Event) {
		this.uiService.setAllowChangingRidersWhenClosingLocation((<ToggleCustomEvent>event).detail.checked);
	}
}

import { Component, Input, OnInit } from "@angular/core";
import { ModalController, SearchbarCustomEvent, SegmentCustomEvent } from "@ionic/angular";

@Component({
	selector: "app-assignee-modal",
	templateUrl: "./assignee-picker.modal.component.html",
	styleUrls: ["./assignee-picker.modal.component.scss"],
})
export class AssigneePickerModalComponent implements OnInit {
	static modalId = "assignee-picker-component";
	static save = "save";
	static cancel = "cancel";

	constructor(private modalController: ModalController) {}

	@Input() assignees: { id: string; name: string }[] = [];
	@Input() possibleAssignees: { id: string; name: string; information: string }[];
	@Input() singleSelect = false;

	segmentChoice: "users" | "usergroups" = "users";
	searchWord = "";

	users: { id: string; name: string }[] = [];
	userGroups: { id: string; name: string }[] = [];

	filteredUsers: { id: string; name: string }[] = [];
	filteredUserGroups: { id: string; name: string }[] = [];

	close = () =>
		this.modalController.dismiss([], AssigneePickerModalComponent.cancel, AssigneePickerModalComponent.modalId);

	save = () => {
		const assignees = this.assignees.map((x) => {
			return { id: x.id, name: x.name };
		});

		this.modalController.dismiss(
			assignees,
			AssigneePickerModalComponent.save,
			AssigneePickerModalComponent.modalId,
		);
	};

	selectAssignee = (assignee: { id: string; name: string }) => {
		const assigneeInAssignees = this.assignees.find((x) => x.id === assignee.id);
		if (assigneeInAssignees || (this.singleSelect === true && this.assignees.length > 0)) {
			return;
		}
		this.assignees = [...this.assignees, assignee];
	};

	removeAssignee = (assignee: { id: string; name: string }) => {
		this.assignees = this.assignees.filter((x) => x.id !== assignee.id);
	};

	onSearchChange(event: SearchbarCustomEvent) {
		this.searchWord = event.detail.value.toLocaleLowerCase();

		if (this.searchWord.trim() == "") {
			this.filteredUsers = [...this.users];
			this.filteredUserGroups = [...this.userGroups];
			return;
		}
		this.filteredUsers = this.users.filter((x) => x.name.toLocaleLowerCase().includes(this.searchWord));
		this.filteredUserGroups = this.userGroups.filter((x) => x.name.toLocaleLowerCase().includes(this.searchWord));
	}

	toggleChoice(event: SegmentCustomEvent) {
		this.segmentChoice = event.detail.value as "users" | "usergroups";
	}

	ngOnInit(): void {
		this.userGroups = this.possibleAssignees.filter((x) => x.id.startsWith("usergroups/"));
		this.users = this.possibleAssignees.filter((x) => x.id.startsWith("users/"));

		this.filteredUsers = [...this.users];
		this.filteredUserGroups = [...this.userGroups];
	}
}

import { Component, forwardRef } from "@angular/core";
import { AssetPickerModalService } from "../../modals/asset-picker/asset-picker.modal.service";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "app-asset",
	templateUrl: "./asset.component.html",
	styleUrls: ["./asset.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AssetComponent),
			multi: true,
		},
	],
})
export class AssetComponent implements ControlValueAccessor {
	constructor(private assetPickerModalService: AssetPickerModalService) {}

	public asset: DocumentReference;

	selectAsset = async () => {
		const modal = await this.assetPickerModalService.create();
		modal.present();

		const result = await modal.onDidDismiss();

		if (result.role === "save") {
			this.asset = result.data;
			this.onTouched();
			this.onFormChange(result.data);
		}
	};

	//Form Setup
	onFormChange = (_description: string) => {};
	onTouched = () => {};

	disabled = false;

	writeValue(asset: DocumentReference): void {
		this.asset = asset;
	}

	registerOnChange(fn: (description: string) => void): void {
		this.onFormChange = fn;
	}
	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}

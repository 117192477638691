import { Component, Input, ViewChild } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { take } from "rxjs/operators";
import { LocationStateQuery } from "source/app/configuration/state/location-state.query";
import { LocationStateService } from "source/app/configuration/state/location-state.service";
import { PinCodeInputComponent } from "../../components/pin-code-input/pin-code-input.component";
import { ApiService } from "../../configuration/services/api.service";

@Component({
	selector: "app-change-pin-code",
	templateUrl: "./change-pin-code.component.html",
	styleUrls: ["./change-pin-code.component.scss"],
})
export class ChangePinCodeComponent {
	constructor(
		private modalController: ModalController,
		private apiService: ApiService,
		private alertController: AlertController,
		private locationStateService: LocationStateService,
		private translocoService: TranslocoService,
		private locationStateQuery: LocationStateQuery,
	) {}

	@ViewChild(PinCodeInputComponent) pinCodeInput: PinCodeInputComponent;
	@Input() state: ChangePinCodeState = ChangePinCodeState.ChooseNew;
	@Input() currentPin = "";

	onCurrentPinEntered = async (pin: string) => {
		const operator = await this.locationStateQuery.selectOperator$.pipe(take(1)).toPromise();

		if (operator.pin == pin) {
			this.state = ChangePinCodeState.ChooseNew;
		} else {
			await this.showCurrentPinIncorrectAlert();
		}
	};
	private newPin = "";
	onNewPinEntered = (pin: string) => {
		this.state = ChangePinCodeState.ConfirmNew;
		this.newPin = pin;
	};
	onNewPinConfirmationEntered = async (pin: string) => {
		if (this.newPin == pin) {
			await this.apiService.changePin(this.newPin);
			await this.modalController.dismiss();

			this.locationStateService.setPinForOperator(this.newPin);
		} else {
			this.showConfirmationPinDoesNotMatchAlert();
			this.state = ChangePinCodeState.ChooseNew;
		}

		this.newPin = "";
	};
	async onPinCodeEntered(pin: string) {
		switch (this.state) {
			case ChangePinCodeState.EnterCurrent: {
				await this.onCurrentPinEntered(pin);
				break;
			}
			case ChangePinCodeState.ChooseNew: {
				this.onNewPinEntered(pin);
				break;
			}
			case ChangePinCodeState.ConfirmNew: {
				await this.onNewPinConfirmationEntered(pin);
			}
		}

		this.pinCodeInput.clear();
		this.currentPin = "";
	}

	private async showCurrentPinIncorrectAlert() {
		const alert = await this.alertController.create({
			message: this.translocoService.translate("CHANGE_PIN__CURRENT_PIN_NOT_CORRECT"),
		});

		await alert.present();
	}

	private async showConfirmationPinDoesNotMatchAlert() {
		const alert = await this.alertController.create({
			message: this.translocoService.translate("CHANGE_PIN__NO_MATCH"),
		});

		await alert.present();
	}
}

export enum ChangePinCodeState {
	EnterCurrent = "EnterCurrent",
	ChooseNew = "ChooseNew",
	ConfirmNew = "ConfirmNew",
}

import { Component } from "@angular/core";
import { combineLatest, interval } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { TimeSpanModel } from "source/app/models/time-span.model";
import { LocationStateQuery } from "../../configuration/state/location-state.query";

@Component({
	selector: "app-current-user",
	templateUrl: "./current-user.component.html",
	styleUrls: ["./current-user.component.scss"],
})
export class CurrentUserComponent {
	constructor(private locationStateQuery: LocationStateQuery) {}

	operator$ = this.locationStateQuery.selectOperator$;
	positionsWithAttendant$ = this.locationStateQuery.positions$.pipe(
		map((positions) => {
			return positions.filter((position) => position.attendant != null);
		}),
	);

	timeLoggedIn$ = combineLatest([interval(1000).pipe(startWith(0)), this.operator$]).pipe(
		map(([_ticks, operator]) => {
			if (operator?.loggedIn == undefined) {
				return undefined;
			}

			const loggedIn = new Date(operator.loggedIn).getTime();
			const now = new Date().getTime();

			return TimeSpanModel.fromMinutes((now - loggedIn) / 60000);
		}),
	);
}

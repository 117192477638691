import { Injectable } from "@angular/core";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { FileUpload } from "./file-upload.model";

export type FileUploadsState = EntityState<FileUpload>;

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "fileUploads", resettable: true })
export class FileUploadsStore extends EntityStore<FileUploadsState> {
	constructor() {
		super();
	}
}

import { Component } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { ApiService, DowntimeTemplate } from "../../configuration/services/api.service";
import { LocationStateService } from "../../configuration/state/location-state.service";
import { LocationStateQuery } from "source/app/configuration/state/location-state.query";

@Component({
	selector: "app-down-location",
	templateUrl: "./down-location.component.html",
	styleUrls: ["./down-location.component.scss"],
})
export class DownLocationComponent {
	constructor(
		private modalController: ModalController,
		private apiService: ApiService,
		private locationStateQuery: LocationStateQuery,
		private locationStateService: LocationStateService,
		private translocoService: TranslocoService,
		private alertController: AlertController,
	) {}

	description: string;
	selectedTemplate: DowntimeTemplate;
	templates$ = this.locationStateQuery.select((state) => state.downtimeTemplates);
	saving = false;

	toggleTemplate = (template: DowntimeTemplate) => {
		if (template == this.selectedTemplate) {
			this.selectedTemplate = null;
			this.description = null;
		} else {
			this.selectedTemplate = template;
			this.description = template.description;
		}
	};

	startDowntime = async () => {
		this.saving = true;
		try {
			await this.apiService.down(this.description, this.selectedTemplate?.categories);
			await this.locationStateService.setLocationAsdown(this.description);
			await this.modalController.dismiss();
		} catch {
			const alert = await this.alertController.create({
				message: this.translocoService.translate("DOWN_LOCATION__ERROR_MESSAGE"),
				buttons: [this.translocoService.translate("DOWN_LOCATION__OK")],
				backdropDismiss: true,
			});

			await alert.present();
		}
		this.saving = false;
	};

	dismiss = () => {
		this.modalController.dismiss();
	};
}

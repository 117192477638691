<app-question-template [form]="form" [question]="question">
	<ion-item>
		<ion-label>
			{{ "CHECKLISTS__UNSUPPORTED_QUESTION__DESCRIPTION" | transloco }}
			<p>{{ "CHECKLISTS__UNSUPPORTED_QUESTION__PLEASE_CLICK_UNDERSTAND" | transloco }}</p>
		</ion-label>
	</ion-item>
	<ion-item>
		<ion-toggle (ionChange)="toggleAccept($event)" [checked]="checked" [enableOnOffLabels]="true">
			{{ "CHECKLISTS__UNSUPPORTED_QUESTION__I_UNDERSTAND" | transloco }}
		</ion-toggle>
	</ion-item>
</app-question-template>

import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ImageElement } from "../../../checklist.interface";

@Component({
	selector: "app-image-element",
	templateUrl: "./image-element.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageElementComponent {
	@Input() element: ImageElement;
}

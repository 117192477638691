import { HttpEventType } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ID } from "@datorama/akita";
import { concatMap, filter, switchMap, tap } from "rxjs/operators";
import { FileUpload } from "./file-upload.model";
import { FileUploadsStore } from "./file-uploads.store";
import { ApiService } from "../services/api.service";
import { FileUploadsQuery } from "./file-uploads.query";
import { retryBackoff } from "backoff-rxjs";
import { FileTranslationsService } from "./file-translations.service";
import { FileTranslation } from "./file-translation.model";
import { StorageService } from "../services/storage.service";
import { v4 as uuidv4 } from "uuid";
import { BehaviorSubject } from "rxjs";

export const INIT_INTERVAL_MS = 100; // 100 ms
export const MAX_INTERVAL_MS = 60 * 1000; // 60 sec

@Injectable({ providedIn: "root" })
export class FileUploadsService {
	constructor(
		private fileUploadsStore: FileUploadsStore,
		private fileUploadsQuery: FileUploadsQuery,
		private fileTranslationService: FileTranslationsService,
		private storageService: StorageService,
		private apiService: ApiService,
	) {
		this.fileUploadsStore.reset();
		this.upload();
	}

	public currentUploadProgress$ = new BehaviorSubject(0);

	private upload = () => {
		this.fileUploadsQuery
			.selectFirst()
			.pipe(
				filter((x) => x !== undefined),
				concatMap((x) => this.storageService.getBlobWithKey(x.id)),
				switchMap((x) => {
					this.currentUploadProgress$.next(0);
					return this.apiService.uploadFiles$([new File([x.blob], x.key)]).pipe(
						tap((reponse) => {
							if (reponse.type === HttpEventType.Response) {
								this.fileUploadsStore.remove(x.key);
								const fileTranslation: FileTranslation = {
									id: x.key,
									file: x.blob,
									path: reponse.body.data[0].path,
								};

								this.fileTranslationService.add(fileTranslation);
							}
							if (reponse.type === HttpEventType.UploadProgress) {
								this.currentUploadProgress$.next(reponse.loaded / reponse.total);
							}
						}),
					);
				}),
				retryBackoff({
					initialInterval: INIT_INTERVAL_MS,
					maxInterval: MAX_INTERVAL_MS,
					resetOnSuccess: true,
				}),
			)
			.subscribe();
	};

	add = async (type: string, blob: Blob): Promise<string> => {
		const id = uuidv4();
		const fileUpload: FileUpload = {
			id: id,
			type: type,
		};
		await this.storageService.set(fileUpload.id, blob);
		this.fileUploadsStore.add(fileUpload);
		return id;
	};

	update(id: string, fileUpload: Partial<FileUpload>) {
		this.fileUploadsStore.update(id, fileUpload);
	}

	remove(id: ID) {
		this.fileUploadsStore.remove(id);
	}
}

import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";
import { StatisticsMetric } from "source/app/components/statistics/statistics.types";

export interface UiState {
	seatsUnavailable: number;
	cartsInUse: number;
	allowChangingDispatchesWhenClosingLocation: boolean;
	allowChangingRidersWhenClosingLocation: boolean;
	defaultCartsHasBeenSet: boolean;
	lockedSettings: boolean;
	dashboardStatisticsMetric: StatisticsMetric;
	widgetOptions: {
		riders: RidersWidgetOptions;
		queueTime: WidgetOptions;
	};
}

export interface WidgetOptions {
	visible: boolean;
}

export interface RidersWidgetOptions extends WidgetOptions {
	mode?: "selector" | "grid";
	metric?: "emptySeats" | "occupiedSeats";
	numberGridConfiguration: NumberGridConfiguration;
}

export interface NumberGridConfiguration {
	amount: number;
	amountConfigured: boolean;
	direction: "ascending" | "descending";
}

export function createInitialState(): UiState {
	return {
		seatsUnavailable: 0,
		cartsInUse: 0,
		allowChangingDispatchesWhenClosingLocation: true,
		allowChangingRidersWhenClosingLocation: true,
		defaultCartsHasBeenSet: false,
		lockedSettings: false,
		dashboardStatisticsMetric: StatisticsMetric.None,
		widgetOptions: {
			riders: {
				visible: true,
				mode: "grid",
				metric: "occupiedSeats",
				numberGridConfiguration: {
					amount: 100,
					direction: "ascending",
					amountConfigured: false,
				},
			},
			queueTime: {
				visible: true,
			},
		},
	};
}

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "ui", resettable: true })
export class UiStore extends Store<UiState> {
	constructor() {
		super(createInitialState());
	}
}

import { Component, Inject } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { EnvironmentQuery } from "../../configuration/state/environment.query";
import { EnvironmentState } from "../../configuration/state/environment.state";

@Component({
	selector: "app-environment-bar",
	templateUrl: "./environment-bar.component.html",
	styleUrls: ["./environment-bar.component.scss"],
})
@Inject(EnvironmentQuery)
export class EnvironmentBarComponent {
	constructor(private environmentQuery: EnvironmentQuery) {}

	environmentName$ = this.environmentQuery.environment$.pipe(map((environment) => environment.name));
	isNotProduction$ = this.environmentQuery.environment$.pipe(
		map((environment) => environment.environment !== EnvironmentState.Production),
	);

	buildNumber = environment.buildNumber;
}

import { ChangeDetectionStrategy, Component, OnDestroy, forwardRef } from "@angular/core";
import { UtilityService } from "source/app/configuration/services/utility.service";
import { FormQuestion, ImageQuestion } from "../../../checklist.interface";
import { QuestionComponent } from "../question.component";
import { validate as uuidValidate } from "uuid";
import { StorageService } from "source/app/configuration/services/storage.service";
import { ImageService } from "source/app/configuration/services/image.service";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "app-image-question",
	templateUrl: "./image-question.component.html",
	styleUrls: ["./image-question.component.scss"],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ImageQuestionComponent),
			multi: true,
		},
	],
})
export class ImageQuestionComponent
	extends QuestionComponent<ImageQuestion>
	implements OnDestroy, ControlValueAccessor
{
	constructor(
		private utilityService: UtilityService,
		private storageService: StorageService,
		private imageService: ImageService,
	) {
		super();
	}
	imgUrl: string;

	takePhoto = async () => {
		const mobaroImage = await this.imageService.getPhotoFromCamera();
		this.imgUrl = URL.createObjectURL(mobaroImage.file);
		this.setAnswers(mobaroImage.id);
	};

	private setImageFromAnswer = async (imageAnswer: string) => {
		if (uuidValidate(imageAnswer)) {
			const imageBlob: Blob = await this.storageService.get(imageAnswer);
			this.imgUrl = URL.createObjectURL(imageBlob);
		} else {
			this.imgUrl = await this.utilityService.getImageUrl(imageAnswer, "medium");
		}
	};

	ngOnDestroy(): void {
		if (this.imgUrl != null) {
			URL.revokeObjectURL(this.imgUrl);
		}
	}

	override writeValue(answer: FormQuestion | undefined): void {
		super.writeValue(answer);
		if (answer?.answers) {
			this.setImageFromAnswer(answer.answers[0]);
		}
	}
}

import { Injectable } from "@angular/core";
import { Camera, CameraResultType } from "@capacitor/camera";
import { FileUploadsService } from "../state/file-uploads.service";
import { FileTranslationsService } from "../state/file-translations.service";
import { StorageService } from "./storage.service";

@Injectable({
	providedIn: "root",
})
export class ImageService {
	constructor(
		private fileUploadsService: FileUploadsService,
		private fileTranslationsService: FileTranslationsService,
		private storageService: StorageService,
	) {}

	getPhotoFromCamera = async (): Promise<MobaroImage> => {
		const photo = await Camera.getPhoto({ resultType: CameraResultType.Uri });
		const type = "image/" + photo.format;
		const blob = await this.resizeImage(photo.webPath, type);
		const id = await this.fileUploadsService.add(type, blob);

		URL.revokeObjectURL(photo.webPath);
		return {
			id: id,
			file: blob,
			type: type,
		};
	};

	getImagesFromGallery = async (): Promise<MobaroImage[]> => {
		const galleryPhotos = await Camera.pickImages({});

		const imagePromises = galleryPhotos.photos.map(async (photo) => {
			const type = "image/" + photo.format;
			const blob = await this.resizeImage(photo.webPath, type);
			const id = await this.fileUploadsService.add(type, blob);

			URL.revokeObjectURL(photo.webPath);
			return {
				id: id,
				file: blob,
				type: type,
			};
		});

		return Promise.all(imagePromises);
	};

	removeImage = async (id: string) => {
		await this.storageService.deleteFiles([id]);
		this.fileUploadsService.remove(id);
		this.fileTranslationsService.remove(id);
	};

	private resizeImage = async (imageSource: string, type: string): Promise<Blob> => {
		const targetWidth = 1200;
		const targetHeight = 1200;
		const quality = 90;

		return new Promise((resolve, _reject) => {
			const img = new Image();
			img.onload = async () => {
				if (img.width <= targetWidth && img.height <= targetHeight) {
					const response = await fetch(imageSource);
					const blob = await response.blob();
					resolve(blob);
				} else {
					const ratio = Math.min(targetWidth / img.width, targetHeight / img.height);
					const imageWidth = ratio * img.width;
					const imageHeight = ratio * img.height;

					const canvas = document.createElement("canvas");

					canvas.width = imageWidth;
					canvas.height = imageHeight;

					canvas.getContext("2d").drawImage(img, 0, 0, imageWidth, imageHeight);
					canvas.toBlob(resolve, type, quality);
				}
			};

			img.src = imageSource;
		});
	};
}

export interface MobaroImage {
	id: string;
	file: Blob;
	type: string;
}

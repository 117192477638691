import { Component } from "@angular/core";
import { map } from "rxjs";
import { LocationStateQuery } from "source/app/configuration/state/location-state.query";
import { NoteEditorComponent, NoteEditorComponentMode } from "source/app/modals/note-editor/note-editor.component";
import { ModalController } from "@ionic/angular";

@Component({
	selector: "app-note-template",
	templateUrl: "./note-template.component.html",
	styleUrls: ["./note-template.component.scss"],
})
export class NoteTemplateComponent {
	constructor(
		private locationStateQuery: LocationStateQuery,
		private modalController: ModalController,
	) {}

	templatesExist$ = this.locationStateQuery.noteTemplates$.pipe(map((templates) => templates?.length > 0));

	create = async () => {
		const modal = await this.modalController.create({
			component: NoteEditorComponent,
			cssClass: "note-editor-modal auto-height",
			componentProps: {
				mode: NoteEditorComponentMode.Create,
			},
		});

		return await modal.present();
	};
}

import { Injectable } from "@angular/core";
import { ID } from "@datorama/akita";
import { FileTranslation } from "./file-translation.model";
import { FileTranslationsStore } from "./file-translations.store";

@Injectable({ providedIn: "root" })
export class FileTranslationsService {
	constructor(private fileTranslationsStore: FileTranslationsStore) {}

	removeTranslations = (translations: FileTranslation[]) => {
		this.fileTranslationsStore.remove(translations.map((x) => x.id));
	};

	add(fileTranslation: FileTranslation) {
		this.fileTranslationsStore.add(fileTranslation);
	}

	update(id: string, fileTranslation: Partial<FileTranslation>) {
		this.fileTranslationsStore.update(id, fileTranslation);
	}

	remove(id: ID) {
		this.fileTranslationsStore.remove(id);
	}
}

import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ApiService } from "source/app/configuration/services/api.service";
import {
	AssignmentDefinition,
	AssignmentDefinitionState,
	AssignmentSolutionData,
	ManifestAssignment,
	MobaroAsset,
} from "source/app/features/assignments/assignment.interface";
import { formatISO } from "date-fns";
import { fromZonedTime, toZonedTime } from "date-fns-tz";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { AssignmentTrigger, AssignmentWithData, ImageAttachment } from "../../checklists/checklist.interface";

@Component({
	selector: "app-assignment",
	templateUrl: "./assignment-modal.component.html",
	styleUrls: ["assignment-modal.component.scss"],
})
export class AssignmentModalComponent implements OnInit {
	static modalId = "assignment-modal-component";
	constructor(
		private modalController: ModalController,
		private apiService: ApiService,
	) {}
	@Input() assignment: ManifestAssignment;
	@Input() definition: AssignmentDefinition;
	@Input() solution: AssignmentSolutionData;
	@Input() trigger: AssignmentTrigger;

	assignmentForm: FormGroup<AssignmentForm>;
	ready = false;
	categories: string[] = [];
	states: AssignmentDefinitionState[] = [];
	viewConfig: AssignmentViewConfig;

	close = () => {
		this.modalController.dismiss();
	};

	save = () => {
		if (this.assignmentForm.invalid) {
			return;
		}

		const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

		const assignmentWithData: AssignmentWithData = {
			assignment: {
				...this.assignment,
				attachments: this.assignmentForm.controls.attachments.value,
				name: this.assignmentForm.controls.title.value,
				description: this.assignmentForm.controls.description.value,
				definitionState: this.assignmentForm.controls.definitionState.value,
				definitionPriority: this.assignmentForm.controls.definitionPriority.value,
				priority: this.assignmentForm.controls.priority.value,
				asset: this.assignmentForm.controls.asset.value,
				assignees: this.assignmentForm.controls.assignees.value,
				categories: this.assignmentForm.controls.categories.value,
				definitionCategories: this.assignmentForm.controls.definitionCategories.value,
				start: this.getUTCISOStringFromLocalDateTimeString(this.assignmentForm.controls.start.value, timeZone),
				end: this.getUTCISOStringFromLocalDateTimeString(this.assignmentForm.controls.end.value, timeZone),
			},
			solution: this.assignmentForm.controls.solution.value,
			triggeredBy: this.trigger,
		};

		this.modalController.dismiss(assignmentWithData, "save", AssignmentModalComponent.modalId);
	};

	createAssignmentViewConfig = (trigger: AssignmentTrigger): AssignmentViewConfig => {
		if (trigger === null) {
			return {
				showPriority: true,
				showDateTime: true,
				showAssignee: true,
				showCategories: true,
				showDefinitionCategories: true,
			};
		}
		return {
			showPriority: trigger.priority === false,
			showDateTime: trigger.deadline === false,
			showAssignee: trigger.assignees.length === 0,
			showCategories: trigger.categories.length === 0,
			showDefinitionCategories: trigger.definitionCategories == null || trigger.definitionCategories.length === 0,
		};
	};

	private getUTCISOStringFromLocalDateTimeString = (dateTimeString: string, timeZone: string) => {
		if (dateTimeString == null) {
			return null;
		}
		return fromZonedTime(dateTimeString, timeZone).toISOString();
	};

	compareWith = (o1: { id: string }, o2: { id: string }) => {
		return o1 && o2 ? o1.id === o2.id : o1 === o2;
	};

	private createDateRangeValidator(): ValidatorFn {
		return (form: AbstractControl): ValidationErrors | null => {
			const start: string = form.get("start").value;
			const end: string = form.get("end").value;

			if (start && end) {
				const isRangeValid = new Date(end).getTime() - new Date(start).getTime() > 0;

				return isRangeValid ? null : { dateRange: true };
			}

			return null;
		};
	}

	async ngOnInit(): Promise<void> {
		if (this.definition != null) {
			this.states = this.definition.states.filter((x) => x.type !== "Final");
		}

		this.viewConfig = this.createAssignmentViewConfig(this.trigger);

		const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		const startTimeForPicker = formatISO(toZonedTime(this.assignment.start, userTimeZone));
		const endTimeForPicker = formatISO(toZonedTime(this.assignment.end, userTimeZone));

		this.assignmentForm = new FormGroup<AssignmentForm>(
			{
				title: new FormControl<string>({ value: this.assignment.name, disabled: false }, [Validators.required]),
				attachments: new FormControl<ImageAttachment[]>({
					value: this.assignment.attachments,
					disabled: false,
				}),
				description: new FormControl<string>({ value: this.assignment.description, disabled: false }),
				definitionState: new FormControl<DocumentReference>({
					value: this.assignment.definitionState,
					disabled: false,
				}),
				definitionPriority: new FormControl<DocumentReference>({
					value: this.assignment.definitionPriority,
					disabled: false,
				}),
				priority: new FormControl<boolean>({ value: this.assignment.priority, disabled: false }),
				asset: new FormControl<MobaroAsset>({ value: this.assignment.asset, disabled: false }),
				assignees: new FormControl<DocumentReference[]>({ value: this.assignment.assignees, disabled: false }, [
					Validators.required,
				]),
				categories: new FormControl<string[]>({ value: this.assignment.categories, disabled: false }),
				definitionCategories: new FormControl<string[]>({
					value: this.assignment.definitionCategories,
					disabled: false,
				}),
				start: new FormControl<string>({ value: startTimeForPicker, disabled: false }),
				end: new FormControl<string>({ value: endTimeForPicker, disabled: false }),
				solution: new FormControl<AssignmentSolutionData>({ value: this.solution, disabled: false }),
			},
			[this.createDateRangeValidator()],
		);

		this.categories = await this.apiService.assignmentCategories();
		this.ready = true;
	}
}

export interface AssignmentForm {
	title: FormControl<string>;
	attachments: FormControl<ImageAttachment[]>;
	description: FormControl<string>;
	definitionState: FormControl<DocumentReference>;
	definitionPriority: FormControl<DocumentReference>;
	priority: FormControl<boolean>;
	asset: FormControl<MobaroAsset>;
	assignees: FormControl<DocumentReference[]>;
	categories: FormControl<string[]>;
	definitionCategories: FormControl<string[]>;
	start: FormControl<string>;
	end: FormControl<string>;
	solution: FormControl<AssignmentSolutionData>;
}

export interface AssignmentViewConfig {
	showPriority: boolean;
	showDateTime: boolean;
	showAssignee: boolean;
	showCategories: boolean;
	showDefinitionCategories: boolean;
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslocoModule } from "@ngneat/transloco";
import { FileUploadsComponent } from "./file-uploads.component";
import { FileUploadsModalService } from "./file-uploads-modal-service";

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, TranslocoModule],
	declarations: [FileUploadsComponent],
	providers: [FileUploadsModalService],
})
export class FileUploadsModule {}

import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { TextEditorComponent } from "./text-editor.component";

@Injectable()
export class TextEditorModalService {
	constructor(private modalController: ModalController) {}

	create = (text: string) => {
		return this.modalController.create({
			id: TextEditorComponent.modalId,
			cssClass: "text-editor-modal",
			component: TextEditorComponent,
			componentProps: {
				text: text,
			},
		});
	};
}

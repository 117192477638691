<ion-header>
	<ion-toolbar>
		<ion-title>{{ "ASSIGNMENT_RESOLVE__TITLE" | transloco }}</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="close()">
				<ion-icon slot="icon-only" name="close-outline"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content>
	<ion-text>
		<p class="ion-padding-start ion-padding-end">
			{{ "ASSIGNMENT_RESOLVE__SUBTITLE" | transloco }}
		</p>
	</ion-text>
	<ion-list [formGroup]="formGroup">
		<ion-item *ngIf="definition && states.length > 1">
			<ion-select
				formControlName="definitionState"
				[placeholder]="'ASSIGNMENT__STATE_EMPTY' | transloco"
				label="{{ 'ASSIGNMENT__STATE' | transloco }}"
				(ionChange)="changeState($event)"
				[compareWith]="compareWith"
			>
				<ion-select-option *ngFor="let state of states" [value]="state">
					{{ state.name }}
				</ion-select-option>
			</ion-select>
		</ion-item>
		<ion-item>
			<ion-textarea
				formControlName="description"
				autoGrow="true"
				label="{{ 'ASSIGNMENT_RESOLVE__DESCRIPTION' | transloco }}"
			></ion-textarea>
		</ion-item>
		<ion-item lines="none">
			<div>
				<app-attachments formControlName="attachments" [showAttachments]="true"></app-attachments>
			</div>
		</ion-item>
	</ion-list>
	<div class="buttons-container">
		<ion-button (click)="close()" shape="round" expand="block" color="light">
			{{ "BASE__CANCEL" | transloco }}
		</ion-button>
		<ion-button [disabled]="formGroup.invalid" (click)="save()" shape="round" expand="block">
			{{ "ASSIGNMENT_RESOLVE__RESOLVE" | transloco }}
		</ion-button>
	</div>
</ion-content>

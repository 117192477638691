import { Observable, of } from "rxjs";
import { throwExhaustiveError } from "source/app/configuration/errors/exhaustive-error";
import { DateTimeCondition } from "../../checklist.interface";

/**
 * Evaluates the current day of the week with the day specified in the condition using the provided comparer.
 *
 * The function handles the following comparers:
 * - "EQ": Returns true if the current day of the week matches the specified day.
 * - "NEQ": Returns true if the current day of the week does not match the specified day.
 *
 * @param condition The DateTimeCondition to evaluate.
 * @returns An Observable<boolean> indicating whether the condition is true or false.
 * @throws When an unhandled logical comparer is encountered.
 */
export function evaluateDateTimeCondition(condition: DateTimeCondition): Observable<boolean> {
	const timeNow = new Date();
	const weekday = parseInt(condition.right);
	const currentIsoWeekday = timeNow.getDay() || 7;

	switch (condition.comparer) {
		case "EQ":
			return of(weekday === currentIsoWeekday);
		case "NEQ":
			return of(weekday !== currentIsoWeekday);
		default:
			throwExhaustiveError("Unhandled logical comparer", condition.comparer);
	}
}

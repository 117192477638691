import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslocoModule } from "@ngneat/transloco";
import { ValuePickerModule } from "../value-picker/value-picker.module";
import { LanguagePickerModal } from "./language-picker.modal";

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, TranslocoModule, ValuePickerModule],
	providers: [LanguagePickerModal],
})
export class LanguagePickerModalModule {}

import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { FileTranslationsStore, FileTranslationsState } from "./file-translations.store";

@Injectable({ providedIn: "root" })
export class FileTranslationsQuery extends QueryEntity<FileTranslationsState> {
	constructor(store: FileTranslationsStore) {
		super(store);
	}
}

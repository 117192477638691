<div class="close-location-content">
	<div class="headline">
		<div class="headline-text">
			{{ "CLOSE_LOCATION__TITLE" | transloco }}
		</div>
	</div>

	<app-grouping [label]="'CLOSE_LOCATION__CYCLES'" *ngIf="allowChangingDispatchesWhenClosingLocation$ | async">
		<div class="content">
			<app-number-selector [(selectedValue)]="dispatches"></app-number-selector>
		</div>
		<div class="help-text">
			{{ "CLOSE_LOCATION__CYCLES_HELP_TEXT" | transloco }}
		</div>
	</app-grouping>

	<app-grouping [label]="'CLOSE_LOCATION__RIDERS'" *ngIf="allowChangingRidersWhenClosingLocation$ | async">
		<div class="content">
			<app-number-selector [(selectedValue)]="riders"></app-number-selector>
		</div>
		<div class="help-text">
			{{ "CLOSE_LOCATION__RIDERS_HELP_TEXT" | transloco }}
		</div>
	</app-grouping>

	<div class="button-container">
		<div class="close-text">
			{{ "CLOSE_LOCATION__SET_STATUS_AS_CLOSED" | transloco }}
		</div>
		<ion-button [disabled]="isClosing" color="primary" shape="round" class="no-transform" (click)="close()">
			{{ "CLOSE_LOCATION__CLOSE_RIDE" | transloco }}
		</ion-button>
	</div>
</div>

import { Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { LocationStateService } from "../../configuration/state/location-state.service";

@Injectable()
export class LogoutModal {
	constructor(
		private alertController: AlertController,
		private locationStateService: LocationStateService,
	) {}

	async show(): Promise<void> {
		const modal = await this.alertController.create({
			header: "Logout",
			message: "Are you sure you want to completely logout and reset the app?",
			buttons: [
				{
					text: "Cancel",
					role: "cancel",
					cssClass: "secondary",
					handler: async () => {
						await this.dismiss();
					},
				},
				{
					text: "Logout",
					handler: async () => {
						this.locationStateService.resetApplication();
						await this.dismiss();
					},
				},
			],
		});

		await modal.present();
	}

	async dismiss() {
		this.alertController.dismiss();
	}
}
